import {ArrowDown, ArrowUp, RecommendedIcon} from "assets/icons";
import EnhanceIcon from "assets/icons/EnhanceIcon";
import classNames from "classnames";

import {Checkbox, Dropdown, LazyLoadImageWrapper, UpgradeNowCard} from "components";

import {useAppDispatch, useAppSelector, useOpenLink} from "hooks";

import useEventMonitor from "hooks/mapx/useEventMonitor";
import useUserBehaviorTrackHook from "hooks/mapx/useUserBehaviorTrackHook";
import {ActionAltButton, CandidateExperience, GeneralButton} from "mapx-components";
import AdditionalProfileActions from "mapx-components/AdditionalProfileActions";

import React, {useCallback, useEffect, useMemo, useState} from "react";

import {BiPhone, BiUser} from "react-icons/bi";

import {useHistory} from "react-router-dom";
import {targetListCompaniesSelector} from "store/mapx/target-list/targetListSelectors";

import styles from "./projectCandidateCard.module.scss";
import css from "../CandidateCard/CandidateInformation/candidateInformation.module.scss";
import {
	TProjectCandidateCardProps,
	TProjectCandidateStatusDropdownOption,
} from "mapx-components/Cards/ProjectCandidateCard/types";
import {STCompanyShort} from "api/companyApi/types";
import {generateCandidatesContactDetails} from "store/mapx/project/generateCandidateContactDetailsAsyncActions";
import {isCandidateContactDetailsGenerating} from "store/mapx/project/generateCandidateContactDetailsSelectors";
import {getSingleCandidateDone} from "store/mapx/candidate/candidateActions";
import CandidateContactDetails from "../CandidateContactDetails";
import CandidateScoring from "../CandidateScoring";
import {
	activeProjectReportViewOnCandidateCardSelector,
	projectProgressStatusesSelector,
} from "store/mapx/project/projectSelectors";
import CandidateSummary from "mapx-components/Cards/CandidateSummary";
import {handleCandidateSelectionForAssessment} from "store/mapx/project/projectAssessmentAsyncActions";
import {
	isCandidateSelectedForAssessmentSelector,
	isCurrentCandidateForCurrentProjectScoringInProgressSelector,
} from "store/mapx/project/candidateScoringSelectors";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {isCurrentCurrentCandidateRefreshIsInProgressSelector} from "store/mapx/refresh-candidate/refreshCandidateSelectors";
import {removeTrailingSlash} from "helpers/string";
import {STProject, STProjectProgressStatus} from "api/projectApi/types";
import {changeCandidateStatusByCandidateId} from "store/mapx/project/projectAsyncActions";
import Loader from "components/Plugins/Loader";

export default function ProjectCandidateCard({
	candidate,
	position = null,
	searchType,
	shouldTrack = false,
	isAdditionalProfileList = false,
	displayAllExperience = false,
	displayDealExperience = false,
	upgradeView = false,
	isRecommended = false,
}: TProjectCandidateCardProps) {
	const dispatch = useAppDispatch();

	const openLink = useOpenLink();

	const history = useHistory();

	const project: STProject = useAppSelector(projectSelector);

	const projectProgressStatuses: STProjectProgressStatus[] = useAppSelector(
		projectProgressStatusesSelector,
	);

	const [updatingProgressStatus, setUpdatingProgressStatus] = useState(false);

	const [displayAllExp, setDisplayAllExp] = useState(false);

	const [displayAllExpTriggerByScoring, setDisplayAllExpTriggerByScoring] = useState(false);

	const {trackStart, trackComplete, itemEventDetails} = useUserBehaviorTrackHook({
		itemType: "candidate",
		itemId: candidate.id,
		source: searchType,
		isRecommended: false,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		position,
	});

	const activeProjectReportViewOnCandidateCard = useAppSelector(
		activeProjectReportViewOnCandidateCardSelector,
	);

	const loadingCandidateContactDetails = useAppSelector((state) =>
		isCandidateContactDetailsGenerating(state, candidate),
	);

	const isCandidateSelectedForAssessment = useAppSelector((state) =>
		isCandidateSelectedForAssessmentSelector(state, project?.id, candidate?.id),
	);

	const isCurrentCandidateAssessmentInProgress = useAppSelector((state) =>
		isCurrentCandidateForCurrentProjectScoringInProgressSelector(
			state,
			project?.id,
			candidate?.id,
		),
	);

	const positions = useMemo(() => candidate.positions ?? [], [candidate]);

	/**
	 * IS THIS A AI SEARCH TYPE
	 */

	const isThisAISearchType = useMemo(
		() =>
			searchType === "Additional Profiles" ||
			searchType === "Free Text Input Search" ||
			searchType === "Generate More Results" ||
			searchType === "More Like This" ||
			searchType === "Rejected Profiles",
		[searchType],
	);

	/**
	 ** USER BEHAVIOR TRACKING LOGICS
	 */
	const containerRef = useEventMonitor({shouldTrack, itemEventDetails});

	/**
	 * CHECKING IF OUTSIDE COMPANIES
	 */

	const targetListCompanies = useAppSelector(targetListCompaniesSelector);

	const isFromOutsideCompany = useMemo(() => {
		const targetCompanyIds = targetListCompanies.map((company: STCompanyShort) => company.id);

		if (positions && positions?.length > 0 && positions[0]?.company !== undefined) {
			for (const exp of positions) {
				if (exp.year_to === null) {
					const foundCompany = targetCompanyIds?.includes(exp.company.id);

					if (foundCompany) {
						return false;
					}
				}
			}

			return true;
		} else {
			return true; // if no experience means outside company
		}
	}, [positions, targetListCompanies]);

	const displaySpecialIndicator = useMemo(() => {
		if (isThisAISearchType) {
			return isFromOutsideCompany;
		} else {
			return false;
		}
	}, [isFromOutsideCompany, isThisAISearchType]);

	const candidateExperiences = useMemo(() => {
		return positions && positions?.length > 0
			? displayAllExp || displayAllExpTriggerByScoring
				? positions
				: positions.slice(0, 3)
			: [];
	}, [displayAllExp, displayAllExpTriggerByScoring, positions]);

	const ToggleExperienceLengthButtonComponent = ((positions && positions?.length > 3) ||
		candidate.summary !== null) && (
		<GeneralButton
			customClass={classNames(styles.showMoreButton, {
				[styles.reverse]: !displayAllExp,
			})}
			icon={<ArrowUp width={20} height={20} />}
			isClicked={false}
			handleClick={() => setDisplayAllExp((prev) => !prev)}
			title={""}
		/>
	);

	useEffect(() => {
		setDisplayAllExp(displayAllExperience);
	}, [displayAllExperience]);

	const handleItemClick = useCallback(
		(event?: React.MouseEvent<HTMLElement | SVGElement, MouseEvent>) => {
			dispatch(getSingleCandidateDone(candidate));

			if (event && (event.ctrlKey || event.metaKey || event.ctrlKey || event.altKey)) {
				const originSource = location.pathname;

				const link = `/candidate/${candidate.id}?src=${originSource}`;

				openLink(event, link, true);
			} else {
				const link = `${location.pathname}/${candidate.id}?src=${searchType}`;

				history.push(link);
			}
		},
		[candidate, dispatch, history, openLink, searchType],
	);

	const handleGenerateContactButtonClick = useCallback(async () => {
		await dispatch(generateCandidatesContactDetails([candidate?.id]));
	}, [dispatch, candidate?.id]);

	const isCurrentCurrentCandidateRefreshIsInProgress = useAppSelector((state) =>
		isCurrentCurrentCandidateRefreshIsInProgressSelector(
			state,
			removeTrailingSlash(candidate.linkedin_url ?? ""),
		),
	);

	const handleCandidateStatusChange = useCallback(
		async (option: TProjectCandidateStatusDropdownOption) => {
			if (candidate) {
				setUpdatingProgressStatus(true);
				await dispatch(changeCandidateStatusByCandidateId(candidate.id, option));
				setUpdatingProgressStatus(false);
			}
		},
		[candidate, dispatch],
	);

	const InlineLoaderComponent = <Loader height={14} width={14} color={"#4E5555"} type={"Oval"} />;

	const handleOnEditScore = () => {
		dispatch(getSingleCandidateDone(candidate));
	};

	const progressStatusDropdownOptions: TProjectCandidateStatusDropdownOption[] = useMemo(() => {
		const removeOption = {value: null, name: "Remove Status"};

		const options = projectProgressStatuses.map((status) => ({
			value: status.id,
			name: status.name,
		}));

		if (candidate?.progress_status) {
			return [removeOption, ...options];
		} else {
			return options;
		}
	}, [candidate?.progress_status, projectProgressStatuses]);

	return (
		<div
			onMouseEnter={trackStart}
			onMouseLeave={trackComplete}
			ref={containerRef}
			className={classNames(styles.candidate, {
				[styles.outsideCompany]: displaySpecialIndicator,
				[styles.isRecommended]: isRecommended,
			})}
		>
			{upgradeView && <UpgradeNowCard />}

			<div className={classNames({[styles.upgradeView]: upgradeView})}>
				{displaySpecialIndicator && (
					<span className={styles.enhanceIcon}>
						<EnhanceIcon color="white" />
					</span>
				)}

				{isRecommended && (
					<span className={styles.recommendedIcon}>
						<RecommendedIcon />
					</span>
				)}

				<div
					className={classNames(styles.container, styles.informationContainer, {
						[styles.apCandidateContainer]: isThisAISearchType,
					})}
				>
					<div className={styles.contentBlock}>
						<div className={styles.infoBlockWithExperience}>
							<div className={styles.infoTitle}>
								<div className={styles.nameArea}>
									{searchType === "Project" && (
										<div style={{paddingRight: 10, display: "flex"}}>
											<Checkbox
												value={candidate.id.toString()}
												isChecked={isCandidateSelectedForAssessment}
												onChange={() =>
													dispatch(
														handleCandidateSelectionForAssessment(
															candidate?.id,
														),
													)
												}
											/>
										</div>
									)}

									<div
										className={classNames({
											[css.rotatingWrapper]:
												isCurrentCurrentCandidateRefreshIsInProgress,
										})}
									>
										{candidate.avatar_url !== null ? (
											<LazyLoadImageWrapper
												className={classNames(
													styles.logo,
													css.layLoadImage,
												)}
												image={candidate.avatar_url}
												alt={candidate.full_name ?? candidate.name}
												onClick={handleItemClick}
											/>
										) : (
											<BiUser
												className={classNames(
													styles.iconLogo,
													css.layLoadImage,
												)}
												onClick={handleItemClick}
											/>
										)}
									</div>

									<div className={styles.info} onClick={handleItemClick}>
										<span className={styles.candidateName}>
											{candidate.full_name ?? candidate.name}
										</span>
										<span className={styles.candidateCountry}>
											{candidate.country?.name}
										</span>
									</div>
								</div>

								{searchType === "Project" && (
									<Dropdown
										onSelect={handleCandidateStatusChange}
										options={progressStatusDropdownOptions}
										getOptionLabel={(option) => option.name}
										defaultSelectedOption={
											progressStatusDropdownOptions.find(
												(s) => s.name === candidate?.progress_status,
											) || null
										}
										placeholder={
											<div
												className={classNames(styles.dropdownPlaceholder, {
													[styles.hasStatus]: candidate.progress_status,
												})}
											>
												<div
													style={{display: "flex", alignItems: "center"}}
												>
													{candidate?.progress_status
														? candidate?.progress_status
														: updatingProgressStatus
														? "Updating"
														: "Candidate Status"}{" "}
													{updatingProgressStatus &&
														InlineLoaderComponent}
												</div>

												<div>
													<ArrowDown stroke={"#B0B5B4"} />
												</div>
											</div>
										}
										dropdownButtonClass={styles.statusDropdown}
									/>
								)}
							</div>

							<div className={styles.experienceBlock}>
								{positions && positions?.length > 0 && (
									<CandidateExperience
										key={candidate.id}
										positions={candidateExperiences}
										fromQuickView={false}
										isRecommended={false}
										candidateId={candidate?.id}
										position={position}
										displayDealExperience={displayDealExperience}
										searchType={searchType}
									/>
								)}
							</div>
						</div>

						{searchType === "Project" && (
							<div className={styles.reportBlock}>
								{activeProjectReportViewOnCandidateCard === "summary" && (
									<CandidateSummary
										key={candidate.id}
										id={candidate.id}
										summary={candidate.summary}
										expanded={displayAllExp}
									/>
								)}

								{activeProjectReportViewOnCandidateCard === "assessment" && (
									<CandidateScoring
										isLoading={isCurrentCandidateAssessmentInProgress}
										fit_to_brief={candidate.fit_to_brief}
										expanded={displayAllExp}
										handleOnEdit={handleOnEditScore}
										setShouldAllExpDisplayed={setDisplayAllExpTriggerByScoring}
									/>
								)}
							</div>
						)}
					</div>

					{searchType === "Project" && (
						<div className={styles.contactDetails}>
							<CandidateContactDetails
								contactDetails={candidate?.contact_details}
								loading={loadingCandidateContactDetails}
							/>
						</div>
					)}

					{/*{isThisAISearchType && (*/}
					<div className={styles.actionButtonContainer}>
						<div className={styles.buttonGroup}>
							{ToggleExperienceLengthButtonComponent}

							{searchType === "Project" && (
								<ActionAltButton
									disabled={candidate?.contact_details !== null}
									key={"Generate Contact Details"}
									customClass={classNames(
										styles.generateContactDetails,
										{
											[styles.loading]: loadingCandidateContactDetails,
										},
										// {
										//     [styles.additionalMargin]:
										//     positions && positions?.length <= 3,
										// },
									)}
									iconComponent={<BiPhone color={"#4E5555"} />}
									handleClick={handleGenerateContactButtonClick}
									title={
										loadingCandidateContactDetails
											? "Generating Contact Details..."
											: "Generate Contact Details"
									}
									loading={loadingCandidateContactDetails}
								/>
							)}
						</div>

						<AdditionalProfileActions
							searchType={searchType}
							candidate={candidate}
							isAdditionalProfileList={isAdditionalProfileList}
							isQuickView={false}
							isRecommended={candidate.is_recommended}
						/>
					</div>
					{/*)}*/}
				</div>
			</div>
		</div>
	);
}
