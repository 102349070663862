import {FC, useEffect, useState} from "react";

import {
	getFunctionalBackground,
	getIndustryBackground,
} from "store/mapx/company/companyAsyncActions";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	companiesOrgChartFiltersSelector,
	companyOrgChartFilteringSelector,
} from "store/mapx/company/companySelectors";
import BackgroundAnalysisContainer from "mapx-components/BackgroundAnalysisContainer";

import {getModifiedFunctionalBreakdownData, getModifiedIndustryBackgroundData} from "./utils";
import styles from "./BackgroundAnalysis.module.scss";
import {IndustryDiversityTabComponentProps} from "mapx-pages/CompanyDetails/types";
import classNames from "classnames";

const BackgroundAnalysis: FC<IndustryDiversityTabComponentProps> = ({isCurrentTabSelected}) => {
	const dispatch = useAppDispatch();

	const companyOrgChartFiltering = useAppSelector(companyOrgChartFilteringSelector);

	const companiesOrgChartFilters = useAppSelector(companiesOrgChartFiltersSelector);

	const {industryBackground, functionalBackground, currentCompany} = useAppSelector(
		(state) => state.company,
	);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [isDefaultChart, setIsDefaultChart] = useState<boolean>(true);

	const [currentOption, setCurrentOption] = useState<number>(0);

	const [currentIndustryOption, setCurrentIndustryOption] = useState<number>(0);

	const industryBackgroundData = getModifiedIndustryBackgroundData(industryBackground);

	const functionalBreakdownData = getModifiedFunctionalBreakdownData(functionalBackground);

	useEffect(() => {
		async function get() {
			if (currentCompany?.id) {
				setIsLoading(true);

				await Promise.all([
					dispatch(getIndustryBackground(currentCompany?.id, companiesOrgChartFilters)),
					dispatch(getFunctionalBackground(currentCompany?.id, companiesOrgChartFilters)),
				]);
			}
		}

		get().finally(() => setIsLoading(false));
	}, [currentCompany?.id, companiesOrgChartFilters, dispatch]);

	return (
		<div
			className={classNames(styles.chart_container, {
				[styles.hidden]: !isCurrentTabSelected,
			})}
		>
			<BackgroundAnalysisContainer
				title="Skills Breakdown"
				data={functionalBreakdownData}
				currentOption={currentOption}
				setCurrentOption={setCurrentOption}
				isDefaultChart={isDefaultChart}
				isLoading={isLoading || companyOrgChartFiltering}
				setIsDefaultChart={setIsDefaultChart}
				isRoundedChartVal={true}
				description={
					!currentOption
						? "The average time people have spent within each function and specialism as their previous roles."
						: "The number of people with experience in each function and specialism as their previous roles."
				}
			/>

			<BackgroundAnalysisContainer
				title="Industry Breakdown"
				data={industryBackgroundData}
				isDefaultChart={isDefaultChart}
				isLoading={isLoading || companyOrgChartFiltering}
				currentOption={currentIndustryOption}
				setCurrentOption={setCurrentIndustryOption}
				setIsDefaultChart={setIsDefaultChart}
				isRoundedChartVal={true}
				description={
					currentIndustryOption
						? "The number of people with experience in each industry."
						: "The average time people have spent within each industry."
				}
			/>
		</div>
	);
};

export default BackgroundAnalysis;
