import HttpClient from "api";
import type {STEvents} from "api/types";
import {
	ProjectPowerPointTemplateConfiguration,
	STProjectForm,
	TProjectChecklistForm,
} from "api/projectApi/types";
import {TPaginationParam} from "../candidateApi/types";
import {AxiosRequestConfig} from "axios";

class MapxProjectApi extends HttpClient {
	async exportProject(
		projectID: number | string,
		config: AxiosRequestConfig = {responseType: "arraybuffer"},
	) {
		return this.doGet(`/mapx/projects/${projectID}/export`, config);
	}

	async getPresentationAvailableTemplates(config = {}) {
		return this.doGet(`/presentation-available-templates`, config);
	}

	async downloadProjectPowerpoint(
		projectID: number | string,
		payload: ProjectPowerPointTemplateConfiguration,
		config: AxiosRequestConfig = {
			responseType: "blob",
			headers: {
				Accept: "application/octet-stream",
			},
		},
	) {
		return this.doPost(`/projects/${projectID}/export-to-pptx`, payload, config);
	}

	async deleteProject(projectID: number) {
		return this.doDelete(`/mapx/projects/${projectID}`);
	}

	async getProject(projectID: number, config = {}) {
		return this.doGet(`/mapx/projects/${projectID}`, config);
	}

	async getProjects(pagination: TPaginationParam, name = "") {
		let filterQuery = `?page=${pagination.page}&per_page=${pagination.per_page}`;

		// Conditionally add the optional name parameter
		if (name !== undefined && name !== null && name !== "") {
			filterQuery += `&name=${name}`;
		}

		return this.doGet(`/mapx/projects${filterQuery}`);
	}

	async saveProject(formData: STProjectForm, config = {}) {
		return this.doPost("/mapx/projects", formData, config);
	}

	async updateProject(projectID: number, formData: STProjectForm, config = {}) {
		return this.doPatch(`/mapx/projects/${projectID}`, formData, config);
	}

	async getProjectSuccessChecklistInfo(projectId: number, config = {}) {
		return this.doGet(`/project/${projectId}/checklist`, config);
	}

	async updateProjectChecklistInfo(
		projectId: number,
		formData: TProjectChecklistForm,
		config = {},
	) {
		return this.doPatch(`/project/${projectId}/checklist`, formData, config);
	}

	async saveEvents(data: STEvents[], config = {}) {
		return this.doPost(`/mapx/events`, data, config);
	}

	async getProjectProgressStatuses(config = {}) {
		return this.doGet(`/progress-statuses`, config);
	}
}

const mapxProjectApi = new MapxProjectApi();

export default mapxProjectApi;
