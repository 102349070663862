import {Dispatch} from "react";
import {TAppDispatch, TRootState} from "types";
import mapxProjectApi from "api/projectApi";
import {successResponse} from "helpers/map";
import {
	prepareDateForFileNameSuffix,
	preparePowerpointFileAndDownload,
	prepareSpreadSheetFileAndDownload,
} from "helpers/documentHelpers";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {Mixpanel} from "helpers/mixpanel";
import {userEmailSelector} from "store/mapx/user/userSelectors";
import {
	ProjectPowerPointTemplateConfiguration,
	ProjectPowerPointTemplateSelection,
	STProject,
	TPresentationAvailableTemplate,
} from "api/projectApi/types";
import {
	projectReportTableRowsSelector,
	selectedPresentationTemplateSelector,
} from "store/mapx/project/projectSelectors";
import {
	setPresentationAvailableTemplates,
	setProjectReportTableRows,
} from "store/mapx/project/projectActions";
import {AxiosResponse} from "axios";

// const cancelTokens = {};

export const downloadProjectReportAsSpreadsheet =
	() => async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		try {
			const state = getState();

			const project: STProject = projectSelector(state);

			const projectName = project?.name ?? "Unnamed Project";
			const formattedDate = prepareDateForFileNameSuffix();
			const fileName = `${projectName}-${formattedDate}`;

			const response = await mapxProjectApi.exportProject(project?.id);

			if (successResponse(response, 200)) {
				prepareSpreadSheetFileAndDownload(response.data, fileName);

				const userEmail = userEmailSelector(state);

				Mixpanel.track(`Project Spreadsheet Downloaded`, {
					name: `${project.name}`,
					pageTitle: `${project.name}`,
					url: window.location.pathname,
					distinct_id: userEmail,
				});
			}
		} catch (e) {
			console.error(e);
		}
	};

export const downloadProjectReportAsPowerpoint =
	() => async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		try {
			const state = getState();

			const project: STProject = projectSelector(state);

			const templateName: string = selectedPresentationTemplateSelector(state);

			const projectName = project?.name ?? "Unnamed Project";

			const formattedDate = prepareDateForFileNameSuffix();

			const fileName = `${projectName}-${formattedDate}`;

			const templateConfig = projectReportTableRowsSelector(state);

			const payload: ProjectPowerPointTemplateConfiguration = {
				slides: templateConfig,
				template_name: templateName,
			};

			const response = await mapxProjectApi.downloadProjectPowerpoint(project?.id, payload);

			if (successResponse(response, 200)) {
				preparePowerpointFileAndDownload(response.data, fileName);

				const userEmail = userEmailSelector(state);

				Mixpanel.track(`Project Powerpoint Downloaded`, {
					name: `${project.name}`,
					pageTitle: `${project.name}`,
					url: window.location.pathname,
					distinct_id: userEmail,
				});
			}
		} catch (e) {
			console.error(e);
		}
	};

export const addNewRowOnProjectReportConfigTable =
	() => (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		try {
			const state = getState();

			const templateConfig = projectReportTableRowsSelector(state);

			const newRow: ProjectPowerPointTemplateSelection = {
				status: "all",
				slide_type: "single-line",
				order: "full_name",
			};

			dispatch(setProjectReportTableRows([...templateConfig, newRow]));
		} catch (e) {
			console.log(e);
		}
	};

export const getPresentationAvailableTemplates = () => async (dispatch: Dispatch<TAppDispatch>) => {
	try {
		const response: AxiosResponse = await mapxProjectApi.getPresentationAvailableTemplates();

		if (successResponse(response, 200)) {
			const data = response.data.templates as TPresentationAvailableTemplate[];

			dispatch(setPresentationAvailableTemplates(data));
		}
	} catch (error) {
		return {error};
	}
};
