import {
	BACKGROUND_BULK_IMPORTS_IN_PROGRESS,
	CLEAR_PROJECT_CANDIDATE_ASSESSMENT_PROCESS,
	PROJECT_CANDIDATE_ASSESSMENT_PROCESS_REQUEST_CREATE_IN_PROGRESS,
	REMOVE_IMPORT_DATA_FROM_BULK_IMPORT_STATE,
	REMOVE_REFRESH_DATA_FROM_BULK_IMPORT_STATE,
	SET_BACKGROUND_BULK_IMPORTS_IN_PROGRESS_TO_WATCHER,
	SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_CURRENT_IMPORT,
	SET_PROJECT_CANDIDATE_ASSESSMENT_PROCESS,
	UPDATE_CANDIDATE_ASSESSMENT_STATUS_ON_BT,
} from "../mapXActionTypes";
import {AnyAction} from "redux";
import {TBackgroundTaskReducerInitialState} from "store/mapx/background-tasks/types";
import {
	TLinkedInUrlImportItem,
	TProfilesImportInProgress,
} from "mapx-components/AddPeopleByLinkedIn/types";

export const backgroundTaskReducerInitialState: TBackgroundTaskReducerInitialState = {
	isLastBulkImportReportDataLoading: true,
	activeCandidateAssessmentForProject: null,
	bulkLinkedinProfileImports: [],
	candidateAssessmentRequestCreateInProgress: false,
};

// @TODO SAVE BACKGROUND TASK BY DIFFERENT STANDALONE FEATURES,
// @TODO SO THAT WE CAN CREATE CUSTOM SELECTORS TO ACCESS THE NEEDED INFORMATION

const backgroundTaskReducer = (state = backgroundTaskReducerInitialState, action: AnyAction) => {
	switch (action.type) {
		case BACKGROUND_BULK_IMPORTS_IN_PROGRESS:
			return {
				...state,
				isLastBulkImportReportDataLoading: action.payload,
			};
		case SET_PROJECT_CANDIDATE_ASSESSMENT_PROCESS:
			return {
				...state,
				activeCandidateAssessmentForProject: action.payload,
				candidateAssessmentRequestCreateInProgress: false,
			};
		case UPDATE_CANDIDATE_ASSESSMENT_STATUS_ON_BT:
			const prevData = state.activeCandidateAssessmentForProject;
			const updatedData = prevData;

			if (updatedData) {
				const prevCandidateAssessments = updatedData.candidate_assessments;
				updatedData.candidate_assessments = prevCandidateAssessments.map((item) => {
					if (item.candidate_id === action.payload.candidate_id) {
						return {
							...item,
							is_assessed: action.payload.is_assessed,
						};
					}

					return item;
				});
			}

			return {
				...state,
				activeCandidateAssessmentForProject: updatedData ? {...updatedData} : null,
			};

		case PROJECT_CANDIDATE_ASSESSMENT_PROCESS_REQUEST_CREATE_IN_PROGRESS:
			return {
				...state,
				candidateAssessmentRequestCreateInProgress: action.payload,
			};
		case CLEAR_PROJECT_CANDIDATE_ASSESSMENT_PROCESS:
			return {
				...state,
				activeCandidateAssessmentForProject: null,
			};

		case SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_CURRENT_IMPORT: {
			const importsData = [...state.bulkLinkedinProfileImports];

			let updatedBulkImportData: TProfilesImportInProgress[];

			if (action.payload.purpose === "refresh") {
				// Check if there is already an import exists
				const existingEntryIndex = importsData.findIndex((existingObj) => {
					if (
						existingObj.project_id !== action.payload.project_id ||
						existingObj.purpose !== "refresh"
					) {
						return false;
					}
					const existingUrls = existingObj.linkedin_candidate_urls
						.map((urlObj) => urlObj.url)
						.sort();
					const newUrls = action.payload.linkedin_candidate_urls
						.map((urlObj: TLinkedInUrlImportItem) => urlObj.url)
						.sort();

					return (
						existingUrls.length === newUrls.length &&
						existingUrls.every((url, index) => url === newUrls[index])
					);
				});

				if (existingEntryIndex !== -1) {
					// Update the existing object
					updatedBulkImportData = [...importsData];
					updatedBulkImportData[existingEntryIndex] = {
						...updatedBulkImportData[existingEntryIndex],
						...action.payload,
					};
				} else {
					// Add a new object for the incoming payload
					updatedBulkImportData = [...importsData, action.payload];
				}
			} else {
				const existingIndex = importsData.findIndex(
					(item) =>
						item.project_id === action.payload.project_id &&
						item.purpose === action.payload.purpose,
				);

				if (existingIndex !== -1) {
					updatedBulkImportData = [...importsData];
					updatedBulkImportData[existingIndex] = {
						...updatedBulkImportData[existingIndex],
						...action.payload,
					};
				} else {
					updatedBulkImportData = [...importsData, action.payload];
				}
			}

			return {
				...state,
				bulkLinkedinProfileImports: updatedBulkImportData,
			};
		}

		case SET_BACKGROUND_BULK_IMPORTS_IN_PROGRESS_TO_WATCHER: {
			const importsData = [...state.bulkLinkedinProfileImports];
			const {response} = action.payload;

			const updatedResponseData: TProfilesImportInProgress = {
				...response.data,
				purpose: "import",
			};

			const existingIndex = importsData.findIndex(
				(item) => item.project_id === response.activeProjectInState?.id,
			);

			let updatedBulkImportData: TProfilesImportInProgress[];

			if (existingIndex !== -1) {
				updatedBulkImportData = [...importsData];
				updatedBulkImportData[existingIndex] = {
					...updatedBulkImportData[existingIndex],
					...updatedResponseData,
				};
			} else {
				updatedBulkImportData = [...importsData, updatedResponseData];
			}

			return {
				...state,
				bulkLinkedinProfileImports: updatedBulkImportData,
			};
		}

		case REMOVE_IMPORT_DATA_FROM_BULK_IMPORT_STATE: {
			const importKey = action.payload;

			const existingImportData = [...state.bulkLinkedinProfileImports];

			const updatedCandidateImports = existingImportData.filter(
				(item) => !(item.project_id === importKey && item.purpose === "import"),
			);

			return {
				...state,
				bulkLinkedinProfileImports: updatedCandidateImports,
			};
		}

		case REMOVE_REFRESH_DATA_FROM_BULK_IMPORT_STATE: {
			const importKey = action.payload.project_id;
			const candidateUrlsToRemove = action.payload.urls;

			const existingImportData = [...state.bulkLinkedinProfileImports];

			const updatedCandidateImports = existingImportData.filter((item) => {
				if (item.project_id === importKey && item.purpose === "refresh") {
					const existingUrls = item.linkedin_candidate_urls || [];

					// Check if all URLs to remove are in existing URLs (ignoring order)
					const isMatch = candidateUrlsToRemove.every(
						(removeUrlObj: TLinkedInUrlImportItem) =>
							existingUrls.some(
								(existingUrlObj) =>
									existingUrlObj.url === removeUrlObj.url &&
									existingUrlObj.status === removeUrlObj.status,
							),
					);

					return !isMatch;
				}

				return true;
			});

			return {
				...state,
				bulkLinkedinProfileImports: updatedCandidateImports,
			};
		}

		default:
			return state;
	}
};

export default backgroundTaskReducer;
