import React from "react";
import classNames from "classnames";
import styles from "../../Projects/Loader/styles.module.scss";
import {SkeletonLoaderProps} from "../types";
import ContentLoader from "react-content-loader";

const MarketReportSkeletonLoader = ({count = 5}: SkeletonLoaderProps) => {
	return (
		<div
			className={classNames(styles.skeletonContainer)}
			data-tooltip={"Loading market reports..."}
		>
			{Array.from({length: count}, (_, idx) => (
				<div key={idx} className={classNames(styles.skeletonContainer__card)}>
					<ContentLoader
						speed={2}
						height={120}
						width={"100%"}
						backgroundColor="#DCE7E7"
						foregroundColor="#F9F9F9"
					>
						{/* Market Report Name */}
						<rect x="10" y="20" rx="3" ry="3" width="200" height="20" />
						{/* Subtitle */}
						<rect x="10" y="50" rx="3" ry="3" width="180" height="15" />
						{/* Stats */}
						<rect x="10" y="75" rx="3" ry="3" width="110" height="15" />
						<rect x="130" y="75" rx="3" ry="3" width="110" height="15" />

						{/* Open Report Button */}
						<rect x="87%" y="40" rx="3" ry="3" width="140" height="25" />
						{/* Three dots */}
						<rect x="97%" y="-2.2" rx="1" ry="1" width="2px" height="30" />
					</ContentLoader>
				</div>
			))}
		</div>
	);
};

export default MarketReportSkeletonLoader;
