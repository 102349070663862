import AuthApi from "api/authApi";
import mapXHubspotApi from "api/hubspotApi";
import mapXIpApi from "api/ipApi/mapxIpApi";
import mapXOrganisationApi from "api/organisationApi";
import axios from "axios";
import {ToastContent} from "components";
import {Mixpanel} from "helpers/mixpanel";
import {toast} from "react-toastify";
import {companyLicenseTypesSelector} from "store/mapx/company/companySelectors";
import {getTargetListsByOwnerID} from "store/mapx/target-list/targetListAsyncActions";
import {userOrganisationRoleSelector, userSelector} from "store/mapx/user/userSelectors";
import {disconnectPusher} from "web-socket/pusherClient";
import {
	getOrganisationUsers,
	getOrganisationUsersForAdmin,
	getOrganizationTableData,
} from "../organisation/organisationAsyncFunctions";

import {
	changePasswordFailed,
	changePasswordFetch,
	deleteUserWithIdDone,
	deleteUserWithIdFailed,
	deleteUserWithIdInit,
	getLicenseTypeDone,
	getUserLocationDone,
	getUserLocationFail,
	getUserLocationInit,
	getUserNameFailed,
	getUserNameFetch,
	getUserNameSuccess,
	loadProfileDone,
	loadProfileFail,
	loadProfileInit,
	loginFailed,
	loginFetch,
	loginSuccess,
	logout,
	registerContactsBlockedByCountryDone,
	registerContactsBlockedByCountryFail,
	registerContactsBlockedByCountryInit,
	sendVerificationEmailDone,
	sendVerificationEmailFail,
	sendVerificationEmailInit,
	setUserCreditInformation,
	usersTransferDone,
	usersTransferFailed,
	usersTransferInit,
	usersUpdateDone,
	usersUpdateFailed,
	usersUpdateInit,
	verifyEmailDone,
	verifyEmailFail,
	verifyEmailInit,
	verifyVerificationDone,
	verifyVerificationFail,
	verifyVerificationInit,
} from "./userActions";

export const getLicenseTypes = () => async (dispatch) => {
	try {
		const response = await AuthApi.getLicenseTypes();

		if (response?.data) {
			dispatch(getLicenseTypeDone(response.data?.results));
		}
	} catch (e) {
		if (axios.isCancel(e)) {
			console.log("Request canceled:", e.message);
		}

		return {
			message: "Something went wrong!",
			error: e.toString(),
		};
	}
};

export const getUserProfileInformation = () => async () => {
	try {
		const responseData = await AuthApi.getUser();

		return responseData.data.result;
	} catch (e) {
		if (axios.isCancel(e)) {
			console.log("Request canceled:", e.message);
		}

		return {
			message: "Something went wrong!",
			error: e.toString(),
		};
	}
};

export const getUserChannel = () => async () => {
	try {
		const responseData = await AuthApi.getUserChannel();

		return responseData.data;
	} catch (e) {
		if (axios.isCancel(e)) {
			console.log("Request canceled:", e.message);
		}

		return {
			message: "Something went wrong!",
			error: e.toString(),
		};
	}
};

export const updateUserCreditInformation = () => async (dispatch) => {
	try {
		const responseData = await dispatch(getUserProfileInformation());

		if (responseData) {
			const payload = {
				credits: responseData.credits,
				license_type: responseData.license,
				credit_refresh: responseData.credits_refresh_at,
			};

			dispatch(setUserCreditInformation(payload));
		}
	} catch (e) {
		if (axios.isCancel(e)) {
			console.log("Request canceled:", e.message);
		}

		return {
			message: "Something went wrong!",
			error: e.toString(),
		};
	}
};

export const loadProfile = () => async (dispatch) => {
	try {
		dispatch(loadProfileInit());

		const {
			data: {result: user},
		} = await AuthApi.getUser();

		const pusherChannels = await dispatch(getUserChannel());

		const savedUser = {
			app_key: pusherChannels?.app_key,
			channel: pusherChannels?.channel,
			company: user.company,
			companyId: user.company_id,
			credit_refresh: user.credits_refresh_at,
			credits: user.credits,
			email: user.email,
			firstName: user.first_name,
			image: user.image,
			is_invenias_user: user.is_invenias || false,
			isActive: user.is_active,
			isSuperUser: user?.is_superuser || false,
			lastName: user.last_name,
			license: user.license,
			organisation_role: user.organisation_role,
			organisation: user.organisation,
			role: user.role,
			user_id: user.user_id,
		};

		window.localStorage.setItem("user", JSON.stringify(savedUser));

		dispatch(loadProfileDone(savedUser));
	} catch (e) {
		dispatch(loadProfileFail(e));
	}
};

const handleUserLoggedIn = async (result, dispatch) => {
	try {
		window.localStorage.clear();
		window.localStorage.setItem("token", result.data.access_token);
		window.localStorage.setItem("refresh_token", result.data.refresh_token);
		window.localStorage.setItem(
			"expires_in",
			(new Date().getTime() + 1000 * result.data.expires_in).toString(),
		);

		// await HttpClient.resetConstructor();

		const user = await dispatch(getUserProfileInformation());

		if (user?.is_active) {
			const pusherChannels = await dispatch(getUserChannel());

			const savedUser = {
				company: user.company,
				companyId: user.company_id,
				credit_refresh: user.credits_refresh_at,
				credits: user.credits,
				email: user.email,
				firstName: user.first_name,
				image: user.image,
				is_invenias_user: user.is_invenias || false,
				isActive: user.is_active,
				isSuperUser: user?.is_superuser || false,
				lastName: user.last_name,
				license: user.license,
				organisation_role: user.organisation_role,
				organisation: user.organisation,
				role: user.role,
				user_id: user.user_id,
				app_key: pusherChannels?.app_key,
				channel: pusherChannels?.channel,
			};

			window.localStorage.setItem("user", JSON.stringify(savedUser));

			dispatch(loginSuccess(savedUser));

			dispatch(getTargetListsByOwnerID());

			Mixpanel.identify(user.email);
			Mixpanel.track("logged in", {distinct_id: user.email});
			Mixpanel.people.set({
				$first_name: user.first_name,
				$last_name: user.last_name,
				$email: user.email,
				organisation: user.organisation?.name,
				distinct_id: user.email,
			});

			return true;
		} else {
			localStorage.clear();

			const error = {
				message:
					"This account is not active yet! Contact administrator for more information!",
			};

			dispatch(loginFailed({error}));

			Mixpanel.track("inactive user", {email: user.email, distinct_id: user.email});

			return {error};
		}
	} catch (e) {
		if (axios.isCancel(e)) {
			console.log("Request canceled:", e.message);
		}

		return {
			message: "Authentication failed!",
		};
	}
};

export const loginUser = (userData) => async (dispatch) => {
	dispatch(loginFetch());
	let result = {};

	try {
		result = await AuthApi.login(userData);
	} catch (error) {
		result = {error: error.data};
	}

	if (result.status === 200) {
		return handleUserLoggedIn(result, dispatch);
	} else {
		const error = result.data.detail;

		dispatch(loginFailed({error}));

		return {error};
	}
};

export const logoutUser = () => async (dispatch, getState) => {
	const state = getState();
	const user = userSelector(state);

	try {
		if (user && user?.email) {
			Mixpanel.track("logged out", {distinct_id: user.email});
			Mixpanel.reset();
		}

		disconnectPusher();

		// AuthApi.logout({
		// 	refresh_token: window.localStorage.getItem("refresh_token"),
		// });

		dispatch(logout());
	} catch (error) {
		console.log(error);
	}

	setTimeout(() => {
		window.localStorage.clear();
		// window.location.reload();
	}, 1000);

	// window.localStorage.clear();
};

export const logoutBulk = () => async () => {
	try {
		await AuthApi.logoutBulk({
			refresh_token: window.localStorage.getItem("refresh_token"),
		});

		return true;
	} catch (e) {
		if (axios.isCancel(e)) {
			console.log("Request canceled:", e.message);
		}

		return false;
	}
};

export const getUserName = (email) => async (dispatch) => {
	dispatch(getUserNameFetch());
	let result = {};

	try {
		result = await AuthApi.getUserName({email});
	} catch (error) {
		result = {error: error.data};
	}

	if (result.status === 204) {
		dispatch(getUserNameSuccess(email));

		return true;
	} else {
		const error = result.data.detail;

		dispatch(getUserNameFailed({error}));

		return {error};
	}
};

export const verifyEmailAccount = (userData) => async (dispatch) => {
	let result = {};

	try {
		dispatch(verifyEmailInit());

		result = await AuthApi.verifyEmail(userData);
		dispatch(verifyEmailDone());

		toast.success(ToastContent, {
			data: {title: "The reset password email has been sent successfully!"},
		});
	} catch (error) {
		dispatch(verifyEmailFail());

		result = {error: error.data};
	}

	if (result.status === 200) {
		return true;
	} else {
		const error = result.data.detail;

		return {error};
	}
};

export const resetPassword = (code, formData) => async () => {
	let result = {};

	const data = {new_password: formData.newPassword, code};

	try {
		result = await AuthApi.resetPassword(data);
	} catch (error) {
		result = {error: error.data};
	}

	if (result.status === 200) {
		return true;
	} else {
		const error = result.data.detail;

		return {error};
	}
};

export const deleteUser = (user_id, organisation_id) => async (dispatch, getState) => {
	try {
		dispatch(deleteUserWithIdInit());

		const state = getState();
		const role = userOrganisationRoleSelector(state);

		const {data} = await mapXOrganisationApi.deleteUser(user_id);

		if (role === "Admin") {
			dispatch(getOrganisationUsersForAdmin());
		} else {
			await dispatch(getOrganizationTableData());
			await dispatch(getOrganisationUsers(organisation_id));
		}

		dispatch(deleteUserWithIdDone(data));

		toast.success(ToastContent, {data: {title: "The user deleted successfully!"}});
	} catch (error) {
		dispatch(deleteUserWithIdFailed({error}));

		toast.error(ToastContent, {data: {title: "Something went wrong!"}});

		return {error};
	}
};

export const userUpdate = (userID, body, organization) => async (dispatch, getState) => {
	try {
		const state = getState();

		const user = userSelector(state);

		const role = userOrganisationRoleSelector(state);

		const licenseTypes = companyLicenseTypesSelector(state);

		dispatch(usersUpdateInit(userID));

		const {data, status} = await mapXOrganisationApi.userUpdate(userID, body);

		if (status === 200) {
			dispatch(usersUpdateDone(data, userID));
			if (user.user_id === userID) {
				dispatch(updateUserCreditInformation());
				dispatch(loadProfile());
			}
			if (organization.id !== body.organisation_id && role !== "Admin") {
				dispatch(getOrganizationTableData());
			}

			if (Object.keys(body).length === 1 && body.license_type_id) {
				const license = licenseTypes.find((lt) => lt.id === body.license_type_id);

				toast.success(ToastContent, {
					data: {
						title: `User ${license.name === "Starter" ? "downgraded" : "upgraded"} to ${
							license.name
						} successfully!`,
					},
				});
			} else {
				toast.success(ToastContent, {data: {title: "User info updated successfully!"}});
			}

			return data;
		} else {
			dispatch(usersUpdateFailed(data, userID));
			toast.error(ToastContent, {data: {title: data?.detail}});
		}
	} catch (error) {
		dispatch(usersUpdateFailed(error, userID));

		toast.error(ToastContent, {data: {title: "Something went wrong!"}});

		return {error};
	}
};

export const usersTransferOwnership = (body) => async (dispatch) => {
	try {
		dispatch(usersTransferInit());

		const {data} = await mapXOrganisationApi.usersTransfer(body);

		dispatch(usersTransferDone(data));

		toast.success(ToastContent, {data: {title: "The ownership has been moved successfully!"}});
	} catch (error) {
		dispatch(usersTransferFailed({error}));

		toast.error(ToastContent, {data: {title: "Something went wrong!"}});

		return {error};
	}
};

export const changePassword = (formData) => async (dispatch) => {
	const response = await AuthApi.changePassword(formData);

	const detailedError = response?.data?.detail || "Something went wrong!";

	if (detailedError) {
		dispatch(changePasswordFailed(detailedError));
	} else {
		dispatch(changePasswordFetch(formData));
	}

	return response;
};

export const registerUser = (code, formData) => async (dispatch) => {
	let result = {};

	try {
		result = await AuthApi.register(code, formData);
	} catch (error) {
		result = {error: error.data};
	}

	if (result?.data?.access_token) {
		return handleUserLoggedIn(result, dispatch);
	} else {
		const error = result.data.detail;

		return {error};
	}
};

export const verifyVerificationCode = (verificationCode) => async (dispatch) => {
	try {
		dispatch(verifyVerificationInit(verificationCode));

		const response = await AuthApi.verifyVerificationCode(verificationCode);

		if (response.status === 200) {
			dispatch(verifyVerificationDone(verificationCode));

			Mixpanel.track(`Signup: Verification confirmed`, {
				pageTitle: "Signup Page",
				description: verificationCode,
				location: window.location.pathname,
			});
		} else if (response?.data?.status === 404) {
			Mixpanel.track(`Signup: Verification code expired`, {
				pageTitle: "Signup Page",
				description: verificationCode,
				location: window.location.pathname,
			});

			window.location = "/invitation-invalid";

			throw new Error(response.data.detail);
		} else {
			throw new Error(response?.data?.detail);
		}

		return response;
	} catch (error) {
		dispatch(verifyVerificationFail(error));

		return {error};
	}
};

export const sendVerificationEmail = (email) => async (dispatch) => {
	try {
		dispatch(sendVerificationEmailInit(email));

		Mixpanel.track(`Signup: Email Entered`, {
			email: email,
			distinct_id: email,
			pageTitle: "Signup Page",
			location: window.location.pathname,
		});

		const search = new URLSearchParams(window.location.search);

		let params = {};
		for (let [key, value] of search) {
			params[key] = value;
		}

		const response = await AuthApi.sendVerificationEmail(email, params);

		if (response.status === 200 || response.status === 201) {
			dispatch(sendVerificationEmailDone(email));

			Mixpanel.track(`Signup: Email verification successfully submitted`, {
				email: email,
				distinct_id: email,
				pageTitle: "Signup Page",
				location: window.location.pathname,
			});
		} else if (response.status === 400) {
			dispatch(sendVerificationEmailFail(response?.data?.message || response?.data?.detail));

			Mixpanel.track(`Signup: Email submission validation failed`, {
				email: email,
				distinct_id: email,
				pageTitle: "Signup Page",
				location: window.location.pathname,
			});
		} else if (response.status === 404) {
			Mixpanel.track(`Signup: Email submission error`, {
				email: email,
				distinct_id: email,
				pageTitle: "Signup Page",
				description: `Error Code: ${response.status}`,
				location: window.location.pathname,
			});

			throw new Error(response?.data?.message);
		} else {
			Mixpanel.track(`Signup: Email submission error`, {
				email: email,
				distinct_id: email,
				pageTitle: "Signup Page",
				description: `Error Code: ${response.status}`,
				location: window.location.pathname,
			});

			throw new Error(response?.data?.message);
		}

		return response;
	} catch (error) {
		dispatch(sendVerificationEmailFail(error));

		return {error};
	}
};

export const getUserLocation = () => async (dispatch) => {
	try {
		dispatch(getUserLocationInit());

		const {data: location} = await mapXIpApi.getLocation();

		dispatch(getUserLocationDone(location));
	} catch (error) {
		dispatch(getUserLocationFail(error));
	}
};

export const registerBlockedByCountryForm = (payload) => async (dispatch) => {
	try {
		dispatch(registerContactsBlockedByCountryInit());

		await mapXHubspotApi.registerContactsBlockedByCountryForm(payload);

		dispatch(registerContactsBlockedByCountryDone());
	} catch (error) {
		dispatch(registerContactsBlockedByCountryFail(error));
	}
};
