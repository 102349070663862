import React, {ChangeEvent, useCallback, useEffect, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector, useModalHook} from "hooks";
import {ModalHeader, ModalSeparator} from "components";
import {TCriteriaWiseFitToBriefData, TEditFitToBriefModalProps} from "../types";
import styles from "../styles.module.scss";
import ModalFormHeaderInformation from "../../ModalFormHeaderInformation";
import SelectDropdown from "components/Inputs/SelectDropdown";
import {handleUpdateAssessmentForProjectCandidate} from "store/mapx/project/projectAssessmentAsyncActions";
import SearchTextArea from "../../../Inputs/SearchTextArea";
import {TextTabPane, TextTabs} from "../../../index";
import {SICandidate, TCandidateAssessmentVerdictItem} from "api/candidateApi/types";
import UserIntroInfo from "../CandidateInformation/UserIntroInfo";
import UserAvatarImage from "../CandidateInformation/UserAvatarImage";
import {currentCandidateSelector} from "store/mapx/candidate/candidateSelectors";
import classNames from "classnames";

const EditFitToBriefModal = ({
	modalDisplay,
	setModalDisplay,
	assessments,
}: TEditFitToBriefModalProps) => {
	const [activeTab, setActiveTab] = useState(0);
	const currentCandidate: SICandidate = useAppSelector(currentCandidateSelector);

	const [tabData, setTabData] = useState<TCriteriaWiseFitToBriefData[]>([]);

	const [updatingAssessmentIndex, setUpdatingAssessmentIndex] = useState<Nullable<number>>(null);

	const dispatch = useAppDispatch();
	const [loadingState, setLoadingState] = useState<Record<number, boolean>>({});
	const handleModalHide = useCallback(() => setModalDisplay(false), [setModalDisplay]);

	const {modalIsOpen, customStyles, Modal} = useModalHook(
		{
			content: {
				maxWidth: "792px",
				borderRadius: "4px",
				width: "99%",
				boxShadow: "0px 25px 40px -10px rgba(79, 75, 74, 0.08)",
			},
		},
		modalDisplay,
	);

	useEffect(() => {
		if (
			modalDisplay &&
			assessments?.length > 0 &&
			Object.values(loadingState).every((value) => !value)
		) {
			const initialTabData = assessments.map((assessmentItem) => ({
				assessment: assessmentItem.assessment ?? "",
				fitToBriefId: assessmentItem.id,
				verdict: assessmentItem.verdict ?? "",
			}));

			setTabData(initialTabData);
		}
	}, [assessments, activeTab, loadingState, modalDisplay]);

	const updateFitToBriefScoring = useCallback(
		async (tabIndex: number, updatedTabData: TCriteriaWiseFitToBriefData[]) => {
			const {assessment, verdict, fitToBriefId} = updatedTabData[tabIndex];

			setLoadingState((prev) => ({...prev, [tabIndex]: true}));

			return dispatch(
				handleUpdateAssessmentForProjectCandidate(
					currentCandidate?.id,
					fitToBriefId,
					verdict,
					assessment,
				),
			);
		},
		[currentCandidate, dispatch],
	);

	useEffect(() => {
		const delayDebounceFn = setTimeout(async () => {
			if (updatingAssessmentIndex !== null) {
				updateFitToBriefScoring(updatingAssessmentIndex, tabData).then((res) => {
					if (res?.status) {
						setLoadingState((prev) => ({...prev, [updatingAssessmentIndex]: false}));
					}
				});
				setUpdatingAssessmentIndex(null);
			}
		}, 800);

		return () => clearTimeout(delayDebounceFn);
	}, [updatingAssessmentIndex, tabData, updateFitToBriefScoring]);

	const handleAssessmentChange = useCallback(
		(e: ChangeEvent<HTMLTextAreaElement>, tabIndex: number) => {
			const updatedAssessment = e.target.value;

			setUpdatingAssessmentIndex(tabIndex);

			setTabData((prev) =>
				prev.map((tab, index) =>
					index === tabIndex ? {...tab, assessment: updatedAssessment} : tab,
				),
			);
		},
		[],
	);

	const handleVerdictChange = useCallback(
		(verdict: TCandidateAssessmentVerdictItem, tabIndex: number) => {
			setTabData((prev) => {
				const updatedTabData = prev.map((tab, index) => {
					if (index === tabIndex) {
						return {...tab, verdict};
					}

					return tab;
				});
				updateFitToBriefScoring(tabIndex, updatedTabData).then((res) => {
					if (res?.status) {
						setLoadingState((prev) => ({...prev, [tabIndex]: false}));
					}
				});

				return updatedTabData;
			});
		},
		[updateFitToBriefScoring],
	);

	const handleTabChange = useCallback(
		(newTabIndex: number) => {
			if (newTabIndex !== activeTab) {
				setActiveTab(newTabIndex);
			}
		},
		[activeTab],
	);

	const tabs = useMemo(() => {
		return assessments?.map((assessmentItem, index) => {
			const {criterion} = assessmentItem;

			return (
				<TextTabPane title={criterion.name} key={`criteria-${index}`}>
					<div className={styles.updateInputsRow}>
						<div className={styles.textarea}>
							<SearchTextArea
								data-testid={`assessment-edit-for-candidate-${index}`}
								className={styles.assessment}
								label="Assessment"
								onChange={(e) => handleAssessmentChange(e, index)}
								type="text"
								value={tabData[index]?.assessment ?? ""}
							/>
						</div>
						<div className={styles.updateInputContainer}>
							<label htmlFor={`verdict-${index}`}>Verdict</label>
							<SelectDropdown
								customClass={styles.margin}
								aria-label="Verdict"
								name={`verdict-${index}`}
								isAsync={false}
								onChange={(value: {name: TCandidateAssessmentVerdictItem}) =>
									handleVerdictChange(value.name, index)
								}
								options={[{name: "Yes"}, {name: "No"}, {name: "Maybe"}]}
								defaultValue={{name: tabData[index]?.verdict ?? ""}}
								isClearable={false}
								hideIndicator={false}
								noOptionsMessage="Choose verdict"
							/>
						</div>
					</div>

					<small
						className={classNames(styles.loaderText, {
							[styles.hide]: !loadingState[index],
						})}
					>
						Saving...
					</small>
				</TextTabPane>
			);
		});
	}, [assessments, tabData, handleAssessmentChange, handleVerdictChange, loadingState]);

	useEffect(() => {
		return () => {
			setTabData([]);
			setLoadingState({});
		};
	}, []);

	return (
		<Modal
			contentLabel="Modal"
			isOpen={modalIsOpen}
			onRequestClose={handleModalHide}
			style={customStyles}
			title="Update Score"
			shouldCloseOnOverlayClick={false}
		>
			<ModalHeader title="Update Score" onClose={handleModalHide} />

			<ModalSeparator />

			<div className={styles.fitToBriefForm}>
				<ModalFormHeaderInformation
					icon={UserAvatarImage(
						currentCandidate?.avatar_url,
						currentCandidate?.full_name ?? currentCandidate?.name,
					)}
					title={currentCandidate?.full_name ?? currentCandidate?.name}
					description={UserIntroInfo(currentCandidate)}
				/>

				<div className={styles.targetTabArea}>
					<TextTabs
						className={styles.tabs}
						activeTab={activeTab}
						onTabChange={handleTabChange}
					>
						{tabs}
					</TextTabs>
				</div>
			</div>
		</Modal>
	);
};

export default EditFitToBriefModal;
