import {Dispatch} from "react";
import {TAppDispatch, TRootState} from "types";
import mapXCandidateApi from "api/candidateApi";
import {SICandidate, TGenerateSummariesApiPayload} from "api/candidateApi/types";
import {
	filteredSavedCandidatesSelector,
	targetListCandidatesSelector,
	targetListIDSelector,
} from "store/mapx/target-list/targetListSelectors";
import {successResponse} from "helpers/map";
import {getAllGenerateSummaryCandidateIdsForCurrentProjectSelector} from "store/mapx/project/generateCandidateSummarySelectors";
import {setGenerateSummaryCandidateIdsForCurrentProject} from "store/mapx/project/projectActions";
import {ICandidateByIdsApiRequestPayload} from "api/candidateApi/form";
import axios from "axios";
import {
	updateFilteredTargetListCandidatesWithSummary,
	updateTargetListCandidatesWithSummary,
} from "store/mapx/target-list/targetListActions";
import {updateCandidateProfileAfterRefresh} from "../refresh-candidate/refreshCandidateActions";
import {updateProjectSavedPeopleCandidatesInTheList} from "../target-list/targetListCandidatesAsyncActions";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const cancelTokens = {} as any;

export const generateCandidatesSummary =
	(candidateIds: number[]) => async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		try {
			const state = getState();

			const targetListId = targetListIDSelector(state);

			const allIds = getAllGenerateSummaryCandidateIdsForCurrentProjectSelector(
				state,
				targetListId,
			);

			let ids: number[] = [];

			if (allIds && Array.isArray(allIds)) {
				ids = allIds;
			}

			ids = [...candidateIds, ...ids];

			const candidateIdsByProject = {[targetListId]: ids};

			dispatch(setGenerateSummaryCandidateIdsForCurrentProject(candidateIdsByProject));

			const apiPayload: TGenerateSummariesApiPayload = {
				candidate_ids: ids,
				target_list_id: targetListId,
			};

			await mapXCandidateApi.generateSummaries(apiPayload);
		} catch (e) {
			console.error(e);
		}
	};

export const checkCandidateSummaryGeneratedStatus =
	(candidateIds: number[]) => async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		const requestKey = `GET_GENERATE_SUMMARY_CANDIDATE_STATUS`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		const state = getState();

		const targetListId = targetListIDSelector(state);

		try {
			const apiPayload: ICandidateByIdsApiRequestPayload = {
				filters: {
					candidates: candidateIds,
					target_list: targetListId,
				},
			};

			const response = await mapXCandidateApi.getCandidatesByIds(apiPayload, config);
			if (successResponse(response, 200)) {
				const results = response.data.results;

				const savedPeoplesInState = targetListCandidatesSelector(state);
				const filteredSavedPeoplesInState = filteredSavedCandidatesSelector(state);

				let updatedIds = [...candidateIds];

				const updatedPeopleForState: SICandidate[] = [];
				const updatedFilteredPeopleForState: SICandidate[] = [];

				savedPeoplesInState.forEach((candidate: SICandidate) => {
					const isCandidateInList = candidateIds.includes(candidate.id);
					const candidateFromResults = results.find(
						(c: SICandidate) => c.id === candidate.id,
					);
					const updatedCandidate = candidateFromResults ?? candidate;

					if (isCandidateInList && updatedCandidate.summary !== null) {
						dispatch(updateCandidateProfileAfterRefresh(updatedCandidate));
						updatedIds = updatedIds.filter((id) => id !== candidate.id);
					}

					updatedPeopleForState.push(updatedCandidate);

					if (
						filteredSavedPeoplesInState.some((c: SICandidate) => c.id === candidate.id)
					) {
						updatedFilteredPeopleForState.push(updatedCandidate);
					}
				});

				dispatch(updateTargetListCandidatesWithSummary(updatedPeopleForState));

				dispatch(
					updateFilteredTargetListCandidatesWithSummary(updatedFilteredPeopleForState),
				);

				dispatch(
					setGenerateSummaryCandidateIdsForCurrentProject({
						[targetListId]: [...updatedIds],
					}),
				);

				if (updatedIds.length === 0) {
					dispatch(updateProjectSavedPeopleCandidatesInTheList());
				}
			}
		} catch (e) {
			console.error(e);
		}
	};
