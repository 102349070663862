import React, {useMemo} from "react";
import {SearchInput} from "mapx-components";
import css from "./StatusSearchFilter.module.scss";
import useFetchListOptions from "hooks/useFetchListOptions";
import {Checkbox, CheckboxSkeletonLoader, ResetFilters, Tag} from "components";
import {getName} from "helpers/string";
import CheckboxList from "mapx-components/Inputs/CheckboxList";
import {useAppSelector} from "hooks";
import {TStatusesSearchFilterProps, TStatusItem} from "./types";
import {projectProgressStatusesSelector} from "store/mapx/project/projectSelectors";
import {getProjectProgressStatuses} from "store/mapx/project/projectAsyncActions";
import FilterTags from "../../FilterTags";

export default function StatusSearchFilter({
	handleResetClick,
	handleOnChange,
	selectedStatuses,
}: TStatusesSearchFilterProps) {
	const statusOptions = useAppSelector(projectProgressStatusesSelector);

	const {filteredData, onFilterChanged, loading} = useFetchListOptions({
		options: statusOptions,
		callbackMethod: getProjectProgressStatuses,
	});

	const typedFilteredData = filteredData as TStatusItem[];

	const reorderedData = useMemo(() => {
		return typedFilteredData?.filter((item) => !selectedStatuses.includes(item.id));
	}, [typedFilteredData, selectedStatuses]);

	const selectedStatusItems = useMemo(() => {
		return statusOptions?.filter((option: TStatusItem) => selectedStatuses.includes(option.id));
	}, [statusOptions, selectedStatuses]);

	return (
		<div>
			<SearchInput
				onChange={onFilterChanged}
				placeholder="Search for a status"
				type="text"
				errorText={undefined}
				errorClass={undefined}
				isLoading={loading}
			/>

			<ResetFilters
				parentStyle={{color: "#5A5A5A", marginRight: 19}}
				onClick={handleResetClick}
				displayIcon={true}
			>
				Clear Selection
			</ResetFilters>

			<div className={css.checkboxList}>
				<CheckboxList data-testid={"statusList"}>
					{reorderedData?.map((item: TStatusItem) => (
						<Checkbox
							borderColor="#0C5850"
							containerClass={css.checkboxContainer}
							isChecked={selectedStatuses.includes(item.id)}
							key={item.id}
							label={getName(item.name)}
							onChange={() => handleOnChange(item)}
							value={getName(item.name)}
						/>
					))}
				</CheckboxList>

				{loading && <CheckboxSkeletonLoader />}
			</div>

			{selectedStatuses?.length > 0 && (
				<div style={{margin: "8px 0"}}>
					<FilterTags
						onDrop={(e) => e.preventDefault()}
						label={"Selected Statuses"}
						count={selectedStatusItems?.length}
						moveDisabled={true}
						tags={selectedStatusItems?.map((tag: TStatusItem) => (
							<Tag key={tag.id} onClick={() => handleOnChange(tag)}>
								{`${tag.name}` || ""}
							</Tag>
						))}
						onClear={handleResetClick}
					/>
				</div>
			)}
		</div>
	);
}
