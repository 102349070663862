import React, {useCallback, useEffect, useMemo, useState} from "react";
import styles from "./styles.module.scss";
import {RadioInput} from "components";
import {GeneralButton} from "mapx-components/index";
import {NotchArrowRight} from "assets/icons";
import {setProjectTabIndex} from "store/mapx/project-list/projectListActions";
import {useAppDispatch, useAppSelector} from "hooks";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {useHistory} from "react-router-dom";
import classNames from "classnames";
import {setProjectChecklistFullDisplay} from "store/mapx/project/projectActions";
import {
	displayFullProjectChecklistSelector,
	projectSuccessChecklistOptionsSelector,
} from "store/mapx/project/projectSelectors";
import {
	TProjectSuccessChecklistTitle,
	TProjectSuccessCheckOption,
} from "mapx-components/Layouts/ProjectChecklist/types";
import {
	getProjectSuccessChecklist,
	projectSuccessChecklistFormUpdate,
} from "store/mapx/project/projectSuccessChecklistAsyncActions";
import {STProject, TProjectChecklistForm} from "api/projectApi/types";
import SkeletonLoader from "./skeletonLoader";
import ProjectTabs from "mapx-pages/Project/ProjectTabs";
import {cancelRequest} from "api/cancelTokens";
import {GET_PROJECT_SUCCESS_CHECKLIST_TOKEN} from "api/requestCancelTokenStrings";

const ProjectChecklist = () => {
	const selectedProject: STProject = useAppSelector(projectSelector);

	const displayMax = useAppSelector(displayFullProjectChecklistSelector);

	const checkOptions: TProjectSuccessCheckOption[] = useAppSelector(
		projectSuccessChecklistOptionsSelector,
	);

	const [loading, setLoading] = useState(false);

	const history = useHistory();

	const dispatch = useAppDispatch();

	const handleViewButtonClick = useCallback(
		(src: TProjectSuccessChecklistTitle, toUrl: string) => {
			toUrl = toUrl.replace(/\{id}/g, selectedProject.id.toString());

			if (src === "Score & Rank") {
				dispatch(setProjectTabIndex(ProjectTabs.savedPeopleTabIndex));
				toUrl = `${toUrl}?openFitToBrief=true`;
			} else if (src === "Download PPT Report") {
				dispatch(setProjectTabIndex(ProjectTabs.shareTabIndex));
			}

			history.push(toUrl);
		},
		[dispatch, history, selectedProject?.id],
	);

	const completedTask = useMemo(() => {
		return checkOptions.reduce(
			(accumulator, currentValue) => accumulator + (currentValue.checked ? 1 : 0),
			0,
		);
	}, [checkOptions]);

	const updateFormIfNeeded = useCallback(
		async (latestUpdates: TProjectSuccessCheckOption[]) => {
			const apiPayload: TProjectChecklistForm = latestUpdates?.reduce(
				(acc, curVal) => {
					return {...acc, [curVal.attribute]: curVal.checked};
				},
				{
					has_saved_companies: false,
					has_saved_people: false,
					has_shared_project: false,
					has_viewed_insights: false,
				},
			);

			let hasUpdate = false;

			latestUpdates?.forEach((option) => {
				if (!option.checked) {
					if (
						option.attribute === "has_saved_companies" &&
						selectedProject?.companies_number > 0
					) {
						hasUpdate = true;
						apiPayload[option.attribute] = true;
					}

					if (
						option.attribute === "has_saved_people" &&
						selectedProject?.candidates_number > 0
					) {
						hasUpdate = true;
						apiPayload[option.attribute] = true;
					}
				}
			});

			if (hasUpdate) {
				await dispatch(projectSuccessChecklistFormUpdate(apiPayload));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			dispatch,
			selectedProject?.candidates_number,
			selectedProject?.companies_number,
			selectedProject?.users?.length,
		],
	);

	useEffect(() => {
		const get = async () => {
			if (selectedProject?.id) {
				const latestUpdates = await dispatch(
					getProjectSuccessChecklist(selectedProject?.id),
				);

				await updateFormIfNeeded(latestUpdates);
			}
		};

		setLoading(true);

		get().then(() => {
			setLoading(false);
		});

		return () => {
			cancelRequest(GET_PROJECT_SUCCESS_CHECKLIST_TOKEN);
		};
	}, [dispatch, selectedProject?.id, updateFormIfNeeded]);

	return (
		<div className={styles.container}>
			<div
				className={styles.checklistHeader}
				onClick={() => dispatch(setProjectChecklistFullDisplay(!displayMax))}
			>
				<div className={styles.title}>
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<mask
							id="mask0_197_3892"
							style={{maskType: "alpha"}}
							maskUnits="userSpaceOnUse"
							x="0"
							y="0"
							width="20"
							height="20"
						>
							<rect width="20" height="20" fill="#D9D9D9" />
						</mask>
						<g mask="url(#mask0_197_3892)">
							<path
								d="M8.9375 13L13.8958 8.0625L12.8333 7L8.9375 10.875L7.16667 9.125L6.10417 10.1875L8.9375 13ZM4.5 17C4.0875 17 3.73437 16.8531 3.44062 16.5594C3.14687 16.2656 3 15.9125 3 15.5V4.5C3 4.0875 3.14687 3.73438 3.44062 3.44063C3.73437 3.14688 4.0875 3 4.5 3H8.0625C8.17361 2.56944 8.40625 2.21181 8.76042 1.92708C9.11458 1.64236 9.52778 1.5 10 1.5C10.4722 1.5 10.8854 1.64236 11.2396 1.92708C11.5938 2.21181 11.8264 2.56944 11.9375 3H15.5C15.9125 3 16.2656 3.14688 16.5594 3.44063C16.8531 3.73438 17 4.0875 17 4.5V15.5C17 15.9125 16.8531 16.2656 16.5594 16.5594C16.2656 16.8531 15.9125 17 15.5 17H4.5ZM4.5 15.5H15.5V4.5H4.5V15.5ZM10 4C10.1444 4 10.2639 3.95278 10.3583 3.85833C10.4528 3.76389 10.5 3.64444 10.5 3.5C10.5 3.35556 10.4528 3.23611 10.3583 3.14167C10.2639 3.04722 10.1444 3 10 3C9.85556 3 9.73611 3.04722 9.64167 3.14167C9.54722 3.23611 9.5 3.35556 9.5 3.5C9.5 3.64444 9.54722 3.76389 9.64167 3.85833C9.73611 3.95278 9.85556 4 10 4Z"
								fill="#2C3030"
							/>
						</g>
					</svg>
					Project Checklist
				</div>
				<div className={styles.checklistCount}>
					<span>
						{loading ? 0 : completedTask}/{checkOptions.length}
					</span>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						className={classNames(styles.toggleIcon, {[styles.rotated]: !displayMax})}
					>
						<mask
							id="mask0_197_3903"
							style={{maskType: "alpha"}}
							maskUnits="userSpaceOnUse"
							x="0"
							y="0"
							width="20"
							height="20"
						>
							<rect width="20" height="20" fill="#D9D9D9" />
						</mask>
						<g mask="url(#mask0_197_3903)">
							<path d="M10 12L6 8H14L10 12Z" fill="#4E5555" />
						</g>
					</svg>
				</div>
			</div>

			{loading && displayMax && <SkeletonLoader />}

			{!loading && (
				<div
					data-testid={"checklist-id"}
					className={classNames(styles.checklist, {[styles.opened]: displayMax})}
					aria-expanded={displayMax}
				>
					{checkOptions.map((item: TProjectSuccessCheckOption) => (
						<RadioInput
							readOnly
							key={item.title}
							inputName={item.title}
							isFeedback={true}
							label={item.title}
							description={
								<div>
									{/*<span className={styles.completedLabelDescription}>*/}
									{/*	{item.description}*/}
									{/*</span>*/}

									{item.view && (
										<GeneralButton
											title={"View"}
											className={styles.viewButton}
											handleClick={() =>
												handleViewButtonClick(item.title, item.url)
											}
										>
											<NotchArrowRight color={"#0C5850"} />
										</GeneralButton>
									)}
								</div>
							}
							checked={item.checked}
							containerClass={item.checked ? styles.completed : ""}
							labelClassName={item.checked ? styles.completedLabelText : ""}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default ProjectChecklist;
