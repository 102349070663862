import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import HttpClient from "api";
import {useBroadcastState} from "hooks/useBroadcastState";
import {STAuthResponse} from "api/types";
import {BROADCAST_CHANNELS} from "helpers/BoradcastChannel/BroadcastChannels";
import ContentLoader from "react-content-loader";

const TokenRefreshCheck = () => {
	const location = useLocation();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		const refreshTokenIfNeeded = async () => {
			const client = new HttpClient();
			await client.checkAndRefreshToken();
		};

		refreshTokenIfNeeded().finally(() => setLoading(false));
	}, [location]);

	useBroadcastState<STAuthResponse>(BROADCAST_CHANNELS.AUTH_TOKEN, (newState) => {
		const client = new HttpClient();
		client.setAccessToken(newState.access_token); // Update globally

		localStorage.setItem("refresh_token", newState.refresh_token);
		localStorage.setItem(
			"expires_in",
			String(new Date().getTime() + newState.expires_in * 1000),
		);
	});

	if (loading) {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "calculate(100vh - 72px)",
					padding: "80px 20px 0 20px",
				}}
			>
				<ContentLoader
					backgroundColor="#DCE7E7"
					foregroundColor="#F9F9F9"
					viewBox="0 0 800 660"
					height={800}
					width={800}
				>
					<rect x="0" y="13" rx="4" ry="4" width="800" height="9" />
					<rect x="0" y="29" rx="4" ry="4" width="400" height="8" />
					<rect x="0" y="50" rx="4" ry="4" width="700" height="10" />
					<rect x="0" y="65" rx="4" ry="4" width="500" height="10" />
					<rect x="0" y="79" rx="4" ry="4" width="400" height="10" />
					<rect x="0" y="99" rx="5" ry="5" width="800" height="80" />
					<rect x="0" y="189" rx="5" ry="5" width="300" height="40" />
					<rect x="0" y="239" rx="5" ry="5" width="600" height="60" />
					<rect x="0" y="310" rx="4" ry="4" width="700" height="10" />
					<rect x="0" y="325" rx="4" ry="4" width="500" height="10" />
					<rect x="0" y="345" rx="4" ry="4" width="400" height="20" />
				</ContentLoader>
			</div>
		);
	} else {
		return null;
	}
};

export default TokenRefreshCheck;
