import {Dispatch} from "react";
import {TAppDispatch, TRootState} from "types";
import mapxProjectApi from "api/projectApi";
import axios from "axios";
import {successResponse} from "helpers/map";
import {projectSuccessChecklistOptionsSelector} from "store/mapx/project/projectSelectors";
import {
	STProjectChecklist,
	TProjectSuccessAPIAttribute,
	TProjectSuccessCheckOption,
} from "mapx-components/Layouts/ProjectChecklist/types";
import {updateProjectSuccessChecklist} from "store/mapx/project/projectActions";
import {TProjectChecklistForm} from "api/projectApi/types";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {clearCancelToken, getCancelToken} from "api/cancelTokens";
import {
	GET_PROJECT_SUCCESS_CHECKLIST_TOKEN,
	PATCH_PROJECT_SUCCESS_CHECKLIST,
} from "api/requestCancelTokenStrings";

export const getProjectSuccessChecklist =
	(projectId: number) => async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		try {
			const config = {
				cancelToken: getCancelToken(GET_PROJECT_SUCCESS_CHECKLIST_TOKEN).token,
			};

			const state = getState();

			const response = await mapxProjectApi.getProjectSuccessChecklistInfo(projectId, config);

			if (response && successResponse(response, 200)) {
				const checklistResponse = response.data as STProjectChecklist;

				const options: TProjectSuccessCheckOption[] =
					projectSuccessChecklistOptionsSelector(state);

				const updatedOptions = options?.map((option: TProjectSuccessCheckOption) => {
					option.checked = checklistResponse[option.attribute];

					return option;
				});

				dispatch(updateProjectSuccessChecklist(updatedOptions));

				return updatedOptions;
			}
		} catch (e) {
			if (!axios.isCancel(e)) {
				console.error(`Error fetching project success checklist: ${e}`);
			}

			console.error(e);
		} finally {
			clearCancelToken(GET_PROJECT_SUCCESS_CHECKLIST_TOKEN);
		}
	};

export const updateProjectSuccessChecklistItem =
	({attribute, value}: {attribute: TProjectSuccessAPIAttribute; value: boolean}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		try {
			const state = getState();

			const options: TProjectSuccessCheckOption[] =
				projectSuccessChecklistOptionsSelector(state);

			// only if previously not checked
			const selectedOption = options.find((op) => op.attribute === attribute);

			if (selectedOption && selectedOption?.checked === false) {
				let apiPayload: TProjectChecklistForm = {
					has_saved_companies: false,
					has_saved_people: false,
					has_shared_project: false,
					has_viewed_insights: false,
				};

				for (const option of options) {
					if (attribute === option.attribute) {
						apiPayload = {...apiPayload, [attribute]: value};
					} else {
						apiPayload = {...apiPayload, [option.attribute]: option.checked};
					}
				}

				dispatch(projectSuccessChecklistFormUpdate(apiPayload));
			}
		} catch (e) {
			console.error(e);
		}
	};

export const projectSuccessChecklistFormUpdate =
	(apiPayload: TProjectChecklistForm) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		try {
			const config = {
				cancelToken: getCancelToken(PATCH_PROJECT_SUCCESS_CHECKLIST).token,
			};

			const state = getState();

			const project = projectSelector(state);

			if (project) {
				const response = await mapxProjectApi.updateProjectChecklistInfo(
					project.id,
					apiPayload,
					config,
				);

				if (response && successResponse(response, 200)) {
					const checklistResponse = response.data as STProjectChecklist;

					const options: TProjectSuccessCheckOption[] =
						projectSuccessChecklistOptionsSelector(state);

					const updatedOptions = options.map((option: TProjectSuccessCheckOption) => {
						option.checked = checklistResponse[option.attribute];

						return option;
					});

					dispatch(updateProjectSuccessChecklist(updatedOptions));
				}
			}
		} catch (e) {
			console.error(e);
		}
	};
