/** @format */

import HttpClient from "api/index";
import {STProjectsIdFrequentlyHiredFrom} from "api/projectApi/types";
import {buildQueryParameter} from "helpers/filterHandlers";

class Index extends HttpClient {
	async getGenderDiversityReport(projectId: number, config = {}) {
		return await this.doGet(`/mapx/gender-diversity-report?project_id=${projectId}`, config);
	}

	async getIndustriesReport(projectId: number, config = {}) {
		return await this.doGet(`/mapx/projects/${projectId}/industries-report`, config);
	}

	async getEthnicDiversityReport(projectId: number, config = {}) {
		return await this.doGet(`/mapx/ethnic-diversity-report?project_id=${projectId}`, config);
	}

	async getSpecialismPositionsReport() {
		return await this.doGet("/mapx/specialism-positions-report");
	}

	async getJobFunctionDistribution(projectId: number, config = {}) {
		return await this.doGet(
			`/mapx/specialism-positions-report?project_id=${projectId}`,
			config,
		);
	}

	async getProjectStats(projectId: number, config = {}) {
		return this.doGet(`/mapx/projects/${projectId}/stats`, config);
	}

	async getCountryReport(projectId: number, config = {}) {
		return await this.doGet(`/mapx/candidate-country-report?project_id=${projectId}`, config);
	}

	async getProjectFrequentlyHiredFrom(
		projectId: number,
		params: STProjectsIdFrequentlyHiredFrom,
		config = {},
	) {
		const paramsString = buildQueryParameter(params);

		return await this.doGet(
			`/mapx/projects/${projectId}/frequently-hired-from${paramsString ? paramsString : ""}`,
			config,
		);
	}

	async getIndustriesBackgroundReport(projectId: number, config = {}) {
		return this.doGet(`/industries-background-report?project_id=${projectId}`, config);
	}
}

const mapxProjectInsightsApi = new Index();

export default mapxProjectInsightsApi;
