/** @format */

import type {
	STAuthRegister,
	STAuthUserLogin,
	STAuthResetPassword,
	STAuthChangePassword,
	STAuthSendResetPasswordEmail,
} from "api/authApi/types";

import HttpClient from "api/index";

class AuthApi extends HttpClient {
	async login(userData: STAuthUserLogin) {
		userData = {...userData, grant_type: "password"};

		return this.doPost("/auth/login", userData);
	}

	// async logout(body: unknown) {
	// 	return this.doPost("/auth/logout", body);
	// }

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async logoutBulk(body: any) {
		return this.doPost("/auth/logout/bulk", body);
	}

	async getUser() {
		return this.doGet("/user/profile");
	}

	async getUserChannel() {
		return this.doGet("/channels/user");
	}

	async getUserStats(config = {}) {
		return this.doGet("/user/stats", config);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async getUserName({email}: any) {
		return this.doGet(`/auth/get_username?email_address=${email}`);
	}

	async getLicenseTypes() {
		return this.doGet("/license-types");
	}

	async verifyEmail(formData: STAuthSendResetPasswordEmail, config = {}) {
		return this.doPost("/auth/send-reset-password-email", formData, config);
	}

	async resetPassword(formData: STAuthResetPassword, config = {}) {
		return this.doPost(`/auth/reset-password`, formData, config);
	}

	async changePassword(formData: STAuthChangePassword, config = {}) {
		return this.doPost("/auth/change-password", formData, config);
	}

	async register(formData: STAuthRegister, config = {}) {
		return this.doPost("/auth/register", formData, config);
	}

	async verifyVerificationCode(verificationCode: string) {
		return this.doGet(`/verification-codes/${verificationCode}`);
	}

	async sendVerificationEmail(
		email: string,
		params?: {payment_url: string; payment_interval: string},
	) {
		return this.doPost("/auth/send-verification-email", {email, ...params});
	}
}

const index = new AuthApi();

export default index;
