import {LogoIcon} from "assets/icons";
import classNames from "classnames";
import {Loader} from "components";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Html2pdf from "html2pdf.js";

import {CandidateCard, EducationCard, MainWrapper, PageTitle} from "mapx-components";
import ExperienceCard from "mapx-components/Cards/ExperienceCard";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useReactToPrint} from "react-to-print";
import {getSingleCandidateData} from "store/mapx/candidate/candidateAsyncActions";
import {hasAccessToExtendedPagesSelector} from "store/mapx/user/userSelectors";
import colors from "styles/themes.module.scss";
import css from "./candidate.module.scss";
import {currentCandidateSelector} from "store/mapx/candidate/candidateSelectors";
import {useAppDispatch} from "hooks";
import {TParams} from "mapx-pages/CandidateDetails/types";
import {SICandidate, TCandidateEducation} from "api/candidateApi/types";

const CandidateDetails = () => {
	const dispatch = useAppDispatch();

	const params = useParams<TParams>();

	const history = useHistory();

	const currentCandidate: SICandidate = useSelector(currentCandidateSelector);

	const [loading, setLoading] = useState<boolean>(false);

	const currentRef = useRef(true);

	const search = useLocation().search;

	const recommended = new URLSearchParams(search).get("recommended");

	const originSrc = new URLSearchParams(search).get("src");

	const [printRequested, setPrintRequested] = useState(false);

	const hasAccessToExtendedPages = useSelector(hasAccessToExtendedPagesSelector);

	useEffect(() => {
		const candidateId = params.id;

		async function get() {
			await dispatch(getSingleCandidateData(candidateId));
		}

		if (candidateId !== currentCandidate?.id.toString()) {
			setLoading(true);
			get().finally(() => setLoading(false));
		}

		return () => {
			currentRef.current = false;
		};
	}, [currentCandidate?.id, dispatch, params.id]);

	const onPrintClick = () => {
		setPrintRequested(true);
	};

	useEffect(() => {
		document.addEventListener("keydown", (e) => {
			if ((e.metaKey || e.ctrlKey) && e.key === "p") {
				e.preventDefault();

				onPrintClick();
			}
		});

		window.onafterprint = function () {
			setPrintRequested(false);
		};
	}, []);

	const printRef = React.useRef(null);

	const handleDownload = useReactToPrint({
		onPrintError: (error) => console.log(error),
		content: () => printRef.current,
		print: async (printIframe) => {
			const document = printIframe.contentDocument;
			if (document) {
				const html = document.getElementsByTagName("html")[0];
				const exporter = new Html2pdf(html, {
					filename: `Talent MapX-${currentCandidate.name}.pdf`,
				});
				exporter.getPdf(true);
			}
		},
	});

	useEffect(() => {
		if (printRequested) {
			setTimeout(() => {
				handleDownload();
				setPrintRequested(false);
			}, 150);
		}
	}, [handleDownload, printRequested]);

	const experiences = useMemo(() => {
		if (currentCandidate) {
			return currentCandidate?.positions || [];
		} else {
			return [];
		}
	}, [currentCandidate]);

	const hasEducationInfo: boolean = useMemo(() => {
		return Boolean(
			currentCandidate?.education?.some((edu: TCandidateEducation) =>
				Object.keys(edu).some(
					(key: string) => key !== "id" && edu[key as keyof typeof edu] !== null,
				),
			),
		);
	}, [currentCandidate?.education]);

	function handleRedirect() {
		if (originSrc) {
			// Remove empty path separators
			const parts = originSrc.split("/").filter(Boolean);

			let historyPath = "/";
			if (parts.length === 1) {
				historyPath += parts[0];
			} else if (parts.length === 2) {
				if (parts.includes("project")) {
					historyPath += parts.slice(0, 2).join("/");
				} else {
					historyPath += parts.slice(0, 1).join("/");
				}
			} else if (parts.length > 2) {
				historyPath += parts.slice(0, 2).join("/");
			}

			history.push(historyPath);
		} else {
			history.push("/candidates-filter-search");
		}
	}

	return (
		<MainWrapper>
			<div className={css.container}>
				{loading ? (
					<div className={css.loaderContainer}>
						<Loader
							displayAtCenterOfPage={false}
							type={"Rings"}
							height={80}
							width={80}
							color={colors.loaderDotColor}
						/>
					</div>
				) : (
					currentCandidate?.id === parseInt(params.id) && (
						<div
							ref={printRef}
							id="potentialContainer"
							className={classNames({[css.printContainer]: printRequested})}
						>
							<div className={css.header}>
								<LogoIcon />
								<br />
							</div>

							{hasAccessToExtendedPages && (
								<PageTitle
									title={"All People"}
									content={currentCandidate.full_name}
									onClick={handleRedirect}
									print
									onPrintClick={onPrintClick}
									printRequested={printRequested}
								/>
							)}

							<CandidateCard
								candidate={currentCandidate}
								searchType={"Profile"}
								isRecommended={!!recommended}
								disabledTags
								displayExperience={false}
								detailed
								printRequested={printRequested}
							/>

							<ExperienceCard
								key={currentCandidate.id}
								positions={experiences}
								printRequested={printRequested}
							/>

							{hasEducationInfo && (
								<EducationCard educationHistory={currentCandidate?.education} />
							)}

							{/*<SimilarPeopleCard candidate={currentCandidate} />*/}
						</div>
					)
				)}
			</div>
		</MainWrapper>
	);
};

export default CandidateDetails;
