import {removeDuplicatesFromArray} from "helpers/filterHandlers";
import {createSelector} from "reselect";
import {allAPKeywordLabelSelector} from "store/mapx/additional-profiles/apKeywordFilterSelectors";
import {flattenIndustriesSelector} from "../search/searchSelectors";
import {targetListCompaniesSelector} from "../target-list/targetListSelectors";
import {flattenCountriesSelector} from "../filter/filterSelectors";

// PROFILE REQUEST CONFIGURATOR SELECTORS
export const prJobTitleSuggestionLoadingSelector = (state) =>
	state.additional_profiles.job_titles_suggestion_loading;
export const suggestedJobTitlesSelector = (state) =>
	state.additional_profiles.pr_filters.job_title_suggestions;
export const prFiltersSelector = (state) => state.additional_profiles.pr_filters;
export const prCompaniesSelector = (state) => state.additional_profiles.pr_filters.company_ids;
export const prCandidateSelector = (state) => state.additional_profiles.pr_filters.candidate_id;
export const prLocationSelector = (state) => state.additional_profiles.pr_filters.country_ids;
export const prSearchWidthSelector = (state) => state.additional_profiles.pr_filters.search_width;
export const prSelectedJobTitlesSelector = (state) =>
	state.additional_profiles.pr_filters.selected_job_titles;
// END

export const searchRequestDetailsLoadingSelector = (state) =>
	state.additional_profiles.search_request_details_loading;
export const activeProjectResultsTabContentSelector = (state) =>
	state.additional_profiles.active_project_results_tab_content;

export const additionalProfileFiltersSelector = (state) => state.additional_profiles.ap_filters;

export const additionalProfileResultsSelector = (state) => state.additional_profiles.ap_results;

export const rejectedAdditionalProfileResultsSelector = (state) =>
	state.additional_profiles.ap_rejected_profiles_result;

export const apCandidateCurrentCountriesSelector = (state) =>
	state.additional_profiles.ap_filters.current_countries_or;

export const apCandidatePreviousCountriesSelector = (state) =>
	state.additional_profiles.ap_filters.previous_countries_or;

export const apCandidateAnyCountriesSelector = (state) =>
	state.additional_profiles.ap_filters.countries_or;

export const apCandidateCurrentCountryRegionsSelector = (state) =>
	state.additional_profiles.ap_filters.current_regions_or;

export const apCandidatePreviousCountryRegionsSelector = (state) =>
	state.additional_profiles.ap_filters.previous_regions_or;

export const apCandidateAnyCountryRegionsSelector = (state) =>
	state.additional_profiles.ap_filters.regions_or;

export const apCandidateLocationFilterConfigSelector = (state) =>
	state.additional_profiles.ap_candidate_location_filter_config;

export const apCandidateAnyIndustriesSelector = (state) =>
	state.additional_profiles.ap_filters.industries;
export const apCandidateCurrentIndustriesSelector = (state) =>
	state.additional_profiles.ap_filters.current_industries;
export const apCandidatePreviousIndustriesSelector = (state) =>
	state.additional_profiles.ap_filters.previous_industries;

export const apCandidateGenderSelector = (state) =>
	state.additional_profiles.ap_filters.gender_diversity_importance;
export const apCandidateEthnicitySelector = (state) =>
	state.additional_profiles.ap_filters.ethnic_diversity_importance;
export const apCandidateOrderingSelector = (state) => state.additional_profiles.ap_filters.ordering;
export const apCandidateCurrentCompaniesSelector = (state) =>
	state.additional_profiles.ap_filters.current_companies;
export const apCandidatePreviousCompaniesSelector = (state) =>
	state.additional_profiles.ap_filters.previous_companies;
export const apCandidateAnyCompaniesSelector = (state) =>
	state.additional_profiles.ap_filters.companies;

export const candidateAnyTLCompaniesSelector = (state) =>
	state.additional_profiles.ap_filters.tl_companies;

export const candidateCurrentTLCompaniesSelector = (state) =>
	state.additional_profiles.ap_filters.current_tl_companies;

export const candidatePreviousTLCompaniesSelector = (state) =>
	state.additional_profiles.ap_filters.previous_tl_companies;

export const apTargetListToggleSelector = (state) =>
	state.additional_profiles.ap_filters_position.target_list_toggle;

export const apTargetListPositionSelector = (state) =>
	state.additional_profiles.ap_filters_position.target_list;

export const apCandidateSenioritySelector = (state) =>
	state.additional_profiles.ap_filters.current_seniority;

export const apCandidateStatusesSelector = (state) =>
	state.additional_profiles.ap_filters.progress_status_ids;

export const apCandidateCompanyPositionSelector = (state) =>
	state.additional_profiles.ap_filters_position.companies;

export const displayRefreshSearchSelector = (state) =>
	state.additional_profiles.display_refresh_ap_result;

export const shouldDisplayAPResultsDirectlySelector = (state) =>
	state.additional_profiles.skip_intro_and_display_results;

export const currentProfileRequestStatusSelector = (state) => state.additional_profiles.status;

export const AdditionalProfilesPaginationInfoSelector = (state) =>
	state.additional_profiles.ap_results_pagination_info;

export const RejectedAdditionalProfilesPaginationInfoSelector = (state) =>
	state.additional_profiles.ap_rejected_profiles_pagination_info;

const getPosition = (_, position) => position;

export const apCandidateAllIndustriesCountSelector = createSelector(
	[
		apCandidateCurrentIndustriesSelector,
		apCandidatePreviousIndustriesSelector,
		apCandidateAnyIndustriesSelector,
	],
	(current, previous, any) => current.length + previous.length + any.length,
);

export const apCandidateIndustriesByPositionSelector = createSelector(
	[
		apCandidateCurrentIndustriesSelector,
		apCandidatePreviousIndustriesSelector,
		apCandidateAnyIndustriesSelector,
		getPosition,
	],
	(currentIndustries, previousIndustries, anyIndustries, position) => {
		switch (position) {
			case "current":
				return currentIndustries || [];
			case "previous":
				return previousIndustries || [];
			case "any":
				return anyIndustries || [];
			default:
				return [];
		}
	},
);

export const apCandidateCompanyByPositionSelector = createSelector(
	[
		apCandidateCurrentCompaniesSelector,
		apCandidatePreviousCompaniesSelector,
		apCandidateAnyCompaniesSelector,
		apCandidateCompanyPositionSelector,
	],
	(currentCompanies, previousCompanies, anyCompanies, position) => {
		switch (position) {
			case "current":
				return currentCompanies;
			case "previous":
				return previousCompanies;
			case "any":
				return anyCompanies;
			default:
				return [];
		}
	},
);

export const apTLCompaniesFilterKeyByPositionSelector = createSelector(
	(_, position) => position,
	(position) => {
		switch (position) {
			case "current":
				return "current_tl_companies";
			case "previous":
				return "previous_tl_companies";
			case "any":
			default:
				return "tl_companies";
		}
	},
);

export const apTLCompaniesByTargetListPositionSelector = createSelector(
	[
		candidateCurrentTLCompaniesSelector,
		candidatePreviousTLCompaniesSelector,
		candidateAnyTLCompaniesSelector,
		apTargetListPositionSelector,
	],
	(current, previous, any, position) => {
		switch (position) {
			case "current":
				return current;
			case "previous":
				return previous;
			case "any":
				return any;
			default:
				return [];
		}
	},
);

export const apCandidateAnyIndustriesOptionsSelector = createSelector(
	[apCandidateAnyIndustriesSelector, flattenIndustriesSelector],
	(industries, options) => {
		const any = [];

		const iSet = new Set(industries);

		options.forEach((o) => {
			if (iSet.has(o.id)) {
				any.push({
					id: o.id,
					name: o.name,
				});
			}
		});

		return any;
	},
);

export const apCandidateCurrentIndustriesOptionsSelector = createSelector(
	[apCandidateCurrentIndustriesSelector, flattenIndustriesSelector],
	(industries, options) => {
		const current = [];

		const iSet = new Set(industries);

		options.forEach((o) => {
			if (iSet.has(o.id)) {
				current.push({
					id: o.id,
					name: o.name,
				});
			}
		});

		return current;
	},
);

export const apCandidatePreviousIndustriesOptionsSelector = createSelector(
	[apCandidatePreviousIndustriesSelector, flattenIndustriesSelector],
	(industries, options) => {
		const previous = [];

		const iSet = new Set(industries);

		options.forEach((o) => {
			if (iSet.has(o.id)) {
				previous.push({
					id: o.id,
					name: o.name,
				});
			}
		});

		return previous;
	},
);

export const apCandidateCompaniesOptionsSelector = createSelector(
	[apCandidateCompanyByPositionSelector, targetListCompaniesSelector],
	(companies, options) => {
		const previous = [];

		const cSet = new Set(companies);

		options.forEach((o) => {
			if (cSet.has(o.id)) {
				previous.push({
					id: o.id,
					name: o.name,
				});
			}
		});

		return previous;
	},
);

export const apCandidateCompaniesFilterKeyByPositionSelector = createSelector(
	getPosition,
	(position) => {
		switch (position) {
			case "current":
				return "current_companies";
			case "previous":
				return "previous_companies";
			case "any":
			default:
				return "companies";
		}
	},
);

export const apCandidateIndustriesFilterKeyByPositionSelector = createSelector(
	getPosition,
	(position) => {
		switch (position) {
			case "current":
				return "current_industries";
			case "previous":
				return "previous_industries";
			case "any":
			default:
				return "industries";
		}
	},
);

export const apCandidateSelectedKeywordLabelsSelector = createSelector(
	[allAPKeywordLabelSelector],
	(selectedKeywords) => {
		return removeDuplicatesFromArray([...selectedKeywords]);
	},
);

// QUICK SEARCH SELECTORS

export const activeSearchRequestSelector = (state) =>
	state.additional_profiles.active_search_request;

export const allSearchRequestsSelector = (state) => state.additional_profiles.all_search_requests;

export const allProfileRequestsSelector = createSelector(
	allSearchRequestsSelector,
	(searchRequests) => {
		return searchRequests.filter(
			(searchRequest) => searchRequest.type === "Additional Profiles",
		);
	},
);

export const quickSearchProgressWatchSelector = (state) =>
	state.additional_profiles.quick_searches_progress_watch;

export const allSearchProgressWatchSelector = (state) =>
	state.additional_profiles.search_requests_progress_watch;

export const projectIdSelector = (_, projectId) => projectId;

export const getSearchRequestInProgressForCurrentProject = createSelector(
	allSearchProgressWatchSelector,
	projectIdSelector,
	(allSearchProgressWatchForAllProject, projectId) => {
		if (projectId) {
			const data =
				typeof allSearchProgressWatchForAllProject === "object"
					? Object.values(allSearchProgressWatchForAllProject).find(
							(item) => item.project_id === projectId,
					  )
					: null;

			if (data) {
				return data.status === "Created" || data.status === "In Progress";
			}
		}

		return false;
	},
);

export const activeSearchRequestTypeSelector = createSelector(
	allSearchRequestsSelector,
	(allSearchRequest) => {
		if (allSearchRequest?.length > 0) {
			return allSearchRequest[0].type;
		}
	},
);

export const isApTargetListExpandedSelector = createSelector(
	[
		targetListCompaniesSelector,
		candidateCurrentTLCompaniesSelector,
		candidatePreviousTLCompaniesSelector,
		candidateAnyTLCompaniesSelector,
	],
	(targetListCompanies, current, previous, any) => {
		const companies = [...current, ...previous, ...any];

		return targetListCompanies.length > 0 && companies.length > 0;
	},
);

const getAPLocationPosition = (position) => position;

export const apCandidateLocationPositionSelector = (state) =>
	state.additional_profiles.ap_candidate_location_filter_config.active_position;

export const apLocationFilterKeyByPosition = createSelector(getAPLocationPosition, (position) => {
	switch (position) {
		case "current":
			return "current_countries_or";
		case "previous":
			return "previous_countries_or";
		case "any":
		default:
			return "countries_or";
	}
});

export const apRegionFilterKeyByPosition = createSelector(getAPLocationPosition, (position) => {
	switch (position) {
		case "current":
			return "current_regions_or";
		case "previous":
			return "previous_regions_or";
		case "any":
		default:
			return "regions_or";
	}
});

export const allAPCountriesCountSelector = createSelector(
	[
		apCandidateCurrentCountriesSelector,
		apCandidatePreviousCountriesSelector,
		apCandidateAnyCountriesSelector,
		apCandidateCurrentCountryRegionsSelector,
		apCandidatePreviousCountryRegionsSelector,
		apCandidateAnyCountryRegionsSelector,
	],
	(
		currentCountries,
		previousCountries,
		anyCountries,
		currentRegions,
		previousRegions,
		anyRegions,
	) =>
		currentCountries?.length +
		previousCountries?.length +
		anyCountries?.length +
		currentRegions?.length +
		previousRegions?.length +
		anyRegions?.length,
);

export const apCandidateRegionIdsByPositionSelector = (position) =>
	createSelector(
		[
			apCandidateCurrentCountryRegionsSelector,
			apCandidatePreviousCountryRegionsSelector,
			apCandidateAnyCountryRegionsSelector,
		],
		(currentRegions, previousRegions, anyRegions) => {
			switch (position) {
				case "current":
					return currentRegions || [];
				case "previous":
					return previousRegions || [];
				case "any":
					return anyRegions || [];
				default:
					return [];
			}
		},
	);

export const apCandidateCountryIdsByPositionSelector = (position) =>
	createSelector(
		[
			apCandidateCurrentCountriesSelector,
			apCandidatePreviousCountriesSelector,
			apCandidateAnyCountriesSelector,
		],
		(currentCountries, previousCountries, anyCountries) => {
			switch (position) {
				case "current":
					return currentCountries || [];
				case "previous":
					return previousCountries || [];
				case "any":
					return anyCountries || [];
				default:
					return [];
			}
		},
	);
export const getSelectedCountriesAndRegions = (countryIDs, regionIDs, options) => {
	const selected = [];

	const countrySet = new Set(countryIDs);
	const regionSet = new Set(regionIDs);

	options.forEach((o) => {
		const isCountryMatch = countrySet.has(o.id);

		if (o.regions && o.regions.length > 0) {
			if (isCountryMatch) {
				selected.push({
					id: o.id,
					name: o.name,
				});
			} else {
				const matchingRegions = o.regions.filter((region) => regionSet.has(region.id));

				matchingRegions.forEach((region) => {
					selected.push({
						id: o.id,
						name: `${o.name}: ${region.name}`,
						region_id: region.id,
					});
				});
			}
		} else if (isCountryMatch) {
			selected.push({
				id: o.id,
				name: o.name,
			});
		}
	});

	return selected;
};

export const selectedAPAnyCountriesSelector = createSelector(
	[
		apCandidateAnyCountriesSelector,
		apCandidateAnyCountryRegionsSelector,
		flattenCountriesSelector,
	],
	(countries, regions, options) => getSelectedCountriesAndRegions(countries, regions, options),
);

export const selectedAPPreviousCountriesSelector = createSelector(
	[
		apCandidatePreviousCountriesSelector,
		apCandidatePreviousCountryRegionsSelector,
		flattenCountriesSelector,
	],
	(countries, regions, options) => getSelectedCountriesAndRegions(countries, regions, options),
);

export const selectedAPCurrentCountriesSelector = createSelector(
	[
		apCandidateCurrentCountriesSelector,
		apCandidateCurrentCountryRegionsSelector,
		flattenCountriesSelector,
	],
	(countries, regions, options) => getSelectedCountriesAndRegions(countries, regions, options),
);
