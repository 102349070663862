import React, {useCallback, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {ToastContent} from "components";
import {
	activeImportInfoForCompanySelector,
	lastCompletedCandidateImportByLinkedInForProjectSelector,
} from "store/mapx/linkedin-bulk-import/linkedinBulkImportSelectors";
import {toast} from "react-toastify";
import {
	removeBulkRefreshDataForSource,
	setImportLinkedInProfileInfoForCompany,
	setLastCompleteImportLinkedInProfileInfoForProject,
	setShouldOpenBulkImportReport,
} from "store/mapx/linkedin-bulk-import/linkedInBulkImportActions";
import {getIndividualBulkImportStatuses} from "store/mapx/linkedin-bulk-import/linkedInProfilesImportAsyncActions";
import {
	getProjectNameByProjectIDSelector,
	projectSelector,
} from "store/mapx/project-list/projectListSelectors";
import {setProjectTabIndex} from "store/mapx/project-list/projectListActions";
import {
	TImportLinkedinProfileInfoForProject,
	TLinkedInUrlImportItem,
} from "mapx-components/AddPeopleByLinkedIn/types";
import {useAppDispatch, useAppSelector} from "hooks/index";
import {clearAPWorkflowState} from "store/mapx/additional-profiles/additionalProfilesActions";
import {updateProjectSavedPeopleCandidatesInTheList} from "store/mapx/target-list/targetListCandidatesAsyncActions";
import {getTargetListCompanies} from "store/mapx/target-list/targetListAsyncActions";
import {TRootState} from "types";
import {updateProjectSuccessChecklistItem} from "store/mapx/project/projectSuccessChecklistAsyncActions";
import {TLinkedinBulkImportIndividualStatusResponse} from "../types";
import ProjectTabs from "mapx-pages/Project/ProjectTabs";
import {companySelector} from "store/mapx/company/companySelectors";
import {profilesImportsByLinkedInSelector} from "store/mapx/background-tasks/backgroundTaskSelectors";
import {setImportLinkedInProfileInfoForCurrentImport} from "store/mapx/background-tasks/backgroundTaskActions";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const projectIntervalId = {} as any;

const useBulkImportLinkedInProfileProgressWatcher = () => {
	const history = useHistory();

	const state = useAppSelector((state) => state);

	const currentProject = useAppSelector(projectSelector);

	const currentCompany = useAppSelector(companySelector);

	const activeImportInfoForCompany = useAppSelector(activeImportInfoForCompanySelector);

	const lastCompletedCandidateImportByLinkedInForProject = useAppSelector(
		lastCompletedCandidateImportByLinkedInForProjectSelector,
	);

	const importLinkedInProfileForProjects = useAppSelector(profilesImportsByLinkedInSelector);

	const dispatch = useAppDispatch();

	const handleSeeProjectClick = useCallback(
		async (projectID: number) => {
			if (!projectID) {
				history.push(`/company/${activeImportInfoForCompany?.id}`);
			} else {
				if (
					currentProject === null ||
					currentProject?.id.toString() !== projectID.toString()
				) {
					dispatch(clearAPWorkflowState());
					dispatch(updateProjectSavedPeopleCandidatesInTheList());
					dispatch(getTargetListCompanies());
				}

				dispatch(setProjectTabIndex(ProjectTabs.savedPeopleTabIndex));
				history.push(`/project/${projectID}`);
			}
			dispatch(setShouldOpenBulkImportReport({id: projectID ?? 0, value: true}));

			toast.dismiss(`import_success_toaster${projectID ?? 0}`);
			toast.dismiss(`import_error_toaster${projectID ?? 0}`);
		},
		[dispatch, currentProject, history, activeImportInfoForCompany?.id],
	);

	const isImportInProgress = (status: string) => {
		return status === "Created" || status === "In Progress" || status === "Scraped";
	};

	const updateImportInfo = useCallback(
		async (response: TRootState, data: TImportLinkedinProfileInfoForProject) => {
			dispatch(
				setImportLinkedInProfileInfoForCurrentImport({
					...response.data,
					purpose: data.purpose,
				}),
			);

			const hasAddedPeople = response.data?.linkedin_candidate_urls?.some(
				(item: TLinkedInUrlImportItem) =>
					["success", "imported", "completed", "already exists"].includes(
						item.status?.toLowerCase(),
					),
			);

			if (hasAddedPeople && response.data.project_id) {
				dispatch(
					updateProjectSuccessChecklistItem({
						attribute: "has_saved_people",
						value: true,
					}),
				);
			}
		},
		[dispatch],
	);

	const handleSuccessToast = useCallback(
		async (projectID: number, projectName: Nullable<string>) => {
			toast.success(ToastContent, {
				toastId: `import_success_toaster${projectID}`,
				autoClose: false,
				closeOnClick: false,
				data: {
					title: `Import is completed for ${projectName}`,
					description: () => (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								gap: "2px",
								fontSize: "12px",
							}}
						>
							<p style={{width: "190px"}}>
								An import has successfully completed without errors, click on report
								to see imported profiles.
							</p>
							<button
								style={{
									backgroundColor: "#309161",
									color: "#ECF9F2",
									borderRadius: "5px",
									marginTop: "-20px",
									width: "60px",
									cursor: "pointer",
								}}
								onClick={() => handleSeeProjectClick(projectID)}
							>
								Report
							</button>
						</div>
					),
				},
				delay: 100,
			});
		},
		[handleSeeProjectClick],
	);

	const handleErrorToast = useCallback(
		async (projectID: number, projectName: Nullable<string>) => {
			toast.warning(ToastContent, {
				toastId: `import_error_toaster${projectID}`,
				autoClose: false,
				closeOnClick: false,
				data: {
					title: `Import is completed with errors for ${projectName}`,
					description: () => (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								gap: "2px",
								fontSize: "12px",
							}}
						>
							<p style={{width: "190px"}}>
								An import has completed with errors, click on report to see imported
								profiles.
							</p>
							<button
								style={{
									backgroundColor: "#CC8800",
									color: "#ECF9F2",
									borderRadius: "5px",
									marginTop: "-20px",
									width: "60px",
									cursor: "pointer",
								}}
								onClick={() => handleSeeProjectClick(projectID)}
							>
								Report
							</button>
						</div>
					),
				},
				delay: 100,
			});
		},
		[handleSeeProjectClick],
	);

	const updateImportHistoryOnState = useCallback(
		(response: TLinkedinBulkImportIndividualStatusResponse, urls: TLinkedInUrlImportItem[]) => {
			const previouslyImportedData =
				lastCompletedCandidateImportByLinkedInForProject[response.data.project_id ?? 0]
					?.importInfo?.linkedin_candidate_urls || [];

			const mergedData = {
				id: response.data.project_id ?? 0,
				status: "Completed",
				importInfo: {
					linkedin_candidate_urls: [...urls],
				},
			};

			previouslyImportedData.forEach((item: TLinkedInUrlImportItem) => {
				mergedData.importInfo.linkedin_candidate_urls.push(item);
			});

			const payload = {id: response.data.project_id ?? 0, data: mergedData};

			dispatch(setLastCompleteImportLinkedInProfileInfoForProject(payload));
		},
		[lastCompletedCandidateImportByLinkedInForProject, dispatch],
	);

	const getImportSource = useCallback(
		(projectID: Nullable<number>): string => {
			if (projectID) {
				return getProjectNameByProjectIDSelector(state, projectID);
			} else {
				const currentImportCompanyInfo = activeImportInfoForCompanySelector(state);

				return currentImportCompanyInfo?.name ?? "Unknown";
			}
		},
		[state],
	);

	const handlePostCompletionActions = useCallback(
		async (
			response: TLinkedinBulkImportIndividualStatusResponse,
			errorImportingCount: number,
			data: TImportLinkedinProfileInfoForProject,
		) => {
			const projectId = response.data.project_id ?? 0;

			if (response.data.project_id === currentProject?.id) {
				dispatch(updateProjectSavedPeopleCandidatesInTheList());
			}

			toast.dismiss(`import_started_toaster${projectId}`);

			const importSource = getImportSource(response.data.project_id);

			if (data.purpose === "import") {
				const toastHandler =
					errorImportingCount === 0 ? handleSuccessToast : handleErrorToast;
				const toastMessage = `${
					response.data.project_id ? "Project: " : "Company: "
				}${importSource}`;
				await toastHandler(projectId, toastMessage);
			}
		},
		[currentProject?.id, handleSuccessToast, handleErrorToast, dispatch, getImportSource],
	);

	const handleCompletedImport = useCallback(
		async (
			response: TLinkedinBulkImportIndividualStatusResponse,
			data: TImportLinkedinProfileInfoForProject,
		) => {
			const urls = response.data.linkedin_candidate_urls;
			updateImportHistoryOnState(response, urls);

			if (urls.length > 0) {
				const errorImportingCount: number = urls.filter((item: TLinkedInUrlImportItem) =>
					["bad url", "not found"].includes(item.status?.toLowerCase()),
				).length;

				const stillInProgressImport: number = urls.filter((item: TLinkedInUrlImportItem) =>
					["created", "in progress", "scraped"].includes(item.status?.toLowerCase()),
				).length;

				if (response.data.progress === 100 && stillInProgressImport === 0) {
					if (
						!response.data.project_id &&
						activeImportInfoForCompany?.id === currentCompany?.id
					) {
						const payload = {
							data: response.data.linkedin_candidate_urls,
							id: activeImportInfoForCompany?.id,
						};
						dispatch(setImportLinkedInProfileInfoForCompany(payload));
					}

					if (data.purpose === "refresh") {
						const removeFrmStatePayload = {
							project_id: response.data.project_id,
							urls: response.data.linkedin_candidate_urls,
						};
						dispatch(removeBulkRefreshDataForSource(removeFrmStatePayload));
					}
				} else {
					const updatedResponse = {
						...response,
						data: {
							...response.data,
							progress: 100,
							linkedin_candidate_urls: response.data.linkedin_candidate_urls.map(
								(urlObj) => ({
									...urlObj,
									status: "Scraping Error",
								}),
							),
						},
					};

					await updateImportInfo(updatedResponse, data);
				}

				await handlePostCompletionActions(response, errorImportingCount, data);
			}
			clearInterval(projectIntervalId[response.data?.id]);
		},
		[
			updateImportHistoryOnState,
			activeImportInfoForCompany?.id,
			currentCompany?.id,
			dispatch,
			handlePostCompletionActions,
			updateImportInfo,
		],
	);

	useEffect(() => {
		const intervalIds: Record<string, Nullable<TRootState>> = {};

		const inProgressLinkedinBulkImports: TImportLinkedinProfileInfoForProject[] =
			importLinkedInProfileForProjects;

		if (inProgressLinkedinBulkImports && inProgressLinkedinBulkImports.length > 0) {
			inProgressLinkedinBulkImports.forEach((data) => {
				if (data && isImportInProgress(data.status) && data.id) {
					const importInfo = data;

					if (importInfo) {
						if (intervalIds[importInfo.id]) {
							clearInterval(intervalIds[importInfo.id]);
							intervalIds[importInfo.id] = undefined;
						}

						if (isImportInProgress(importInfo.status)) {
							intervalIds[importInfo.id] = setInterval(async () => {
								try {
									const response = await dispatch(
										getIndividualBulkImportStatuses(importInfo.id),
									);

									await updateImportInfo(response, data);

									if (response.status === "Completed") {
										if (intervalIds[importInfo.id]) {
											clearInterval(intervalIds[importInfo.id]);
											intervalIds[importInfo.id] = undefined;
										}
										await handleCompletedImport(response, data);
									}
								} catch (error) {
									console.error("Error fetching bulk import status:", error);
								}
							}, 8 * 1000);
						}
					}
				}
			});
		}

		return () => {
			for (const key of Object.keys(intervalIds)) {
				if (intervalIds[key]) {
					clearInterval(intervalIds[key]);
				}
			}
		};
	}, [importLinkedInProfileForProjects, dispatch, handleCompletedImport, updateImportInfo]);
};

export default useBulkImportLinkedInProfileProgressWatcher;
