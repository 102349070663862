import {roundToSignificantFigures} from "helpers/number";
import {createSelector} from "reselect";
import {TRootState} from "types";
import {STCompany} from "api/companyApi/types";

export const companiesInProgressSelector = (state: TRootState) => state.company.companiesInProgress;
export const filteredCompaniesInProgressSelector = (state: TRootState) =>
	state.company.filteredCompaniesInProgress;
export const companySelector = (state: TRootState) => state.company.currentCompany;

export const similarCompaniesInProgressSelector = (state: TRootState) =>
	state.company.similarCompaniesInProgress;

export const orgChartKeywordsFiltersDropdownDataSelector = (state: TRootState) =>
	state.company.orgchart_keywords_filters_dropdown_data;

export const companyEthnicDiversityDataSelector = (state: TRootState) =>
	state.company.companyEthnicDiversityData;

export const companyEthnicDiversityDataInProgressSelector = (state: TRootState) =>
	state.company.companyEthnicDiversityDataInProgress;

export const companyGenderDiversityDataSelector = (state: TRootState) =>
	state.company.companyGenderDiversityData;

export const companyGenderDiversityDataInProgressSelector = (state: TRootState) =>
	state.company.companyGenderDiversityDataInProgress;

export const companyLicenseTypesSelector = (state: TRootState) => state.company.licenseTypes;

export const companyOrgChartFilteringSelector = (state: TRootState) =>
	state.company.companiesOrgChartFilteringInProgress;

export const companiesOrgChartFiltersSelector = (state: TRootState) =>
	state.company.companiesOrgChartFilters;

export const companyOrgChartSelector = (state: TRootState) => state.company.organizationalChart;

export const revenueFilterInProgressSelector = (state: TRootState) =>
	state.company.revenueFilterInProgress;

export const companyOrgChartTrendsSelector = (state: TRootState) => state.company.trendsStats;

export const companyOrgChartTrendsInProgressSelector = (state: TRootState) =>
	state.company.trendsStatsInProgress;

export const tooltipCompaniesSelector = (state: TRootState) => state.company.tooltipCompanies;

export const tooltipCompaniesProgressSelector = (state: TRootState) =>
	state.company.tooltipCompaniesProgress;

export const sizeFilterInProgressSelector = (state: TRootState) =>
	state.company.sizeFilterInProgress;

export const companiesSelector = (state: TRootState) => state.company.companies || [];

export const companiesPaginationInfoSelector = (state: TRootState) => state.company.paginationInfo;

export const companySizeDistributeDataSelector = (state: TRootState) =>
	state.company.companySizeDistributeData;

export const currentCompanySelector = (state: TRootState) => state.company.currentCompany;

export const getLicenseTypeInProgressSelector = (state: TRootState) =>
	state.company.getLicenseTypeInProgress;

export const companyRevenueDistributeDataSelector = (state: TRootState) =>
	state.company.companyRevenueDistributeData;

export const companyFrequentlyHiredFromDataSelector = (state: TRootState) =>
	state.company.companyFrequentlyHiredFromData;

// Map This function selectors

export const candidateMappingForAllCompaniesSelector = (state: TRootState) =>
	state.company.candidateMappingForCompanies;

export const candidateMappingInProgressForAllCompaniesSelector = (state: TRootState) =>
	state.company.candidateMappingInProgressForCompanies;

export const companyLocationReportSelector = (state: TRootState) =>
	state.company.companyLocationReport;

export const companyLocationReportInProgressSelector = (state: TRootState) =>
	state.company.fetchingCompanyLocationReport;

export const companyIdSelector = (_: TRootState, companyId: number) => companyId;

export const minSizeSelector = createSelector([companiesSelector], (companies) => {
	const minSize = companies.reduce((min: number, company: STCompany) => {
		if (company.size !== null && company.size < min) {
			min = company.size;
		}

		return min;
	}, Number.MAX_VALUE);

	return roundToSignificantFigures(
		minSize === Number.MAX_VALUE || !minSize ? 1 : minSize,
	).toString();
});

export const maxSizeSelector = createSelector([companiesSelector], (companies) => {
	const maxSize = companies.reduce((max: number, company: STCompany) => {
		if (company.size !== null && company.size > max) {
			max = company.size;
		}

		return max;
	}, Number.MIN_VALUE);

	return roundToSignificantFigures(
		maxSize === Number.MIN_VALUE || !maxSize ? 1 : maxSize,
	).toString();
});

export const maxRevenueSelector = createSelector([companiesSelector], (companies) => {
	const maxRevenue = companies.reduce((max: number, company: STCompany) => {
		if (company.revenue !== null && company.revenue > max) {
			max = company.revenue;
		}

		return max;
	}, Number.MIN_VALUE);

	return roundToSignificantFigures(
		maxRevenue === Number.MIN_VALUE || !maxRevenue ? 1 : maxRevenue,
	).toString();
});

export const minRevenueSelector = createSelector([companiesSelector], (companies) => {
	const minRevenue = companies.reduce((min: number, company: STCompany) => {
		if (company.revenue !== null && company.revenue < min) {
			min = company.revenue;
		}

		return min;
	}, Number.MAX_VALUE);

	return roundToSignificantFigures(
		minRevenue === Number.MAX_VALUE || !minRevenue ? 1 : minRevenue,
	).toString();
});

// export const maxRevenueSelector = createSelector(
// 	companyRevenueDistributeDataSelector,
// 	(companyRevenueDistributeData) => companyRevenueDistributeData.max_revenue || "15000",
// );

// export const minRevenueSelector = createSelector(
// 	companyRevenueDistributeDataSelector,
// 	(companyRevenueDistributeData) => companyRevenueDistributeData.min_revenue || "1",
// );

// Map this function Custom Selectors for single company

export const candidateMappingForCurrentCompanySelector = createSelector(
	candidateMappingForAllCompaniesSelector,
	companyIdSelector,
	(candidateMappingForAllCompanies, companyId: Nullable<number>) => {
		return candidateMappingForAllCompanies &&
			companyId &&
			candidateMappingForAllCompanies[companyId] !== undefined
			? candidateMappingForAllCompanies[companyId]
			: null;
	},
);

export const getCandidateMappingInProgressForCurrentCompany = createSelector(
	candidateMappingInProgressForAllCompaniesSelector,
	companyIdSelector,
	(candidateMappingInProgressForAllCompanies, companyId: Nullable<number>) => {
		return candidateMappingInProgressForAllCompanies &&
			companyId &&
			candidateMappingInProgressForAllCompanies[companyId] !== undefined
			? candidateMappingInProgressForAllCompanies[companyId]
			: false;
	},
);
