import {useState} from "react";
import {debounce} from "lodash";
import axios, {AxiosResponse, type CancelTokenSource} from "axios";
import {successResponse} from "helpers/map";
import mapXMarketMapsApi from "api/marketMapApi";

let cancelToken: CancelTokenSource;

const useTargetListSearchService = () => {
	const [loadingTargetList, setLoadingTargetList] = useState<boolean>(false);

	const fetchTargetList = debounce(
		async (inputString: string, callback: (options: Record<string, string>[]) => void) => {
			setLoadingTargetList(true);
			//Check if there are any previous pending requests
			if (typeof cancelToken !== typeof undefined) {
				cancelToken.cancel("Operation canceled due to new request.");
			}

			//Save the cancel token for the current request
			cancelToken = axios.CancelToken.source();

			const config = {cancelToken: cancelToken.token};

			const response: AxiosResponse = await mapXMarketMapsApi.searchMarketMaps(
				{name: inputString, page: 1},
				config,
			);

			if (response === undefined) {
				setLoadingTargetList(true);
			}

			if (successResponse(response, 200)) {
				setLoadingTargetList(false);

				const data: Record<string, string>[] = response.data.results;

				return callback(data);
			} else {
				setLoadingTargetList(false);

				return callback([]);
			}
		},
		800,
	);

	return {
		loadingTargetList,
		fetchTargetList,
	};
};

export default useTargetListSearchService;
