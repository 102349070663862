import {useAppSelector} from "hooks";
import React, {useState} from "react";
import styles from "./marketReports.module.scss";
import {Button, MultiSelectWithAction} from "components";
import {CreatePlusIcon} from "assets/icons";
import homeStyles from "../Home/HomeStyles.module.scss";
import useSearchDebounce from "hooks/useSearchDebounce";
import MarketMapSearch from "./Filters/search";
import MarketReportCard from "mapx-components/Cards/MarketReportCard";
import {MainWrapper} from "mapx-components";
import {marketMapCreatedBySelector} from "store/mapx/market-map/marketMapSelectors";
import {TMultiSelectOptions} from "components/Inputs/MultiSelectWithAction/types";
import {options} from "../CompanySearch/MarketMaps/Header";

import {uniqueId} from "lodash";
import MarketReportSkeletonLoader from "./Loader";
import {TMarketMapItem} from "./types";

const dummyData = [
	{
		id: uniqueId("task_"),
		title: "Chief Technology Officer, United States",
		count1: 11,
		count2: 10000,
	},
	{
		id: uniqueId("task_"),
		title: "Chief Technology Officer, United States",
		count1: 20,
		count2: 20000,
	},
	{
		id: uniqueId("task_"),
		title: "Chief Technology Officer, United States",
		count1: 30,
		count2: 30000,
	},
] as TMarketMapItem[];

const MarketReports = () => {
	// @TODO use actual state here
	const displayEditModal = false;

	// @TODO use actual loader state
	const marketReportsFetchInProgress = false;

	const [searchTerm, setSearchTerm] = useSearchDebounce(800);

	const [currentSearch, setCurrentSearch] = useState("");

	const createdBy = useAppSelector(marketMapCreatedBySelector);

	const [selected, setSelected] = useState<TMultiSelectOptions[]>(
		createdBy.length === 0 ? options : options.filter((item) => createdBy.includes(item.value)),
	);

	const LoaderComponent = <MarketReportSkeletonLoader count={10} />;

	const handleCreateMarketReportClick = () => {
		// @TODO dispatch action
	};

	const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value);
		setCurrentSearch(e.target.value);
	};

	return (
		<MainWrapper>
			<div className={styles.container}>
				<div className={styles.headerSection}>
					<p> Market Reports</p>

					<div className={styles.headerSection}>
						<MarketMapSearch
							onChange={handleSearchInput}
							loading={marketReportsFetchInProgress}
							value={currentSearch}
						/>

						<MultiSelectWithAction
							className={styles.multiSelect}
							isSelected={selected.length > 0}
							options={options}
							placeholder="Created by"
							selected={selected}
							setSelected={setSelected}
						/>

						<Button
							data-testid="addNewMarketReportButtonOnMarketReportsList"
							LeftIcon={CreatePlusIcon}
							onClick={handleCreateMarketReportClick}
							className={homeStyles.content__bottom__info__buttons_create}
							leftIconClasses={homeStyles.content__bottom__info__buttons_create_icon}
						>
							Create a Market Report
						</Button>
					</div>
				</div>

				{marketReportsFetchInProgress && LoaderComponent}

				{!marketReportsFetchInProgress &&
					dummyData.map((item, index: number) => (
						<MarketReportCard key={`${index}${item.id}${item.title}`} {...item} />
					))}

				{/*@TODO pagination here*/}

				{!dummyData?.length && searchTerm !== "" && (
					<div className={styles.noQueryMatch}>
						<h4>No market report was found with your search query</h4>
						<p>Try with something different</p>
					</div>
				)}

				{!dummyData?.length && searchTerm === "" && (
					<div className={styles.noQueryMatch} onClick={handleCreateMarketReportClick}>
						<h4>{"You don't have any market reports yet!"}</h4>
						<p>
							Click on the Create a Market Report button to get started with a Market
							Report
						</p>
					</div>
				)}

				{
					displayEditModal && "" // @TODO place edit modal here
				}
			</div>
		</MainWrapper>
	);
};

export default MarketReports;
