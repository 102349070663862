import {TLocationDataByRegions} from "mapx-pages/Project/Insights/Locations/types";

export const getRegionByCountry = (countryName: string, data: TLocationDataByRegions) => {
	for (const [region, regionData] of Object.entries(data)) {
		for (const country of regionData.countries) {
			if (country.name === countryName) {
				return region;
			}
		}
	}

	return null;
};

export const colors = ["#3D3B54", "#286972", "#6699A0", "#6699A0", "#D5E4D9", "#D4F0EE", "#E7FFFE"];

export const geographyStyle = (marked: boolean, color: string) => {
	return {
		default: {
			fill: marked ? color : "#DBE4E6",
			// stroke: "#9E1030",
			strokeWidth: 0.75,
			outline: "none",
			transition: "all 250ms",
		},
		hover: {
			fill: "rgba(91, 159, 201, 0.7)",
			// stroke: "#9E1030",
			strokeWidth: 0.75,
			outline: "none",
			transition: "all 250ms",
		},
		pressed: {
			// fill: "#DD4132",
			// stroke: "#9E1030",
			// strokeWidth: 0.75,
			outline: "none",
			transition: "all 250ms",
		},
	};
};

export const width = 800;
