import {useEffect} from "react";
import {createBroadcastChannel} from "helpers/BoradcastChannel";

export const useBroadcastState = <T>(channelName: string, onMessage: (data: T) => void) => {
	useEffect(() => {
		const {subscribe} = createBroadcastChannel<T>(channelName);

		const unsubscribe = subscribe((data) => {
			onMessage(data);
		});

		return () => {
			unsubscribe();
		};
	}, [channelName, onMessage]);
};
