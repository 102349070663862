import {useState} from "react";
import {debounce} from "lodash";
import axios, {AxiosResponse, type CancelTokenSource} from "axios";
import {successResponse} from "helpers/map";
import mapxKeywordApi from "api/filterOptionsApi/KeywordApi";

let cancelToken: CancelTokenSource;

const useKeywordSearchService = () => {
	const [loadingKeyword, setLoadingKeyword] = useState<boolean>(false);

	const fetchKeywords = debounce(
		async (inputString: string, callback: (options: Record<string, string>[]) => void) => {
			setLoadingKeyword(true);
			//Check if there are any previous pending requests
			if (typeof cancelToken !== typeof undefined) {
				cancelToken.cancel("Operation canceled due to new request.");
			}

			//Save the cancel token for the current request
			cancelToken = axios.CancelToken.source();

			const config = {cancelToken: cancelToken.token};

			const queryParam = `?search=${inputString}`;

			const response: AxiosResponse = await mapxKeywordApi.searchKeywords(
				queryParam,
				1,
				config,
			);

			if (response === undefined) {
				setLoadingKeyword(true);
			}

			if (successResponse(response, 200)) {
				setLoadingKeyword(false);

				const data: Record<string, string>[] = response.data.results;

				return callback(data);
			} else {
				setLoadingKeyword(false);

				return callback([]);
			}
		},
		300,
	);

	return {
		loadingKeyword,
		fetchKeywords,
	};
};

export default useKeywordSearchService;
