import React, {useMemo} from "react";
import {GeneralButton, MainWrapper} from "mapx-components";
import styles from "mapx-pages/CandidateSearch/styles.module.scss";
import classNames from "classnames";
import {Link, Route, Switch, useLocation} from "react-router-dom";
import {InfoIcon} from "assets/icons";
import QuickViewCandidate from "mapx-pages/Project/QuickViewCandidate";
import CandidateFilterSearch from "mapx-pages/CandidateSearch/FilterSearch";
import CandidateTextSearch from "mapx-pages/CandidateSearch/TextSearch";
import CandidateAIWebSearch from "mapx-pages/CandidateSearch/AIWebSearch";
import CandidateProfileSearch from "mapx-pages/CandidateSearch/ProfileSearch";
import {useSelector} from "react-redux";
import {candidatesPaginationInfoSelector} from "store/mapx/candidate/candidateSelectors";
import pageTitleForCandidateSearch from "mapx-pages/CandidateSearch/pageTitle";
import {AdditionalProfilesPaginationInfoSelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {useAppSelector} from "hooks";
import {hasAccessToExtendedPagesSelector} from "store/mapx/user/userSelectors";
import DownloadSearchRequestLog from "./DownloadSearchRequestLog";
import {formatCandidateNumberToHumanReadableFormat} from "mapx-pages/CandidateSearch/utils";
import TokenRefreshCheck from "components/TokenRefreshCheck";

const TAB_OPTIONS = [
	{
		name: "Filter Search",
		path: "/candidates-filter-search",
		premium: true,
	},
	{
		name: "Text Search",
		path: "/candidates-text-search",
		premium: false,
	},
	{
		name: "Profile Search",
		path: "/candidates-profile-search",
		premium: false,
	},
	{
		name: "AI Web Search",
		path: "/candidates-ai-web-search",
		premium: true,
	},
];

const CandidateSearch = () => {
	const {pathname} = useLocation();

	const filterSearchPagination = useSelector(candidatesPaginationInfoSelector);

	const aiPagination = useSelector(AdditionalProfilesPaginationInfoSelector);

	const hasAccessToPremium = useAppSelector(hasAccessToExtendedPagesSelector);

	// PAGE TITLE UPDATE
	pageTitleForCandidateSearch();

	const candidatesResultCount = useMemo(() => {
		if (pathname === "/candidates-filter-search") {
			if (!filterSearchPagination) return 0;

			return formatCandidateNumberToHumanReadableFormat(filterSearchPagination.count || 0);
		} else {
			if (!aiPagination) return 0;

			return formatCandidateNumberToHumanReadableFormat(aiPagination.count || 0);
		}
	}, [aiPagination, filterSearchPagination, pathname]);

	const howToUseButtonUrl = useMemo(() => {
		return (
			process.env?.REACT_APP_DWS_WEB_TUTORIAL_URL ??
			"https://26144956.hs-sites-eu1.com/en/knowledge-base"
		);
	}, []);

	const tokenRefreshCheck = TokenRefreshCheck();

	return tokenRefreshCheck ? (
		tokenRefreshCheck
	) : (
		<MainWrapper>
			<QuickViewCandidate />

			<div className={styles.candidateSearchHeaderSection}>
				<div className={styles.tabs}>
					{TAB_OPTIONS.filter((t) => !t.premium || (t.premium && hasAccessToPremium)).map(
						(option) => (
							<Link
								key={option.path}
								className={classNames(styles.tabTitle, {
									[styles.activeTab]: pathname === option.path,
								})}
								to={option.path}
							>
								{option.name}
								{pathname === option.path && candidatesResultCount !== 0 && (
									<span className={styles.count}>{candidatesResultCount}</span>
								)}
							</Link>
						),
					)}
				</div>

				<div className={styles.buttons}>
					{howToUseButtonUrl && (
						<GeneralButton
							title="How to Use"
							icon={<InfoIcon color={"#4E5555"} />}
							onClick={() => window.open(howToUseButtonUrl, "_blank")}
							className={styles.howToUse}
						/>
					)}

					<DownloadSearchRequestLog />

					{/*<GeneralButton*/}
					{/*	title="Enhance"*/}
					{/*	icon={<MagicWandIcon color={"#0C5850"} />}*/}
					{/*	onClick={() => noop()}*/}
					{/*	className={styles.enhanceButton}*/}
					{/*/>*/}
				</div>
			</div>

			<div className={styles.tabContent}>
				<Switch>
					<Route
						path={[
							"/candidates-filter-search",
							"/candidates-filter-search/:candidateId",
						]}
						render={() => (
							<CandidateFilterSearch candidatesResultCount={candidatesResultCount} />
						)}
						exact={true}
					/>

					<Route
						path={["/candidates-text-search", "/candidates-text-search/:candidateId"]}
						render={() => <CandidateTextSearch />}
						exact={true}
					/>

					<Route
						path={[
							"/candidates-profile-search",
							"/candidates-profile-search/:candidateId",
						]}
						render={() => <CandidateProfileSearch />}
						exact={true}
					/>

					<Route
						path={[
							"/candidates-ai-web-search",
							"/candidates-ai-web-search/:candidateId",
						]}
						render={() => <CandidateAIWebSearch />}
						exact={true}
					/>
				</Switch>
			</div>
		</MainWrapper>
	);
};

export default CandidateSearch;
