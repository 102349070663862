import {type FC, useEffect, useMemo} from "react";
import {useAppDispatch, useAppSelector, useCompanyIdParam} from "hooks";
import {getMapFunctionHistoryByCompanyId} from "store/mapx/company/companyAsyncActions";
import {
	companyOrgChartFilteringSelector,
	companyOrgChartSelector,
	companySelector,
} from "store/mapx/company/companySelectors";
import type {SIOrgChartProps} from "api/companyApi/types";

import FiltersRow from "./FiltersRow";
import {ChartSection} from "./ChartSection";
import styles from "./OrganizationalChart.module.scss";
import OrganizationChartSkeleton from "mapx-pages/CompanyDetails/CompanySkeletons/OrganizationChartSkeleton";
import {setShowLastImportReportForCompany} from "store/mapx/linkedin-bulk-import/linkedInBulkImportActions";
import AddPeople from "./AddPeople";

const OrganizationalChart: FC = () => {
	const dispatch = useAppDispatch();

	const companyParamId = useCompanyIdParam();

	const companyOrgChartOnState = useAppSelector(companyOrgChartSelector);

	const currentCompany = useAppSelector(companySelector);

	const companyOrgChartFiltering = useAppSelector(companyOrgChartFilteringSelector);

	const sum = companyOrgChartOnState?.reduce(
		// @TODO Need to refactor redux to ts
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(previousValue: number, currentValue: any) =>
			previousValue + currentValue.candidates.length,
		0,
	);

	useEffect(() => {
		if (companyParamId) {
			dispatch(getMapFunctionHistoryByCompanyId(companyParamId));
			dispatch(setShowLastImportReportForCompany(true));
		}
	}, [companyParamId, dispatch]);

	const orgChart = useMemo(() => companyOrgChartOnState, [companyOrgChartOnState]);

	return (
		<div className={styles.container} id="org-chart">
			<div className={styles.container__header}>
				<div className={styles.container__header__title}>
					<h3>Organizational Chart</h3>
				</div>
			</div>

			<div style={{display: "flex", justifyContent: "space-between"}}>
				<FiltersRow />
				<div style={{marginRight: "25px"}}>
					<AddPeople />
				</div>
			</div>

			<div>
				{companyOrgChartFiltering ? (
					<div style={{marginTop: 10}}>
						<OrganizationChartSkeleton />
					</div>
				) : (
					orgChart?.map((item: SIOrgChartProps, idx: number) => (
						<ChartSection
							candidates={item?.candidates}
							currentCompany={currentCompany}
							key={`${currentCompany?.id}${idx}`}
							seniorityTitle={item?.seniority || "Other"}
						/>
					))
				)}
			</div>

			{orgChart && !sum && !companyOrgChartFiltering && (
				<h2 className={styles.container__candidates__heading}>
					No results. Press{" "}
					<span className={styles.container__candidates__heading__green}>
						AI Company Search
					</span>{" "}
					Above to Map Out This Function.
				</h2>
			)}
		</div>
	);
};

export default OrganizationalChart;
