import React, {useState, useEffect} from "react";
import {Button, ToastContent} from "../../components";
import styles from "./pagination.module.scss";
import classNames from "classnames";
import {toast} from "react-toastify";
import {PaginationProps} from "./types";

const Pagination = ({
	currentPage,
	resultsCount,
	perPage,
	totalPages,
	onPageChange,
	onPerPageChange,
	scrollDelayAfterPageChange = 100,
}: PaginationProps) => {
	const [perPageToBeDisplayed, setPerPageToBeDisplayed] = useState<number>(perPage);

	const perPageOptions = [10, 20, 30, 40, 50, 100];

	const [pageInput, setPageInput] = useState<string>("");
	const [debouncedPageInput, setDebouncedPageInput] = useState<string>("");

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			setDebouncedPageInput(pageInput);
		}, 800);

		return () => clearTimeout(delayDebounceFn);
	}, [pageInput]);

	useEffect(() => {
		if (debouncedPageInput) {
			const inputPage = Number(debouncedPageInput);

			if (!isNaN(inputPage) && inputPage >= 1 && inputPage <= totalPages) {
				if (inputPage !== currentPage) {
					onPageChange(inputPage);
				}
			} else {
				toast.warn(ToastContent, {
					data: {title: "Please enter a valid page number"},
				});
			}
		}
	}, [debouncedPageInput, totalPages, currentPage, onPageChange]);

	const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPageInput(e.target.value);
	};

	const scrollAfterPageChange = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	const scrollAfterPerPageChange = () => {
		window.scrollTo({
			top: window.scrollY - 100,
			behavior: "smooth",
		});
	};

	const goToPage = (page: number) => {
		if (page >= 1 && page <= totalPages) {
			onPageChange(page);
			setTimeout(scrollAfterPageChange, scrollDelayAfterPageChange);
		}
	};

	const handlePerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setTimeout(scrollAfterPerPageChange, 100);
		const selectedPerPage = Number(event.target.value);
		setPerPageToBeDisplayed(selectedPerPage);

		if (onPerPageChange) {
			onPerPageChange(selectedPerPage);
		}
	};

	const generatePageNumbers = () => {
		const pages = [];
		const startPage = Math.max(1, currentPage - 2);
		const endPage = Math.min(totalPages, currentPage + 2);

		for (let i = startPage; i <= endPage; i++) {
			pages.push(i);
		}

		return pages;
	};

	return (
		<div className={styles.pagination}>
			<div className={styles.paginationControls}>
				<Button
					onClick={() => goToPage(1)}
					disabled={currentPage === 1}
					className={styles.button}
					data-testid="first-page-button"
				>
					1
				</Button>

				<Button
					onClick={() => goToPage(currentPage - 1)}
					disabled={currentPage === 1}
					className={styles.button}
					data-testid="previous-page-button"
				>
					{"<"}
				</Button>
				<span className={styles.dots}>...</span>
				{generatePageNumbers().map((page) => (
					<Button
						key={page}
						onClick={() => goToPage(page)}
						aria-label={`Page ${page}`}
						className={classNames(styles.button, {
							[styles.active]: currentPage === page,
							[styles.mediumWidth]: currentPage > 99 && currentPage < 1000,
							[styles.largeWidth]: currentPage > 999 && currentPage < 9999,
						})}
					>
						{page}
					</Button>
				))}
				<span className={styles.dots}>...</span>
				<Button
					onClick={() => goToPage(currentPage + 1)}
					disabled={currentPage === totalPages}
					className={styles.button}
					data-testid="next-page-button"
				>
					{">"}
				</Button>

				<Button
					onClick={() => goToPage(totalPages)}
					className={classNames(styles.button, {
						[styles.mediumWidth]: totalPages > 99 && totalPages < 1000,
						[styles.largeWidth]: totalPages > 999,
					})}
					disabled={currentPage === totalPages}
					data-testid="last-page-button"
				>
					{totalPages}
				</Button>

				{onPerPageChange && currentPage === 1 && (
					<Button className={styles.perPageWrapper}>
						<select value={perPageToBeDisplayed} onChange={handlePerPageChange}>
							{perPageOptions.map((option) => (
								<option
									key={option}
									value={option}
									defaultValue={perPageToBeDisplayed}
								>
									{option === resultsCount ? "All" : option} / page
								</option>
							))}
						</select>
					</Button>
				)}

				<span
					className={classNames(styles.inputWrapper, {
						[styles.lessMargin]: !onPerPageChange || currentPage !== 1,
					})}
				>
					<span>
						Go To{" "}
						<input
							value={pageInput}
							onChange={handlePageInputChange}
							type="text"
							data-testid="goToPageInput"
						/>
					</span>
				</span>
			</div>
		</div>
	);
};

export default Pagination;
