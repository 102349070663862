import {useCallback, useEffect} from "react";
import {ToastContent} from "components";
import {toast} from "react-toastify";
import {useAppDispatch, useAppSelector} from "hooks/index";
import {activeLastCandidateAssessmentForProjectSelector} from "store/mapx/background-tasks/backgroundTaskSelectors";
import {
	STBackgroundTaskNotificationPayload,
	STBackgroundTaskResponse,
	TBackgroundTaskStatus,
} from "api/backgroundTaskApi/types";
import {
	clearProjectCandidateAssessmentProcess,
	updateCandidateAssessmentStatusOnBT,
} from "store/mapx/background-tasks/backgroundTaskActions";
import {subscribeToChannel, unsubscribeFromChannel} from "web-socket/pusherClient";
import {TProjectCandidateAssessmentNotificationData} from "hooks/useProjectCandidateScoringProgressWatcher/types";
import {userPusherChannelSelector} from "store/mapx/user/userSelectors";
import {updateProjectCandidateByKeyInTheList} from "store/mapx/project/projectActions";
import {
	checkCandidateAssessmentStatus,
	sortProjectCandidatesListByScore,
} from "store/mapx/project/projectAssessmentAsyncActions";
import {useHistory} from "react-router-dom";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const intervalId = {} as any;

const useProjectCandidateScoringProgressWatcher = () => {
	const channelName = useAppSelector(userPusherChannelSelector);

	const activeLastCandidateAssessmentForProject: STBackgroundTaskResponse = useAppSelector(
		activeLastCandidateAssessmentForProjectSelector,
	);

	const dispatch = useAppDispatch();

	const history = useHistory();

	const displayNotification = useCallback(
		async (projectId: number, status: TBackgroundTaskStatus) => {
			if (status === "Completed") {
				dispatch(clearProjectCandidateAssessmentProcess());

				toast.success(ToastContent, {
					autoClose: false,
					closeOnClick: false, // because close option available on the notification
					data: {
						title: `Candidate Assessment for project is ready`,
						description: (closeToast: () => void, linkColor: any) => (
							<>
								You can check the report by{" "}
								<span
									className="toast-link"
									style={{color: linkColor}}
									onClick={() => {
										history.push(`/project/${projectId}`);
										closeToast();
									}}
								>
									clicking here.
								</span>
							</>
						),
					},
				});
			} else if (status === "Error") {
				toast.info(ToastContent, {
					autoClose: false,
					closeOnClick: true,
					data: {
						title: "Something went wrong while assessing candidates!",
					},
				});

				dispatch(clearProjectCandidateAssessmentProcess());
			}
		},
		[dispatch, history],
	);

	const isTaskInProgress = (status: TBackgroundTaskStatus) => {
		return status === "Created" || status === "In Progress";
	};

	useEffect(() => {
		if (
			activeLastCandidateAssessmentForProject &&
			activeLastCandidateAssessmentForProject.type === "Candidate Assessment"
		) {
			if (activeLastCandidateAssessmentForProject.status === "Completed") {
				dispatch(clearProjectCandidateAssessmentProcess());
			} else {
				const inProgress = isTaskInProgress(activeLastCandidateAssessmentForProject.status);

				if (inProgress) {
					intervalId[activeLastCandidateAssessmentForProject.id] = setInterval(
						async () => {
							try {
								const response = await dispatch(
									checkCandidateAssessmentStatus(
										activeLastCandidateAssessmentForProject.id,
									),
								);

								await displayNotification(
									activeLastCandidateAssessmentForProject.project_id,
									response?.status,
								);

								if (response?.status === "Completed") {
									clearInterval(
										intervalId[activeLastCandidateAssessmentForProject.id],
									);
								}
							} catch (error) {
								console.error("Error checking candidate assessment status:", error);
							}
						},
						60 * 1000, // Execute every 60 seconds
					);

					// Clear the interval after 30 minutes from its start if not cleared already
					setTimeout(() => {
						clearInterval(intervalId[activeLastCandidateAssessmentForProject.id]);
					}, 30 * 60 * 1000); // 30 minutes
				}
			}
		}

		return () => {
			for (const key of Object.keys(intervalId)) {
				clearInterval(intervalId[key]);
			}
		};
	}, [activeLastCandidateAssessmentForProject, dispatch, displayNotification, channelName]);

	useEffect(() => {
		if (
			activeLastCandidateAssessmentForProject?.id &&
			activeLastCandidateAssessmentForProject?.type === "Candidate Assessment"
		) {
			subscribeToChannel<TProjectCandidateAssessmentNotificationData>(
				channelName,
				"Candidate Assessed",
				(data) => {
					if (data.background_task_id === activeLastCandidateAssessmentForProject?.id) {
						const payload = {
							candidate_id: data.candidate_id,
							key: "fit_to_brief",
							value: {
								score: data.score,
								assessments: data.assessments,
							},
						};
						dispatch(updateProjectCandidateByKeyInTheList(payload));

						dispatch(
							updateCandidateAssessmentStatusOnBT({
								candidate_id: data.candidate_id,
								is_assessed: true,
							}),
						);
					}
				},
			);

			subscribeToChannel<STBackgroundTaskNotificationPayload>(
				channelName,
				"Background Task Status Change",
				(data) => {
					if (
						activeLastCandidateAssessmentForProject?.id === data.background_task_id &&
						(data.status === "Completed" || data.status === "Error") &&
						data.source === "dataflow.additional_profiles.services.notifications"
					) {
						dispatch(clearProjectCandidateAssessmentProcess());
						dispatch(sortProjectCandidatesListByScore());

						if (intervalId[activeLastCandidateAssessmentForProject.id]) {
							clearInterval(intervalId[activeLastCandidateAssessmentForProject.id]);
						}

						unsubscribeFromChannel(channelName, "Candidate Assessed");
						unsubscribeFromChannel(channelName, "Background Task Status Change");

						displayNotification(
							activeLastCandidateAssessmentForProject.project_id,
							data.status,
						);
					}
				},
			);
		}
	}, [
		activeLastCandidateAssessmentForProject?.id,
		activeLastCandidateAssessmentForProject?.type,
		activeLastCandidateAssessmentForProject?.project_id,
		channelName,
		dispatch,
		displayNotification,
	]);
};

export default useProjectCandidateScoringProgressWatcher;
