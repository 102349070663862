import classNames from "classnames";
import {LazyLoadImageWrapper} from "components";
import {useAppSelector, useOpenLink} from "hooks";
import useUserBehaviorTrackHook from "hooks/mapx/useUserBehaviorTrackHook";
import {useDispatch, useSelector} from "react-redux";
import {getSingleCompanyDone} from "store/mapx/company/companyActions";
import {setTrackedEventsData} from "store/mapx/events/eventTrackingActions";
import {setActiveAccordionOnFilter, setFilterForCompanies} from "store/mapx/filter/filterActions";
import {getIndustries} from "store/mapx/search/searchAsyncActions";
import {industryOptionsSelector} from "store/mapx/search/searchSelectors";

import css from "./infoSection.module.scss";

export default function InfoSection({item, from, isRecommended, position}) {
	const openLink = useOpenLink();
	const dispatch = useDispatch();
	const {itemEventDetails} = useUserBehaviorTrackHook({
		itemType: "company",
		defaultEvent: "details_viewed",
		itemId: item.id,
		source: from,
		isRecommended,
		position,
	});

	const handleItemClick = (event) => {
		event.preventDefault();

		dispatch(setTrackedEventsData(itemEventDetails));

		if (event.button === 0) {
			dispatch(getSingleCompanyDone(item));
			const link = `/company/${item.id}${isRecommended ? "?recommended=true" : ""}`;
			openLink(event, link);
		}
	};

	/**
	 ** SPECIALTY FILTER MATCH & CONTROL
	 */
	const {industries} = useSelector((state) => state.filter.company_filters);
	const industryOptions = useAppSelector(industryOptionsSelector);

	const handleSelectedIndustries = (item) => {
		if (industryOptions.length === 0) {
			dispatch(setActiveAccordionOnFilter("Industry"));

			dispatch(getIndustries());
		}

		const current = industries ? [...industries] : [];
		let updatedIds = [];

		if (current.includes(item.id)) {
			updatedIds = [...current.filter((id) => item.id !== id)];
		} else {
			updatedIds = [...current, item.id];
		}

		dispatch(setFilterForCompanies({type: "industries", values: updatedIds}));
	};

	return (
		<div className={css.infoFirstRow}>
			<div onClick={handleItemClick}>
				<LazyLoadImageWrapper
					className={css.logo}
					image={
						item.logo_url !== null
							? item.logo_url
							: "https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png"
					}
					alt={item.name}
					style={{background: `url(${item.logo_url})`}}
				/>
			</div>
			<div>
				<div className={css.infoTitle} onClick={handleItemClick}>
					<a
						href={`/company/${item.id}${isRecommended ? "?recommended=true" : ""}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						{item.name}
					</a>
				</div>
				<div className={css.infoDetail}> {item.headquarters}</div>
				{item?.industry !== null && item?.industry?.id !== undefined && (
					<div className={css.infoCategory}>
						<span
							className={classNames(css.infoCategoryButton, {
								// [css.selected]: industries?.includes(item.industry.id),
							})}
							onClick={() => handleSelectedIndustries(item?.industry)}
						>
							{item.industry?.name}
						</span>
					</div>
				)}
			</div>
		</div>
	);
}
