import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import config from "config";
import {STAuthResponse, STError} from "api/types";
import {Mixpanel} from "helpers/mixpanel";
import {STUser} from "api/organisationApi/types";
import {createBroadcastChannel} from "helpers/BoradcastChannel";
import {BROADCAST_CHANNELS} from "helpers/BoradcastChannel/BroadcastChannels";

class HttpClient {
	private client: AxiosInstance;
	private accessToken: string | null = localStorage.getItem("token");
	private refreshInProgress: Promise<string> | null = null;
	private requestQueue: Array<(token: string) => void> = [];

	constructor() {
		this.client = axios.create({
			baseURL: config.apiUrl,
			headers: {
				"Access-Control-Allow-Origin": config.apiUrl as string,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});

		this.client.interceptors.request.use(this.handleRequest.bind(this));
		this.client.interceptors.response.use(
			(response) => response,
			this.handleResponseError.bind(this),
		);
	}

	private handleRequest(config: AxiosRequestConfig): AxiosRequestConfig {
		const token = this.getAccessToken();

		if (token && config.headers) {
			config.headers.Authorization = token.startsWith("Bearer ") ? token : `Bearer ${token}`;
		}

		return config;
	}

	private async handleResponseError(error: STError): Promise<AxiosResponse | void> {
		const originalRequest = error.config;

		if (error.response?.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;

			if (this.refreshInProgress) {
				return this.queueRequest(originalRequest);
			}

			try {
				const newToken = await this.refreshToken();
				originalRequest.headers.Authorization = `Bearer ${newToken}`;

				return this.client.request(originalRequest);
			} catch (refreshError) {
				this.handleLogout();

				return Promise.reject(refreshError);
			}
		}

		return Promise.reject(error);
	}

	/**
	 * Refreshes the access token and returns the new one.
	 *
	 * This method makes a request to the `/auth/refresh` endpoint and
	 * returns the new access token. If the request fails, it logs the user
	 * out. If the request is already in progress, it returns the promise
	 * of the in-progress request.
	 *
	 * @returns A promise that resolves to the new access token.
	 */

	private async refreshToken(): Promise<string> {
		if (this.refreshInProgress) {
			return this.refreshInProgress;
		}

		const refreshToken = localStorage.getItem("refresh_token");

		if (!refreshToken) {
			this.handleLogout();
			throw new Error("No refresh token available");
		}

		this.refreshInProgress = new Promise<string>((resolve, reject) => {
			this.client
				.post<STAuthResponse>("/auth/refresh", {refresh_token: refreshToken})
				.then((response) => {
					const {access_token, refresh_token, expires_in} = response.data;
					this.setAccessToken(access_token);
					this.setRefreshToken(refresh_token, expires_in);

					const {postMessage} = createBroadcastChannel<STAuthResponse>(
						BROADCAST_CHANNELS.AUTH_TOKEN,
					);

					postMessage({access_token, refresh_token, expires_in});

					resolve(access_token);

					this.requestQueue.forEach((callback) => callback(access_token));
					this.requestQueue = [];
				})
				.catch((error) => {
					this.requestQueue = [];
					reject(error);
				})
				.finally(() => {
					this.refreshInProgress = null;
				});
		});

		return this.refreshInProgress;
	}

	/**
	 * Queues a request to be executed once a valid access token is available.
	 *
	 * This function adds the original request to a queue and updates its
	 * Authorization header with a newly acquired token before sending it.
	 *
	 * @param originalRequest - The original Axios request configuration.
	 * @returns A promise that resolves with the Axios response once the
	 *          request is successfully executed.
	 */

	private queueRequest(originalRequest: AxiosRequestConfig): Promise<AxiosResponse> {
		return new Promise((resolve, reject) => {
			this.requestQueue.push((token) => {
				if (originalRequest.headers) {
					originalRequest.headers.Authorization = `Bearer ${token}`;
				}
				this.client.request(originalRequest).then(resolve).catch(reject);
			});
		});
	}

	private getAccessToken(): string | null {
		this.accessToken = localStorage.getItem("token");

		return this.accessToken;
	}

	public setAccessToken(token: string): void {
		this.accessToken = token;
		localStorage.setItem("token", token);
		this.client.defaults.headers.common.Authorization = `Bearer ${token}`;
	}

	private setRefreshToken(refresh_token: string, expires_in: number): void {
		localStorage.setItem("refresh_token", refresh_token);
		localStorage.setItem("expires_in", String(new Date().getTime() + expires_in * 1000));
	}

	/**
	 * Logs the user out by clearing local storage and redirecting to the login page.
	 *
	 * Constructs a redirect URL to the login page. If the current pathname is not
	 * an error page, appends the pathname as a query parameter for redirect after login.
	 *
	 * Tracks the logout event in Mixpanel using the user's email, if available, and
	 * resets Mixpanel data. Clears all local storage data and navigates to the redirect URL.
	 */

	private handleLogout(): void {
		let redirect = "/login";
		const pathname = window.location.pathname;

		const errorPages = [
			"/error",
			"/unknown-error",
			"/500",
			"/404",
			"/403",
			"/503",
			"/invitation-invalid",
		];
		if (pathname && !errorPages.includes(pathname)) {
			redirect += `?redirectTo=${pathname}`;
		}

		const savedUser = window.localStorage.getItem("user");
		const user: STUser | null = savedUser ? JSON.parse(savedUser) : null;

		if (user?.email) {
			Mixpanel.track("logged out", {distinct_id: user.email});
			Mixpanel.reset();
		}

		window.localStorage.clear();
		window.location.href = redirect;
	}

	/**
	 * Checks if the access token has expired by comparing the current time to the
	 * stored token expiry time in local storage. Returns true if the token has
	 * expired, false otherwise.
	 *
	 * @returns {boolean} true if the access token has expired, false otherwise
	 */

	public hasExpired(): boolean {
		const expiresIn = localStorage.getItem("expires_in");
		const tokenExpiryTime = expiresIn ? parseInt(expiresIn, 10) : null;

		return tokenExpiryTime !== null && tokenExpiryTime <= Date.now();
	}

	/**
	 * Checks if the access token has expired and refreshes it if necessary.
	 * If the refresh token is also expired, logs the user out.
	 *
	 * @returns A promise that resolves when the token has been refreshed or the user has been logged out.
	 */

	async checkAndRefreshToken(): Promise<void> {
		if (this.hasExpired()) {
			try {
				await this.refreshToken();
			} catch {
				this.handleLogout();
			}
		}
	}

	async doGet<T>(url: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
		return this.client
			.get<T>(url, config)
			.then((res: AxiosResponse<T>) => res)
			.catch((err) => err?.response);
	}

	/**
	 * Sends a POST request with the given url and body.
	 *
	 * If hasMultimedia is true, the Content-Type header is set to
	 * "multipart/form-data" and the body is converted to a FormData
	 * object, otherwise the body is sent as is.
	 *
	 * @param url The url of the request
	 * @param body The body of the request
	 * @param config The Axios request config
	 * @param hasMultimedia Whether the request has multimedia data
	 */
	// eslint-disable-next-line
	async doPost<T, P extends Record<string, any>>(
		url: string,
		body?: P,
		config?: AxiosRequestConfig,
		hasMultimedia = false,
	): Promise<AxiosResponse> {
		const finalConfig = hasMultimedia
			? {
					...config,
					headers: {
						...config?.headers,
						"Content-Type": "multipart/form-data",
					},
			  }
			: config;

		const formData = hasMultimedia ? this.toFormData(body) : body;

		return this.client
			.post<T>(url, formData, finalConfig)
			.then((res: AxiosResponse<T>) => res)
			.catch((err) => err?.response);
	}

	// eslint-disable-next-line
	async doPatch<T, P extends Record<string, any>>(
		url: string,
		body?: P,
		config: AxiosRequestConfig = {},
	): Promise<AxiosResponse<T>> {
		return this.client
			.patch<T>(url, body, config)
			.then((res: AxiosResponse<T>) => res)
			.catch((err) => err?.response);
	}

	// eslint-disable-next-line
	async doPut<T, P extends Record<string, any>>(
		url: string,
		body?: P,
		config: AxiosRequestConfig = {},
	): Promise<AxiosResponse<T>> {
		return this.client
			.put<T>(url, body, config)
			.then((res: AxiosResponse<T>) => res)
			.catch((err) => err?.response);
	}

	// eslint-disable-next-line
	async doDelete<T, P extends Record<string, any>>(
		url: string,
		body?: P,
		config?: AxiosRequestConfig,
	): Promise<AxiosResponse<T>> {
		return this.client
			.delete<T>(url, {data: body, ...config})
			.then((res: AxiosResponse<T>) => res)
			.catch((err) => err?.response);
	}

	// eslint-disable-next-line
	private toFormData(body?: Record<string, any>): FormData {
		const formData = new FormData();

		if (body) {
			Object.entries(body).forEach(([key, value]) => {
				if (typeof value !== "function") {
					formData.append(key, value as string);
				}
			});
		}

		return formData;
	}
}

export default HttpClient;
