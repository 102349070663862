import {Annotation} from "react-simple-maps";

export const HQDisplay = ({lng, lat, name}: {lng: string; lat: string; name: string}) => {
	return (
		<Annotation
			subject={[Number(lng), Number(lat)]}
			dx={-45}
			dy={45}
			connectorProps={{
				stroke: "#000000",
				strokeWidth: 1,
				strokeLinecap: "round",
			}}
		>
			<text
				x="-4"
				y="2"
				textAnchor="end"
				alignmentBaseline="middle"
				fill="#0C5850"
				fontSize={10}
			>
				HQ: {name}
			</text>
		</Annotation>
	);
};
