import mapxProjectApi from "api/projectApi";
import mapxProjectInsightsApi from "api/projectApi/projectStatsApi";
import axios, {AxiosResponse} from "axios";
import {successResponse} from "helpers/map";
import {noop} from "lodash";

import {
	bulkProjectCandidateStatusChange,
	getIndustriesBackgroundReportDone,
	getIndustriesBackgroundReportFailed,
	getIndustriesBackgroundReportInit,
	getIndustriesReportFailed,
	getIndustriesReportFetch,
	getIndustriesReportSuccess,
	getProjectProgressStatusesFailed,
	getProjectProgressStatusesInit,
	getProjectStatsFailed,
	getProjectStatsInit,
	setCandidateIdsForAssessment,
	setCountryReport,
	setEthnicDiversityData,
	setGenderDiversityData,
	setJobFunctionDistributionData,
	setJobFunctionDistributionInit,
	setProjectFrequentlyHiredFromData,
	setProjectProgressStatuses,
	setProjectProgressStatusesFinished,
	setProjectStats,
} from "./projectActions";
import {TAppDispatch, TRootState} from "types";
import {
	STProject,
	STProjectProgressStatus,
	STProjectsIdFrequentlyHiredFrom,
} from "api/projectApi/types";
import projectCandidateApi from "api/projectApi/projectCandidatesApi";
import {selectedCandidateIdsForAssessmentSelector} from "store/mapx/project/candidateScoringSelectors";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {TProjectCandidatesStatusFormBody} from "api/projectApi/projectCandidatesApi/types";
import {updateProjectSavedPeopleCandidatesInTheList} from "store/mapx/target-list/targetListCandidatesAsyncActions";
import {TBulkStatusChangeParams} from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/types";
import {updateAPCandidateByKey} from "store/mapx/target-list/targetListActions";
import {clearCancelToken, getCancelToken} from "api/cancelTokens";
import {
	GET_PROJECT_STATISTICS_TOKEN,
	PROJECT_DIVERSITY_REPORT_TOKEN,
	PROJECT_ETHNICITY_REPORT_TOKEN,
	PROJECT_FREQ_HIRED_FROM_REPORT_TOKEN,
	PROJECT_INDUSTRIES_BACKGROUND_REPORT_TOKEN,
	PROJECT_INDUSTRIES_REPORT_TOKEN,
	PROJECT_JOB_FUNCTION_REPORT_TOKEN,
	PROJECT_LOCATION_REPORT_TOKEN,
} from "api/requestCancelTokenStrings";
import {TProjectCandidateStatusDropdownOption} from "mapx-components/Cards/ProjectCandidateCard/types";

export const getGenderDiversityData =
	(id: number, callback?: () => void) => async (dispatch: TAppDispatch) => {
		const cancelTokenSource = getCancelToken(PROJECT_DIVERSITY_REPORT_TOKEN);

		let data;

		try {
			const response = await mapxProjectInsightsApi.getGenderDiversityReport(id, {
				cancelToken: cancelTokenSource.token,
			});

			if (response && successResponse(response, 200)) {
				data = response.data;
			} else {
				data = [];
			}

			dispatch(setGenderDiversityData(data));
		} catch (e) {
			if (axios.isCancel(e)) {
				console.log("Request canceled:", e.message);
			} else {
				console.error(e);
			}

			callback && callback();
		} finally {
			callback && callback();
			clearCancelToken(PROJECT_DIVERSITY_REPORT_TOKEN);
		}
	};

export const getEthnicDiversityData =
	(id: number, callback?: () => void) => async (dispatch: TAppDispatch) => {
		const cancelTokenSource = getCancelToken(PROJECT_ETHNICITY_REPORT_TOKEN);

		let data;

		try {
			const response = await mapxProjectInsightsApi.getEthnicDiversityReport(id, {
				cancelToken: cancelTokenSource.token,
			});

			if (response && successResponse(response, 200)) {
				data = response.data;
			} else {
				data = [];
			}

			dispatch(setEthnicDiversityData(data));
		} catch (e) {
			if (axios.isCancel(e)) {
				console.log("Request canceled:", e.message);
			} else {
				console.error(e);
			}
		} finally {
			callback && callback();

			clearCancelToken(PROJECT_ETHNICITY_REPORT_TOKEN);
		}
	};

export const getCountryReport =
	(id: number, callback?: () => void) => async (dispatch: TAppDispatch) => {
		const cancelTokenSource = getCancelToken(PROJECT_LOCATION_REPORT_TOKEN);

		let data = [];

		try {
			const response: AxiosResponse = await mapxProjectInsightsApi.getCountryReport(id, {
				cancelToken: cancelTokenSource.token,
			});

			if (response && successResponse(response, 200)) {
				data = response.data.results;
			} else {
				data = [];
			}

			dispatch(setCountryReport(data));
		} catch (e) {
			if (axios.isCancel(e)) {
				console.log("Request canceled:", e.message);
			} else {
				console.error(e);
			}
		} finally {
			callback && callback();

			clearCancelToken(PROJECT_LOCATION_REPORT_TOKEN);
		}
	};

export const getIndustryReportData = (id: number) => async (dispatch: TAppDispatch) => {
	dispatch(getIndustriesReportFetch());

	const cancelTokenSource = getCancelToken(PROJECT_INDUSTRIES_REPORT_TOKEN);

	try {
		const config = {
			cancelToken: cancelTokenSource.token,
		};

		const response: AxiosResponse = await mapxProjectInsightsApi.getIndustriesReport(
			id,
			config,
		);

		if (successResponse(response, 200)) {
			const data = response.data.results;

			dispatch(getIndustriesReportSuccess(data));
		}
	} catch (e) {
		if (axios.isCancel(e)) {
			console.log("Request canceled:", e.message);
		} else {
			console.error(e);
			dispatch(getIndustriesReportFailed());
		}
	} finally {
		clearCancelToken(PROJECT_INDUSTRIES_REPORT_TOKEN); // Ensure token is cleared
	}
};

export const getJobFunctionDistributionReport = (id: number) => async (dispatch: TAppDispatch) => {
	dispatch(setJobFunctionDistributionInit());

	const cancelTokenSource = getCancelToken(PROJECT_JOB_FUNCTION_REPORT_TOKEN);

	try {
		const response: AxiosResponse = await mapxProjectInsightsApi.getJobFunctionDistribution(
			id,
			{
				cancelToken: cancelTokenSource.token,
			},
		);

		if (successResponse(response, 200)) {
			const data = response.data.results;

			dispatch(setJobFunctionDistributionData(data));
		}
	} catch (e) {
		if (axios.isCancel(e)) {
			console.log("Request canceled:", e.message);
		} else {
			console.error(e);
		}
	} finally {
		clearCancelToken(PROJECT_JOB_FUNCTION_REPORT_TOKEN);
	}
};

export const getIndustriesBackgroundReport =
	(project_id: number) => async (dispatch: TAppDispatch) => {
		dispatch(getIndustriesBackgroundReportInit());

		const cancelTokenSource = getCancelToken(PROJECT_INDUSTRIES_BACKGROUND_REPORT_TOKEN);

		try {
			const response: AxiosResponse =
				await mapxProjectInsightsApi.getIndustriesBackgroundReport(project_id, {
					cancelToken: cancelTokenSource.token,
				});

			if (successResponse(response, 200)) {
				const data = response.data.results;

				dispatch(getIndustriesBackgroundReportDone(data));
			}
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log("Request canceled:", error.message);
			} else {
				console.error(error);
			}

			dispatch(getIndustriesBackgroundReportFailed());

			return {error};
		} finally {
			clearCancelToken(PROJECT_INDUSTRIES_BACKGROUND_REPORT_TOKEN);
		}
	};

export const getProjectStats = (id: number) => async (dispatch: TAppDispatch) => {
	dispatch(getProjectStatsInit());

	const config = {
		cancelToken: getCancelToken(GET_PROJECT_STATISTICS_TOKEN).token,
	};

	try {
		const response = await mapxProjectInsightsApi.getProjectStats(id, config);

		if (successResponse(response, 200)) {
			const data = response.data;

			dispatch(setProjectStats(data));
		}

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (axios.isCancel()) {
			dispatch(getIndustriesReportFailed());
		}
	} catch (e) {
		if (axios.isCancel(e)) {
			console.log("Request canceled:", e.message);
		} else {
			console.error(e);
			dispatch(getProjectStatsFailed());
		}
	} finally {
		clearCancelToken(GET_PROJECT_STATISTICS_TOKEN);
	}
};

export const getProjectFrequentlyHiredFromData =
	(id: number, params: STProjectsIdFrequentlyHiredFrom, callback = noop) =>
	async (dispatch: TAppDispatch) => {
		const cancelTokenSource = getCancelToken(PROJECT_FREQ_HIRED_FROM_REPORT_TOKEN);

		try {
			const response = await mapxProjectInsightsApi.getProjectFrequentlyHiredFrom(
				id,
				params,
				{cancelToken: cancelTokenSource.token},
			);

			if (response && successResponse(response, 200)) {
				const data = response.data;

				dispatch(setProjectFrequentlyHiredFromData(data));
			}
		} catch (e) {
			if (axios.isCancel(e)) {
				console.log("Request canceled:", e.message);
			} else {
				console.error(e);
			}
		} finally {
			callback && callback();

			clearCancelToken(PROJECT_FREQ_HIRED_FROM_REPORT_TOKEN);
		}
	};

export const getProjectProgressStatuses = () => async (dispatch: TAppDispatch) => {
	try {
		dispatch(getProjectProgressStatusesInit());

		const response: AxiosResponse = await mapxProjectApi.getProjectProgressStatuses();

		if (successResponse(response, 200)) {
			const data = response.data.results as STProjectProgressStatus[];

			if (data?.length > 0) {
				dispatch(setProjectProgressStatuses(data));
			} else {
				dispatch(setProjectProgressStatusesFinished());
			}
		}
	} catch (error) {
		dispatch(getProjectProgressStatusesFailed());

		return {error};
	}
};

export const changeProjectCandidateStatus =
	({isRecommended, statusId}: TBulkStatusChangeParams) =>
	async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const candidateIds: number[] = selectedCandidateIdsForAssessmentSelector(state);

			const project: STProject = projectSelector(state);

			const formBody: TProjectCandidatesStatusFormBody = {
				candidate_ids: candidateIds,
			};

			if (isRecommended !== undefined) {
				formBody.is_recommended = isRecommended;
			}

			if (statusId !== undefined) {
				formBody.progress_status_id = statusId;
			}

			dispatch(bulkProjectCandidateStatusChange(true));

			const response = await projectCandidateApi.updateProjectCandidatesStatus(
				project.id,
				formBody,
			);

			dispatch(bulkProjectCandidateStatusChange(false));

			if (successResponse(response, 200)) {
				dispatch(updateProjectSavedPeopleCandidatesInTheList());

				return response.data;
			}
		} catch (error) {
			dispatch(bulkProjectCandidateStatusChange(false));

			return {error};
		}
	};

export const removeSelectedCandidatesFromProject =
	() => async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const candidateIds: number[] = selectedCandidateIdsForAssessmentSelector(state);

			const project: STProject = projectSelector(state);

			const formBody = {
				candidate_ids: candidateIds,
			};

			const response = await projectCandidateApi.deleteProjectCandidatesStatus(
				project.id,
				formBody,
			);

			if (successResponse(response, 200)) {
				dispatch(updateProjectSavedPeopleCandidatesInTheList());

				dispatch(setCandidateIdsForAssessment([]));

				return response.data;
			}
		} catch (error) {
			return {error};
		}
	};

export const changeCandidateStatusByCandidateId =
	(candidateId: number, status: TProjectCandidateStatusDropdownOption) =>
	async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const project: STProject = projectSelector(state);

			const formBody = {
				candidate_ids: [candidateId],
				progress_status_id: status.value,
			};

			const response = await projectCandidateApi.updateProjectCandidatesStatus(
				project.id,
				formBody,
			);

			if (successResponse(response, 200)) {
				const payload = {
					id: candidateId,
					key: "progress_status",
					value: status.value ? status.name : null,
				};

				dispatch(updateAPCandidateByKey(payload));

				return response.data;
			}
		} catch (error) {
			return {error};
		}
	};
