import type {FC} from "react";
import classNames from "classnames";

import {InfoCard} from "assets/icons";

import type {TSkeletonLoaderCardAsListProps} from "./types";
import styles from "./styles.module.scss";

const SkeletonLoaderCardAsList: FC<TSkeletonLoaderCardAsListProps> = ({
	count = 5,
	customClass = "",
}) => (
	<div
		className={classNames(styles.container, {[customClass]: customClass})}
		data-testid="candidates-skeleton-list"
	>
		{Array.from({length: count}, (_, idx) => (
			<div key={idx} className={styles.container__card}>
				<InfoCard />
			</div>
		))}
	</div>
);

export default SkeletonLoaderCardAsList;
