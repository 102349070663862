export const createBroadcastChannel = <T>(channelName: string) => {
	const channel = new BroadcastChannel(channelName);

	const postMessage = (data: T) => {
		channel.postMessage(data);
	};

	const subscribe = (onMessage: (data: T) => void) => {
		const handler = (event: MessageEvent<T>) => {
			onMessage(event.data);
		};

		channel.addEventListener("message", handler);

		return () => {
			channel.removeEventListener("message", handler);
			channel.close();
		};
	};

	return {postMessage, subscribe};
};
