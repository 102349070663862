import React, {useCallback, useEffect, useMemo, useState} from "react";
import {AutoComplete} from "mapx-components";
import {PersonItem} from "components";
import {getSearchedCandidatesData} from "store/mapx/filter/candidateSearchPageAsyncActions";
import useInfiniteScroll from "react-infinite-scroll-hook";
import useSearchDebounce from "hooks/useSearchDebounce";
import {clearSearchedCandidates} from "store/mapx/filter/filterActions";
import {
	getSingleCandidateDone,
	setSelectedCandidatesDone,
	setSelectedCandidatesInit,
} from "store/mapx/candidate/candidateActions";
import mapXCandidateApi from "api/candidateApi";
import {TSearchByNameProps, TSuggestionListItemProps} from "./types";
import {useAppDispatch, useAppSelector} from "hooks";
import {SICandidate} from "api/candidateApi/types";
import {selectedCandidatesSelector} from "store/mapx/filter/filterSelectors";
import {AxiosResponse} from "axios";

function SearchByName({toggleDisplayUserSearchedResult}: TSearchByNameProps) {
	const dispatch = useAppDispatch();

	const selectedCandidates = useAppSelector(selectedCandidatesSelector);

	const searchedCandidates = useAppSelector((state) => state.filter.searchedCandidates);

	const [searchTerm, setSearchTerm] = useSearchDebounce(500);

	const [loading, setLoading] = useState(false);

	const searchCandidates = useCallback(
		async (candidateName: string, page: number) => {
			if (candidateName !== "" && candidateName.length >= 2) {
				setLoading(true);
				await dispatch(getSearchedCandidatesData(candidateName, page)).then(
					(response: {error: {name: string}}) => {
						if (response?.error && response?.error?.name === "CanceledError") {
							setLoading(true);
						} else {
							setLoading(false);
						}
					},
				);
			}
		},
		[dispatch],
	);

	const [loadingRef] = useInfiniteScroll({
		loading,
		hasNextPage: searchedCandidates?.pagination?.pages > searchedCandidates?.pagination?.page,
		onLoadMore: () =>
			searchCandidates(searchTerm, searchedCandidates?.pagination?.page + 1 || 1),
		rootMargin: "0px 0px 400px 0px",
	});

	const handleFilterChange = (searchText: string) => {
		setSearchTerm(searchText);
	};

	const handleItemClick = useCallback(
		async (selectedCandidate: SICandidate) => {
			if (loading) return;
			setLoading(true);

			dispatch(setSelectedCandidatesInit());

			const response: AxiosResponse = await mapXCandidateApi.getCandidate(
				selectedCandidate.id,
			);

			setLoading(false);

			if (response.status === 200) {
				const candidate = response.data;
				candidate.searched_by_linkedin = false;

				// multi candidate selection turned off

				dispatch(setSelectedCandidatesDone([candidate]));

				dispatch(getSingleCandidateDone(candidate));

				toggleDisplayUserSearchedResult([candidate]);
			}
		},
		[dispatch, loading, toggleDisplayUserSearchedResult],
	);

	useEffect(() => {
		if (searchTerm) {
			searchCandidates(searchTerm, 1);
		}
	}, [searchTerm, dispatch, searchCandidates]);

	useEffect(() => {
		if (selectedCandidates?.length === 0) {
			dispatch(clearSearchedCandidates());
		}
	}, [dispatch, selectedCandidates?.length]);

	const inputValue = useMemo(
		() =>
			selectedCandidates?.length > 0 && !selectedCandidates[0].searched_by_linkedin
				? selectedCandidates[0].full_name
				: "",
		[selectedCandidates],
	);

	return (
		<div data-testid="search-by-name">
			<AutoComplete
				key={inputValue !== "" ? inputValue : "default"}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				style={{border: "1px solid #DAD8D7", borderRadius: "0px 0px 0px 4px"}}
				disableFocus
				notFoundResult="No people found"
				suggestions={searchedCandidates.results}
				handleItemClick={handleItemClick}
				handleFilterChange={handleFilterChange}
				clearInputOnSelect={false}
				defaultValue={inputValue}
				isLoading={loading}
				SuggestionListItemComponent={({
					index,
					suggestion,
					onClick,
				}: TSuggestionListItemProps) => {
					return (
						<section onClick={() => setSearchTerm("")}>
							<PersonItem.Suggestion index={index}>
								<PersonItem candidate={suggestion} onClick={onClick} />
							</PersonItem.Suggestion>
						</section>
					);
				}}
				ref={loadingRef}
				hasMore={loading}
				placeholderText={"Search By Name"}
				data-testid={"search-input-for-candidate-search-on-filterOptionsApi"}
			/>
		</div>
	);
}

export default SearchByName;
