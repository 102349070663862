import {useMemo, useState, useEffect} from "react";
import {ProjectCandidateCard} from "mapx-components";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	filteredSavedCandidatesPaginationSelector,
	filteredSavedCandidatesSelector,
	filteredSavedPeopleFetchIsInProgressSelector,
	targetCandidatesPaginationSelector,
	targetListCandidateInProgressSelector,
	targetListCandidatesSelector,
} from "store/mapx/target-list/targetListSelectors";
import {SICandidate} from "api/candidateApi/types";
import {SkeletonLoaderCardAsList} from "components";
import styles from "./styles.module.scss";
import {LazyLoadComponent} from "react-lazy-load-image-component";
import SavedPeopleHeaderSection from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection";
import FilterSection from "mapx-pages/Project/SavedPeople/FilterSection";
import {useSelector} from "react-redux";
import {additionalProfileFiltersSelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {buildCandidateApiRequestPayload} from "helpers/filterHandlers";
import {sortObjectsAlphabetically} from "helpers/array";

import {selectedCandidateIdsForAssessmentSelector} from "store/mapx/project/candidateScoringSelectors";
import Pagination from "mapx-components/Pagination";
import {
	setFilteredSavedPeoplePaginationInfo,
	setSavedPeoplePaginationInfo,
} from "store/mapx/target-list/targetListActions";

const SavedPeople = () => {
	const dispatch = useAppDispatch();

	const selectedCandidatesIdsForAssessment = useAppSelector(
		selectedCandidateIdsForAssessmentSelector,
	);

	const filteredCandidatesFetchInProgress = useAppSelector(
		filteredSavedPeopleFetchIsInProgressSelector,
	);

	const targetListCandidateInProgress = useAppSelector(targetListCandidateInProgressSelector);

	const savedPeoples: SICandidate[] = useAppSelector(targetListCandidatesSelector);

	const filteredSavedPeoples: SICandidate[] = useAppSelector(filteredSavedCandidatesSelector);

	const targetCandidatesPagination = useAppSelector(targetCandidatesPaginationSelector);

	const filteredTargetCandidatesPagination = useAppSelector(
		filteredSavedCandidatesPaginationSelector,
	);

	const filterSelection = useSelector(additionalProfileFiltersSelector);

	const [displayAllExperience, setDisplayAllExperience] = useState<boolean>(false);

	const [totalDisplayNumber, setTotalDisplayNumber] = useState<number>(20);

	const [prevPaginationCount, setPrevPaginationCount] = useState(null);

	const hasFilters = useMemo(() => {
		return !!buildCandidateApiRequestPayload(filterSelection, false);
	}, [filterSelection]);

	const pagination = useMemo(
		() => (hasFilters ? filteredTargetCandidatesPagination : targetCandidatesPagination),
		[filteredTargetCandidatesPagination, hasFilters, targetCandidatesPagination],
	);

	useEffect(() => {
		if (pagination?.count !== prevPaginationCount) {
			setPrevPaginationCount(pagination?.count);

			if (hasFilters) {
				dispatch(
					setFilteredSavedPeoplePaginationInfo({
						count: pagination?.count,
						page: 1,
						per_page: 20,
					}),
				);
			} else {
				dispatch(
					setSavedPeoplePaginationInfo({
						count: pagination?.count,
						page: 1,
						per_page: 20,
					}),
				);
			}
		}
	}, [dispatch, hasFilters, pagination?.count, prevPaginationCount]);

	const displayedCandidates = useMemo(() => {
		let candidates = hasFilters ? filteredSavedPeoples : savedPeoples;

		const startIndex = (pagination?.page - 1) * totalDisplayNumber;
		const endIndex = startIndex + totalDisplayNumber;

		candidates = candidates.slice(startIndex, endIndex);

		if (filterSelection.ordering === "-is_recommended") {
			return sortObjectsAlphabetically(candidates, "is_recommended", "desc");
		} else {
			return candidates;
		}
	}, [
		filteredSavedPeoples,
		hasFilters,
		savedPeoples,
		pagination,
		totalDisplayNumber,
		filterSelection?.ordering,
	]);

	const candidateSelectionTypeForAssessment = useMemo(() => {
		if (pagination?.count === selectedCandidatesIdsForAssessment?.length) {
			return "all";
		} else if (selectedCandidatesIdsForAssessment?.length > 0) {
			return "partial";
		} else {
			return "none";
		}
	}, [pagination?.count, selectedCandidatesIdsForAssessment?.length]);

	function onPageChange(page: number) {
		if (hasFilters) {
			dispatch(setFilteredSavedPeoplePaginationInfo({...pagination, page: page}));
		} else {
			dispatch(setSavedPeoplePaginationInfo({...pagination, page: page}));
		}
	}

	const candidatesFetchInProgress = useMemo(
		() => filteredCandidatesFetchInProgress || targetListCandidateInProgress,
		[filteredCandidatesFetchInProgress, targetListCandidateInProgress],
	);

	const displayPagination = useMemo(
		() => (hasFilters ? filteredSavedPeoples?.length >= 10 : savedPeoples?.length >= 10),
		[filteredSavedPeoples, savedPeoples, hasFilters],
	);

	const totalPagesCount = useMemo(
		() => Math.ceil(pagination?.count / totalDisplayNumber),
		[pagination?.count, totalDisplayNumber],
	);

	return (
		<div className={styles.listedItems}>
			<FilterSection />

			{!candidatesFetchInProgress && (
				<SavedPeopleHeaderSection
					displayAllExperience={displayAllExperience}
					setDisplayAllExperience={setDisplayAllExperience}
					hasActiveFilters={hasFilters}
					currentDisplayedCount={pagination?.count ?? 0}
					totalSavedPeopleCount={targetCandidatesPagination?.count ?? 0}
					candidateSelectionTypeForAssessment={candidateSelectionTypeForAssessment}
				/>
			)}

			<LazyLoadComponent>
				{!candidatesFetchInProgress &&
					displayedCandidates?.map((candidate, index) => {
						return (
							<ProjectCandidateCard
								key={`${candidate?.id}${index}`}
								candidate={candidate}
								searchType="Project"
								displayDealExperience={true}
								displayAllExperience={displayAllExperience}
								isRecommended={candidate.is_recommended}
							/>
						);
					})}
			</LazyLoadComponent>

			{candidatesFetchInProgress && <SkeletonLoaderCardAsList count={5} />}

			{!candidatesFetchInProgress && pagination && displayPagination && (
				<div className={styles.paginationWrapper}>
					<Pagination
						currentPage={pagination?.page}
						resultsCount={pagination?.count}
						totalPages={totalPagesCount}
						perPage={totalDisplayNumber}
						onPageChange={onPageChange}
						onPerPageChange={(count) => setTotalDisplayNumber(count)}
						scrollDelayAfterPageChange={400}
					/>
				</div>
			)}

			{displayedCandidates?.length === 0 &&
				!candidatesFetchInProgress &&
				(hasFilters
					? "No profiles found based on the selected filters"
					: "You have not saved any people on this project yet")}
		</div>
	);
};

export default SavedPeople;
