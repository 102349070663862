import {useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";

import {ContentTabs} from "components";
import {MainWrapper, PageTitle} from "mapx-components";
import OrganizationalChart from "mapx-pages/CompanyDetails/OrganisationalChart";
import {useAppDispatch, useAppSelector, useCompanyIdParam} from "hooks";
import {
	getCompanyEthnicDiversityData,
	getCompanyEthnicDiversityDataFilter,
	getCompanyGenderDiversityData,
	getCompanyGenderDiversityDataFilter,
	getCompanyLocationReportData,
	getCompanyOrganizationalChart,
	getCompanyOrganizationalChartFilter,
	getCompanyTrendStats,
	getCompanyTrendStatsFilter,
	getSingleCompanyData,
} from "store/mapx/company/companyAsyncActions";
import {
	clearCompanyOrganizationalChart,
	clearCompanyPageFilterWithAllResponses,
	clearMapThisFunctionDataForCurrentCompany,
} from "store/mapx/company/companyActions";
import {
	companiesInProgressSelector,
	companiesOrgChartFiltersSelector,
	companySelector,
} from "store/mapx/company/companySelectors";
import QuickViewCandidate from "mapx-pages/Project/QuickViewCandidate";

import OverView from "./Overview";
import CompanySkeletons from "./CompanySkeletons";
import {diversityIndustryFrequentlyData} from "./utils";
import styles from "./company.module.scss";
import {useSelector} from "react-redux";
import {hasAccessToExtendedPagesSelector} from "store/mapx/user/userSelectors";
import {candidatesImportForCompanySelector} from "store/mapx/linkedin-bulk-import/linkedinBulkImportSelectors";
import {cancelRequest} from "api/cancelTokens";
import {
	GET_COMPANY_ETHNICITY_REPORT_TOKEN,
	GET_COMPANY_FHF_REPORT_TOKEN,
	GET_COMPANY_GENDER_REPORT_TOKEN,
	GET_COMPANY_INDUSTRIES_REPORT_TOKEN,
	GET_COMPANY_LOCATION_REPORT_TOKEN,
	GET_COMPANY_MAP_HISTORY_TOKEN,
	GET_COMPANY_ORG_CHART_REPORT_TOKEN,
	GET_COMPANY_SPECIALISMS_REPORT_TOKEN,
	GET_COMPANY_TRENDS_STATS_REPORT_TOKEN,
} from "api/requestCancelTokenStrings";
import {TReportFilterParams} from "api/companyApi/types";

const CompanyDetails = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const search = useLocation().search;

	const companyParamId = useCompanyIdParam();

	const filtersInState: TReportFilterParams = useAppSelector(companiesOrgChartFiltersSelector);

	const currentCompany = useSelector(companySelector);

	const companiesInProgress = useSelector(companiesInProgressSelector);

	const candidatesImportForCompany = useSelector(candidatesImportForCompanySelector);

	const hasAccessToExtendedPages = useSelector(hasAccessToExtendedPagesSelector);

	const recommended = new URLSearchParams(search).get("recommended");

	const goCompanyHandler = () => {
		history.push("/companies");
	};

	useEffect(() => {
		if (
			(companyParamId && !currentCompany) ||
			(companyParamId && companyParamId !== currentCompany?.id)
		) {
			dispatch(getSingleCompanyData(companyParamId));
		}

		async function getCompanyData() {
			const filterApplied = Object.values(filtersInState).some((filters) => !!filters.length);

			if (filterApplied) {
				dispatch(getCompanyOrganizationalChartFilter(companyParamId, filtersInState));

				dispatch(getCompanyEthnicDiversityDataFilter(companyParamId, filtersInState));

				dispatch(getCompanyGenderDiversityDataFilter(companyParamId, filtersInState));

				dispatch(getCompanyTrendStatsFilter(companyParamId, filtersInState));

				dispatch(getCompanyLocationReportData(companyParamId, filtersInState));
			} else {
				dispatch(getCompanyOrganizationalChart(companyParamId));

				dispatch(getCompanyEthnicDiversityData(companyParamId));

				dispatch(getCompanyGenderDiversityData(companyParamId));

				dispatch(getCompanyTrendStats(companyParamId));

				dispatch(getCompanyLocationReportData(companyParamId, {}));
			}
		}

		if (companyParamId === currentCompany?.id) {
			getCompanyData();
		}

		return () => {
			if (companyParamId === currentCompany?.id) {
				dispatch(clearCompanyOrganizationalChart());
				dispatch(clearCompanyPageFilterWithAllResponses());
				dispatch(clearMapThisFunctionDataForCurrentCompany(companyParamId));

				cancelRequest(GET_COMPANY_ORG_CHART_REPORT_TOKEN);
				cancelRequest(GET_COMPANY_ETHNICITY_REPORT_TOKEN);
				cancelRequest(GET_COMPANY_GENDER_REPORT_TOKEN);
				cancelRequest(GET_COMPANY_TRENDS_STATS_REPORT_TOKEN);
				cancelRequest(GET_COMPANY_LOCATION_REPORT_TOKEN);
				cancelRequest(GET_COMPANY_FHF_REPORT_TOKEN);
				cancelRequest(GET_COMPANY_INDUSTRIES_REPORT_TOKEN);
				cancelRequest(GET_COMPANY_SPECIALISMS_REPORT_TOKEN);
				cancelRequest(GET_COMPANY_MAP_HISTORY_TOKEN);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyParamId, dispatch, candidatesImportForCompany, currentCompany]);

	return (
		<MainWrapper>
			<div className={styles.container}>
				<QuickViewCandidate />

				{companiesInProgress ? (
					<div className={styles.loaderContainer}>
						<CompanySkeletons />
					</div>
				) : (
					companyParamId &&
					currentCompany &&
					currentCompany?.id === companyParamId && (
						<>
							{hasAccessToExtendedPages && (
								<PageTitle
									title="All Companies"
									onClick={goCompanyHandler}
									content={currentCompany.name}
								/>
							)}

							<OverView
								type="profile"
								company={currentCompany}
								isRecommended={!!recommended}
							/>

							<div className={styles.chart}>
								<OrganizationalChart />

								<ContentTabs content={diversityIndustryFrequentlyData} />
							</div>

							{/*<CompanyLocations />*/}
							{/*<Competitors />*/}
						</>
					)
				)}
			</div>
		</MainWrapper>
	);
};

export default CompanyDetails;
