import {FC, Fragment, MouseEvent} from "react";
import classNames from "classnames";

import {Button} from "components";
import {useAppSelector} from "hooks";
import InfoTooltip from "mapx-pages/Home/LayoutHeader/InfoTooltip";
import {
	userCreditRefreshSelector,
	userLicenseTypeNameSelector,
} from "store/mapx/user/userSelectors";

import type {TLocalItemsProps} from "./types";
import styles from "./HeaderDropDown.module.scss";
import {uniqueId} from "lodash";

const LocalItems: FC<TLocalItemsProps> = ({featureUserList}) => {
	const userCreditRefreshSelect = useAppSelector(userCreditRefreshSelector);
	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);
	const isStarterOrPlusUser: boolean =
		userLicenseTypeName === "Starter" || userLicenseTypeName === "Plus";

	const renderList = featureUserList?.map(
		({
			id,
			to,
			icon,
			href,
			text,
			target,
			onClick,
			maxCount,
			deleteBtn,
			RightIcon,
			rightBadge,
			btnClassName = "",
			rightBadgeContainer = "",
		}) => {
			const clickHandler = (event: MouseEvent<HTMLElement>) => {
				event?.stopPropagation();
				onClick?.();
			};

			const hrLine = id === "hrLine";
			const emptyData = id === "EmptyData";
			const itemIsEmpty = id === "EmptyItem";

			return (
				!itemIsEmpty &&
				(!hrLine ? (
					<Button
						to={to}
						key={id}
						href={href}
						target={target}
						LeftIcon={icon}
						defaultBtnStyle
						dataTestId={text}
						onClick={clickHandler}
						className={classNames(styles.list__item, {
							[btnClassName]: btnClassName,
							[styles.list__item__red]: deleteBtn,
							[styles.list__item__empty]: emptyData,
						})}
						leftIconClasses={classNames(styles.list__item__icon, {
							[styles.list__item__icon__red]: deleteBtn,
						})}
					>
						{!emptyData ? text : `No ${text} has been added yet.`}

						{rightBadge ? (
							<div
								className={classNames(styles.list__item_container, {
									[rightBadgeContainer]: rightBadgeContainer,
								})}
							>
								<p className={styles.list__item__badge}>
									{rightBadge}
									<span>{maxCount}</span>{" "}
								</p>

								{RightIcon && <RightIcon dataTooltipId="helpIcon" />}
							</div>
						) : null}
					</Button>
				) : (
					<hr key={uniqueId()} className={styles.line} />
				))
			);
		},
	);

	return (
		<Fragment>
			{featureUserList?.length &&
				(isStarterOrPlusUser && featureUserList[0].id !== "logout"
					? renderList?.slice(1)
					: renderList)}

			<InfoTooltip id="helpIcon" nextRefresh={userCreditRefreshSelect} />
		</Fragment>
	);
};

export default LocalItems;
