import type {FC} from "react";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {uniqueId} from "lodash";
import {RoundedButton} from "components";
import {useAppDispatch, useAppSelector, useCompanyIdParam} from "hooks";
import {getCompanyFrequentlyHiredFromData} from "store/mapx/company/companyAsyncActions";
import {
	companiesOrgChartFiltersSelector,
	companyFrequentlyHiredFromDataSelector,
	companyOrgChartFilteringSelector,
} from "store/mapx/company/companySelectors";

import CompanyCard from "./CompanyCard";
import styles from "./frequentlyHiredFrom.module.scss";
import classNames from "classnames";
import {IndustryDiversityTabComponentProps} from "mapx-pages/CompanyDetails/types";
import CompanyCardSkeleton from "mapx-pages/CompanyDetails/FrequentlyHiredFrom/CompanyCardSkeleton";

const FrequentlyHiredFrom: FC<IndustryDiversityTabComponentProps> = ({isCurrentTabSelected}) => {
	const dispatch = useAppDispatch();
	const companyParamId = useCompanyIdParam();

	const data = useAppSelector(companyFrequentlyHiredFromDataSelector);
	const companiesOrgChartFilters = useAppSelector(companiesOrgChartFiltersSelector);
	const companyOrgChartFiltering = useAppSelector(companyOrgChartFilteringSelector);

	const [loading, setLoading] = useState<boolean>(false);

	const showMoreEnabled = useMemo(() => {
		if (data?.pagination) {
			return data?.pagination?.page !== data?.pagination?.pages;
		}

		return true;
	}, [data]);

	const handleShowMoreClick = useCallback(() => {
		setLoading(true);
		dispatch(
			getCompanyFrequentlyHiredFromData(
				companyParamId.toString(),
				() => setLoading(false),
				data?.pagination?.page + 1,
				companiesOrgChartFilters,
			),
		);
	}, [dispatch, companyParamId, data?.pagination?.page, companiesOrgChartFilters]);

	useEffect(() => {
		if (companyParamId) {
			setLoading(true);

			dispatch(
				getCompanyFrequentlyHiredFromData(
					companyParamId.toString(),
					() => setLoading(false),
					1,
					companiesOrgChartFilters,
				),
			);
		}
	}, [companyParamId, companiesOrgChartFilters, dispatch]);

	// return data?.results?.length > 0 ? (
	return (
		<div
			className={classNames(styles.container, {
				[styles.hidden]: !isCurrentTabSelected,
			})}
		>
			<div className={styles.companies}>
				{
					// @TODO Need to refactor redux to ts
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					data?.results?.map(({company}: any) => (
						<CompanyCard key={uniqueId()} company={company} isTooltipSelect={false} />
					))
				}
			</div>

			{(loading || companyOrgChartFiltering) && (
				<div className={styles.loader}>
					{Array.from({length: 3}, (_, idx) => (
						<div key={idx} style={{width: "350px"}}>
							<CompanyCardSkeleton />
						</div>
					))}
				</div>
			)}

			{!loading && showMoreEnabled && data?.results?.length > 0 && (
				<div className={styles.showMore}>
					<RoundedButton onClick={handleShowMoreClick}>Show More</RoundedButton>
				</div>
			)}
			{!companyOrgChartFiltering && !data?.results?.length && !loading && (
				<h2 className={styles.container__is_empty}>No Employees Found</h2>
			)}
		</div>
	);
};

export default FrequentlyHiredFrom;
