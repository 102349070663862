import React, {Component, ErrorInfo} from "react";
import {Provider} from "react-redux";
import {ToastContainer} from "react-toastify";
import {BrowserRouter as Router} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import "react-sliding-pane/dist/react-sliding-pane.css";

import store from "store";
import {ScrollToTop} from "components";
import AppContainer from "AppContainer";
import "./styles/global.scss";

class App extends Component<React.PropsWithChildren<NonNullable<unknown>>, NonNullable<unknown>> {
	constructor(props: React.PropsWithChildren<NonNullable<unknown>>) {
		super(props);
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		if (process.env.NODE_ENV === "production") {
			window.location.href = "/unknown-error";
			// @TODO: send notification with error/errorInfo to slack.
			console.error(error);
			console.error(errorInfo);
			window.localStorage.setItem("error", error.toString());
			window.localStorage.setItem("errorInfo", JSON.stringify(errorInfo));
		}
	}

	render() {
		return (
			<Provider store={store}>
				<Router>
					<AppContainer />
					<ToastContainer
						limit={3}
						autoClose={5000}
						closeButton={false}
						containerId="toast-container"
						draggable
						hideProgressBar={false}
						icon={false}
						newestOnTop={true}
						pauseOnFocusLoss
						pauseOnHover
						position="top-right"
						theme="light"
					/>
					<ScrollToTop />
				</Router>
			</Provider>
		);
	}
}

export default App;
