import {TAppDispatch, TRootState} from "types";
import {
	setCandidateIdsForAssessment,
	setCriteriaForProjectCandidateAssessment,
} from "store/mapx/project/projectActions";
import {
	additionalProfileFiltersSelector,
	apCandidateOrderingSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {buildCandidateApiRequestPayload} from "helpers/filterHandlers";
import {
	filteredSavedCandidatesSelector,
	targetListCandidatesSelector,
	targetListIDSelector,
} from "store/mapx/target-list/targetListSelectors";
import {SICandidate} from "api/candidateApi/types";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {STBackgroundTaskResponse, TBackgroundTaskCreate} from "api/backgroundTaskApi/types";
import mapXBackgroundTaskAPI from "api/backgroundTaskApi";
import {Mixpanel} from "helpers/mixpanel";
import {successResponse} from "helpers/map";
import {STProject} from "api/projectApi/types";
import {userEmailSelector} from "store/mapx/user/userSelectors";
import {
	projectCandidateAssessmentProcessRequestCreateInProgress,
	setProjectCandidateAssessmentProcess,
} from "store/mapx/background-tasks/backgroundTaskActions";
import {
	briefCriteriaProjectAssessmentSelector,
	isCandidateSelectedForAssessmentSelector,
	selectedCandidateIdsForAssessmentSelector,
} from "store/mapx/project/candidateScoringSelectors";
import axios, {AxiosResponse, CancelTokenSource} from "axios";
import {ICandidateByIdsApiRequestPayload} from "api/candidateApi/form";
import mapXCandidateApi from "api/candidateApi";
import {updateCandidateProfileAfterRefresh} from "store/mapx/refresh-candidate/refreshCandidateActions";
import {
	updateFilteredTargetListCandidatesWithSummary,
	updateTargetListCandidatesWithSummary,
} from "store/mapx/target-list/targetListActions";
import {getBackgroundTaskInfoById} from "store/mapx/background-tasks/backgroundTaskAsyncActions";
import {STFitToBriefCriteria} from "api/projectApi/candidateAssessment/types";
import candidateAssessment from "api/projectApi/candidateAssessment";
import {sortArrayByNestedKey} from "helpers/array";
import {customSelectionOption} from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/types";
import {isNumber} from "lodash";
import {updateProjectSuccessChecklistItem} from "store/mapx/project/projectSuccessChecklistAsyncActions";
import {projectSuccessChecklistOptionsSelector} from "store/mapx/project/projectSelectors";
import {TProjectSuccessCheckOption} from "mapx-components/Layouts/ProjectChecklist/types";

const cancelTokens: {[key: string]: CancelTokenSource} = {};

/**
 * Toggles the selection of a candidate for assessment in the current project.
 *
 * @param {number} candidateId The ID of the candidate to toggle selection for.
 * @returns A thunk that toggles the selection of the candidate.
 */

export const handleCandidateSelectionForAssessment =
	(candidateId: number) => (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const project: STProject = projectSelector(state);

			const isCandidateSelected = isCandidateSelectedForAssessmentSelector(
				state,
				project.id,
				candidateId,
			);

			const selectedCandidateIdsForAssessment: number[] =
				selectedCandidateIdsForAssessmentSelector(state);

			if (isCandidateSelected) {
				dispatch(
					setCandidateIdsForAssessment(
						selectedCandidateIdsForAssessment.filter((id) => id !== candidateId),
					),
				);
			} else {
				dispatch(
					setCandidateIdsForAssessment([
						...selectedCandidateIdsForAssessment,
						candidateId,
					]),
				);
			}
		} catch (e) {
			console.log(`Error from handleCandidateSelectionForAssessment: ${e}`);
		}
	};

/**
 * @function handleAllCandidateSelectionForAssessment
 * @description Handles the selection or deselection of all candidates in the target list.
 * @param {string} currentSelectedType A string indicating the type of selection to perform.
 *        The value can be one of the following:
 *        - "all": selects all candidates in the target list
 *        - "partial": selects only the candidates that match the current filter settings
 *        - "none": deselects all candidates in the target list
 * @returns A dispatch function that can be used to dispatch actions to the store
 */

export const handleAllCandidateSelectionForAssessment =
	(currentSelectedType: "all" | "partial" | "none") =>
	(dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const selectedFilters = additionalProfileFiltersSelector(state);

			const hasFilters = !!buildCandidateApiRequestPayload(selectedFilters, false);

			const targetListCandidates = targetListCandidatesSelector(state);

			const filteredCandidates = filteredSavedCandidatesSelector(state);

			const candidates = hasFilters ? filteredCandidates : targetListCandidates;

			let updatedSelectedIds = [];

			if (currentSelectedType === "none" || currentSelectedType === "partial") {
				updatedSelectedIds = candidates.map((candidate: SICandidate) => candidate.id);
			} else {
				updatedSelectedIds = [];
			}

			dispatch(setCandidateIdsForAssessment(updatedSelectedIds));
		} catch (e) {
			console.log(`Error from handleAllCandidateSelectionForAssessment: ${e}`);
		}
	};

export const handleCustomCandidateSelectionForAction =
	(option: customSelectionOption) => (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const selectedFilters = additionalProfileFiltersSelector(state);

			const hasFilters = !!buildCandidateApiRequestPayload(selectedFilters, false);

			const targetListCandidates = targetListCandidatesSelector(state);

			const filteredCandidates = filteredSavedCandidatesSelector(state);

			const candidates = hasFilters ? filteredCandidates : targetListCandidates;

			const currentlySelectedIds = selectedCandidateIdsForAssessmentSelector(state);

			let selectedIds = [];

			if (option.value === "select_all") {
				selectedIds = candidates.map((candidate: SICandidate) => candidate.id);
			} else if (option.value === "deselect_all") {
				selectedIds = [];
			} else if (option.value === "select_recommended") {
				selectedIds = candidates
					.filter((candidate: SICandidate) => candidate.is_recommended)
					.map((candidate: SICandidate) => candidate.id);
			} else if (option.value === "invert_selection") {
				selectedIds = candidates
					.filter(
						(candidate: SICandidate) => !currentlySelectedIds.includes(candidate.id),
					)
					.map((candidate: SICandidate) => candidate.id);
			} else if (option.value === "scores_100") {
				selectedIds = candidates
					.filter(
						(candidate: SICandidate) =>
							candidate?.fit_to_brief && candidate?.fit_to_brief?.score === 100,
					)
					.map((candidate: SICandidate) => candidate.id);
			} else if (option.value === "scores_over_75") {
				selectedIds = candidates
					.filter(
						(candidate: SICandidate) =>
							candidate?.fit_to_brief && candidate?.fit_to_brief?.score > 75,
					)
					.map((candidate: SICandidate) => candidate.id);
			} else if (option.value === "scores_over_50") {
				selectedIds = candidates
					.filter(
						(candidate: SICandidate) =>
							candidate?.fit_to_brief && candidate?.fit_to_brief?.score > 50,
					)
					.map((candidate: SICandidate) => candidate.id);
			} else if (option.value === "scores_over_25") {
				selectedIds = candidates
					.filter(
						(candidate: SICandidate) =>
							candidate?.fit_to_brief && candidate?.fit_to_brief?.score > 25,
					)
					.map((candidate: SICandidate) => candidate.id);
			} else if (option.value === "scores_below_75") {
				selectedIds = candidates
					.filter(
						(candidate: SICandidate) =>
							candidate?.fit_to_brief && candidate?.fit_to_brief?.score < 75,
					)
					.map((candidate: SICandidate) => candidate.id);
			} else if (option.value === "scores_below_50") {
				selectedIds = candidates
					.filter(
						(candidate: SICandidate) =>
							candidate?.fit_to_brief && candidate?.fit_to_brief?.score < 50,
					)
					.map((candidate: SICandidate) => candidate.id);
			} else if (option.value === "scores_below_25") {
				selectedIds = candidates
					.filter(
						(candidate: SICandidate) =>
							candidate?.fit_to_brief && candidate?.fit_to_brief?.score < 25,
					)
					.map((candidate: SICandidate) => candidate.id);
			} else if (option.value === "not_scored") {
				selectedIds = candidates
					.filter(
						(candidate: SICandidate) =>
							candidate?.fit_to_brief && candidate?.fit_to_brief?.score === undefined,
					)
					.map((candidate: SICandidate) => candidate.id);
			} else if (isNumber(option.value)) {
				selectedIds = candidates
					.filter((candidate: SICandidate) => candidate?.progress_status === option.name)
					.map((candidate: SICandidate) => candidate.id);
			} else if (option.value === null) {
				selectedIds = candidates
					.filter((candidate: SICandidate) => candidate?.progress_status === null)
					.map((candidate: SICandidate) => candidate.id);
			}

			dispatch(setCandidateIdsForAssessment(selectedIds));
		} catch (e) {
			console.log(`Error from handleCustomCandidateSelectionForAction: ${e}`);
		}
	};

/**
 * @function getProjectAllCriteriaForAssessment
 * @description Retrieves a list of project criterion that can be used for assessment.
 * @returns A promise that resolves to an array of project criterion. If the request fails,
 *          an empty array is returned.
 */

export const getProjectAllCriteriaForAssessment =
	() => async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const project: STProject = projectSelector(state);

			const response: AxiosResponse = await candidateAssessment.getProjectBriefCriteria(
				project.id,
			);

			if (successResponse(response, 200)) {
				dispatch(setCriteriaForProjectCandidateAssessment(response.data.results));

				return response.data;
			} else {
				return [];
			}
		} catch (e) {
			console.log(`Error from getAllProjectCriteriaForAssessment: ${e}`);
		}
	};

/**
 * @function handleCriteriaSaveForAssessment
 * @description Handles the saving of assessment criterion for a project. If the criterion
 *              already exists (has an ID), it updates the existing criterion. Otherwise,
 *              it creates a new criterion.
 * @param {STFitToBriefCriteria} criteria - The criterion object containing details such as
 *        criterion string, description, priority, and an optional ID. If ID is present,
 *        the criterion is updated; otherwise, a new criterion is created.
 * @returns A promise that resolves to "success" if the operation is
 *          successful, or "error" if it fails.
 * @throws Will log an error message if an exception occurs during the process.
 */

export const handleCriteriaSaveForAssessment =
	(criteria: STFitToBriefCriteria) => async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const project: STProject = projectSelector(state);

			const briefCriteriaData: STFitToBriefCriteria[] =
				briefCriteriaProjectAssessmentSelector(state);

			let response;

			if (criteria?.id) {
				const formBody = {...criteria};
				delete formBody.id;

				response = await candidateAssessment.updateProjectBriefCriteria(
					project.id,
					criteria.id,
					formBody,
				);
			} else {
				response = await candidateAssessment.createProjectBriefCriteria(
					project.id,
					criteria,
				);
			}

			if (successResponse(response, 200) || successResponse(response, 201)) {
				if (criteria?.id) {
					const data = response.data;

					dispatch(
						setCriteriaForProjectCandidateAssessment([
							...briefCriteriaData.map((item: STFitToBriefCriteria) => {
								if (item.id === criteria.id) {
									return data;
								} else {
									return item;
								}
							}),
						]),
					);
				} else {
					dispatch(
						setCriteriaForProjectCandidateAssessment([
							...briefCriteriaData,
							response.data,
						]),
					);
				}

				return "success";
			} else {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return response?.data?.message || "error";
			}
		} catch (e) {
			console.log(`Error from handleCriteriaSaveForAssessment: ${e}`);

			return "error";
		}
	};

/**
 * @function handleUpdateAssessmentForProjectCandidate
 * @description Handles the update of a candidate's assessment data.
 * @returns A promise that resolves to "success" if the operation is
 *          successful, or "error" if it fails.
 * @throws Will log an error message if an exception occurs during the process.
 * @param candidateID
 * @param fitToBriefId
 * @param verdict
 * @param assessment
 */

export const handleUpdateAssessmentForProjectCandidate =
	(candidateID: number, fitToBriefId: number, verdict: string, assessment: string) =>
	async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const requestKey = `UPDATE_CANDIDATE_FIT_TO_BRIEF_INFO_FOR_PROJECT`;

			if (requestKey in cancelTokens) {
				cancelTokens[requestKey].cancel("Operation canceled due to new request.");
			}

			cancelTokens[requestKey] = axios.CancelToken.source();

			const config = {
				cancelToken: cancelTokens[requestKey].token,
			};

			const state = getState();

			const project: STProject = projectSelector(state);

			const formBody = {
				verdict,
				assessment,
			};

			const updateResponse = await candidateAssessment.updateCandidateProjectBriefCriteria(
				project.id,
				candidateID,
				fitToBriefId,
				formBody,
				config,
			);

			if (candidateID) {
				await dispatch(updateCandidatesInState([candidateID]));
			}

			return updateResponse;
		} catch (e) {
			console.log(`Error from handleUpdateAssessmentForProjectCandidate: ${e}`);
		}
	};
/**
 * @function handleCriteriaDeleteForAssessment
 * @description Handles the deletion of a project's assessment criterion.
 * @param {number} criteriaId - The ID of the criterion to be deleted.
 * @returns A promise that resolves to "success" if the operation is
 *          successful, or "error" if it fails.
 * @throws Will log an error message if an exception occurs during the process.
 */

export const handleCriteriaDeleteForAssessment =
	(criteriaId: number) => async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const project: STProject = projectSelector(state);

			const briefCriteriaData: STFitToBriefCriteria[] =
				briefCriteriaProjectAssessmentSelector(state);

			const response = await candidateAssessment.deleteProjectBriefCriteria(
				project.id,
				criteriaId,
			);

			if (successResponse(response, 204)) {
				dispatch(
					setCriteriaForProjectCandidateAssessment([
						...briefCriteriaData.filter(
							(item: STFitToBriefCriteria) => item.id !== criteriaId,
						),
					]),
				);

				return "success";
			} else {
				return "error";
			}
		} catch (e) {
			console.log(`Error from handleCriteriaDeleteForAssessment: ${e}`);
		}
	};

/**
 * This function creates a background task for candidate scoring for a project.
 *
 * It gets the selected candidate ids for assessment and creates a background task
 * with the project id and the selected candidate ids. It then dispatches an action
 * to set the project candidate assessment process with the background task.
 *
 * If the background task creation is successful, it also tracks an event with mixpanel.
 *
 * @returns A boolean indicating whether the background task creation was successful or not.
 */

export const handleCandidateScoringBackgroundProcess =
	() => async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const userEmail = userEmailSelector(state);

			const project: STProject = projectSelector(state);

			const selectedCandidateIds = selectedCandidateIdsForAssessmentSelector(state);

			const formBody: TBackgroundTaskCreate = {
				type: "Candidate Assessment",
				project_id: project?.id,
				candidate_ids: selectedCandidateIds,
			};

			dispatch(projectCandidateAssessmentProcessRequestCreateInProgress(true));

			const response = await mapXBackgroundTaskAPI.createBackgroundTaskRequest(formBody);

			if (successResponse(response, 201)) {
				const backgroundTask: STBackgroundTaskResponse = response.data;

				dispatch(setProjectCandidateAssessmentProcess(backgroundTask));

				const checkOptions: TProjectSuccessCheckOption[] =
					projectSuccessChecklistOptionsSelector(state);

				const scoreNRankObj = checkOptions?.find(
					(item: TProjectSuccessCheckOption) => item.title === "Score & Rank",
				);

				if (scoreNRankObj && !scoreNRankObj?.checked) {
					dispatch(
						updateProjectSuccessChecklistItem({
							attribute: "has_viewed_insights",
							value: true,
						}),
					);
				}

				try {
					response.data.linkedin_candidate_urls.map((url: string) => {
						Mixpanel.track(`Candidate Scoring For Project`, {
							name: `${project.name}: Candidate Scoring`,
							pageTitle: `${project.name}`,
							url: url,
							distinct_id: userEmail,
						});
					});
				} catch (e) {
					console.log(e);
				}

				return true;
			}

			return false;
		} catch (e) {
			console.log(`Error from handleCandidateScoringBackgroundProcess: ${e}`);

			return false;
		}
	};

/**
 * Checks the status of a candidate assessment background task.
 *
 * @param {number} backgroundTaskId the id of the background task to check
 * @returns a promise that resolves to the background task info if the task is completed, or null if the task is still in progress
 */

export const checkCandidateAssessmentStatus =
	(backgroundTaskId: number) => async (dispatch: TAppDispatch, getState: TRootState) => {
		const requestKey = `GET_CANDIDATE_ASSESSMENT_STATUS`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		try {
			const state = getState();

			const data = (await dispatch(
				getBackgroundTaskInfoById(backgroundTaskId, config),
			)) as unknown as Nullable<STBackgroundTaskResponse>;

			if (data !== null) {
				const currentBackgroundTask: STBackgroundTaskResponse = data;

				dispatch(setProjectCandidateAssessmentProcess(currentBackgroundTask));

				const candidateIds = currentBackgroundTask.candidate_assessments
					.filter((a) => a.is_assessed)
					.map((a) => a.candidate_id);

				if (candidateIds.length === 0) {
					// await dispatch(updateCandidatesInState(candidateIds, config));
					const savedPeoplesInState = targetListCandidatesSelector(state);
					const filteredSavedPeoplesInState = filteredSavedCandidatesSelector(state);

					const updatedPeopleForState = sortArrayByNestedKey(
						savedPeoplesInState,
						"fit_to_brief.score",
					);
					const updatedFilteredPeopleForState = sortArrayByNestedKey(
						filteredSavedPeoplesInState,
						"fit_to_brief.score",
					);

					dispatch(updateTargetListCandidatesWithSummary(updatedPeopleForState));
					dispatch(
						updateFilteredTargetListCandidatesWithSummary(
							updatedFilteredPeopleForState,
						),
					);
				}

				// if (currentBackgroundTask.status === "Completed") {
				//     dispatch(getTargetListCandidates());
				// }

				return data;
			}
		} catch (e) {
			console.error(e);
		}
	};

const updateCandidatesInState =
	(candidateIds: number[], config?: TRootState) =>
	async (dispatch: TAppDispatch, getState: TRootState) => {
		const state = getState();
		const targetListId = targetListIDSelector(state);

		const apiPayload: ICandidateByIdsApiRequestPayload = {
			filters: {
				candidates: candidateIds,
				target_list: targetListId,
			},
		};

		try {
			const response = await mapXCandidateApi.getCandidatesByIds(apiPayload, config);

			if (successResponse(response, 200)) {
				const results = response.data.results;

				const savedPeoplesInState = targetListCandidatesSelector(state);
				const filteredSavedPeoplesInState = filteredSavedCandidatesSelector(state);

				const updatedPeopleForState: SICandidate[] = [];
				const updatedFilteredPeopleForState: SICandidate[] = [];

				savedPeoplesInState.forEach((candidate: SICandidate) => {
					const isCandidateInList = candidateIds.includes(candidate.id);
					const candidateFromResults = results.find(
						(c: SICandidate) => c.id === candidate.id,
					);
					const updatedCandidate = candidateFromResults ?? candidate;

					if (
						isCandidateInList &&
						updatedCandidate?.fit_to_brief &&
						updatedCandidate?.fit_to_brief?.assessments?.length > 0
					) {
						dispatch(updateCandidateProfileAfterRefresh(updatedCandidate));
						candidateIds = candidateIds.filter((id) => id !== candidate.id);
					}

					updatedPeopleForState.push(updatedCandidate);

					if (
						filteredSavedPeoplesInState.some((c: SICandidate) => c.id === candidate.id)
					) {
						updatedFilteredPeopleForState.push(updatedCandidate);
					}
				});

				const currentSortOrder = apCandidateOrderingSelector(state);

				if (currentSortOrder === "-fit_to_brief_score") {
					sortArrayByNestedKey(updatedPeopleForState, "fit_to_brief.score");
					sortArrayByNestedKey(updatedFilteredPeopleForState, "fit_to_brief.score");
				}

				dispatch(updateTargetListCandidatesWithSummary(updatedPeopleForState));
				dispatch(
					updateFilteredTargetListCandidatesWithSummary(updatedFilteredPeopleForState),
				);
			}
		} catch (error) {
			console.error("Error updating candidates in state:", error);
		}
	};

export const sortProjectCandidatesListByScore =
	() => (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const filteredSavedPeoplesInState = filteredSavedCandidatesSelector(state);

			const updatedFilteredPeopleForState = sortArrayByNestedKey(
				filteredSavedPeoplesInState,
				"fit_to_brief.score",
			);

			dispatch(updateFilteredTargetListCandidatesWithSummary(updatedFilteredPeopleForState));
		} catch (error) {
			console.error("Error updating candidates in state:", error);
		}
	};
