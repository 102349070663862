import {useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {debounce} from "lodash";

import {ContentCopyIcon, PencilIcon, RecycleBinPermanent} from "assets/icons";
import {useAppDispatch, useAppSelector} from "hooks";
import {customConfirmAlert} from "helpers";
import {EMPTY_COMPANY_LOGO} from "mapx-constants";
import {deleteMarketMap, getMarketMaps} from "store/mapx/market-map/marketMapAsyncFunctions";
import {
	deleteMarketMapInProgressSelector,
	duplicateMarketMapModalSelector,
	editMarketMapModalSelector,
	getMarketMapsInProgressSelector,
	marketMapCreatedBySelector,
	marketMapNameSelector,
	marketMapsPaginationSelector,
	marketMapsSelector,
} from "store/mapx/market-map/marketMapSelectors";
import MarketMapModal from "mapx-components/Modals/MarketMapModal";
import EditMarketMapModal from "mapx-components/Modals/EditMarketMapModal";
import DuplicateMarketMapModal from "mapx-components/Modals/DuplicateMarketMapModal";
import {
	duplicateMarketMapModal,
	editMarketMapModal,
	setMarketMapPaginationInfo,
} from "store/mapx/market-map/marketMapActions";
import type {STMarketMap} from "api/marketMapApi/types";
import type {STCompany} from "api/companyApi/types";

import css from "./List.module.scss";
import Options from "./options";
import Loader from "./loader";
import type {TList} from "./types";
import Pagination from "mapx-components/Pagination";
import {GET_MARKET_MAPS_TOKEN} from "api/requestCancelTokenStrings";
import {cancelRequest} from "api/cancelTokens";

const List = ({search, createdBy}: TList) => {
	const dispatch = useAppDispatch();

	const user = useAppSelector((state) => state.user.user);
	const marketMaps = useAppSelector(marketMapsSelector);
	const pagination = useAppSelector(marketMapsPaginationSelector);
	const getting = useAppSelector(getMarketMapsInProgressSelector);
	const duplicateMarketMapModalOpen = useSelector(duplicateMarketMapModalSelector);
	const editMarketMapModalOpen = useSelector(editMarketMapModalSelector);
	const deletingMarketMaps = useSelector(deleteMarketMapInProgressSelector);
	const prevSearch = useSelector(marketMapNameSelector);
	const prevCreatedBy = useSelector(marketMapCreatedBySelector);

	const [modalDisplay, setModalDisplay] = useState(false);
	const [modalProps, setModalProps] = useState<STMarketMap | null>(null);
	const [editModalProps, setEditModalProps] = useState<STMarketMap | null>(null);
	const [duplicateModalProps, setDuplicateModalProps] = useState<STMarketMap | null>(null);

	const createdByChanged = useMemo(
		() =>
			JSON.stringify(createdBy.map((cb) => cb.value).sort()) !==
			JSON.stringify(prevCreatedBy.sort()),
		[createdBy, prevCreatedBy],
	);

	const debouncedGetMarketMaps = useMemo(
		() =>
			debounce((search: string, createdBy: ("organisation" | "self" | "mapx")[]) => {
				dispatch(getMarketMaps(search, createdBy, pagination?.page, pagination?.per_page));
			}, 300),
		[dispatch, pagination?.page, pagination?.per_page],
	);

	const handleEditModalDisplay = useCallback(
		(open: boolean) => {
			dispatch(editMarketMapModal(open));
		},
		[dispatch],
	);

	const handleDuplicateModalDisplay = useCallback(
		(open: boolean) => {
			dispatch(duplicateMarketMapModal(open));
		},
		[dispatch],
	);

	const onPageChange = async (newPage: number) => {
		dispatch(setMarketMapPaginationInfo({...pagination, page: newPage}));
	};

	const onPerPageChange = async (count: number) => {
		dispatch(setMarketMapPaginationInfo({...pagination, per_page: count}));
	};

	useEffect(() => {
		if (search || createdByChanged) {
			if (pagination?.page !== 1) {
				dispatch(setMarketMapPaginationInfo({...pagination, page: 1}));
			}
		}
	}, [search, createdByChanged, pagination, dispatch]);

	useEffect(() => {
		const createdByValues = createdBy.map((c) => c.value as "organisation" | "self" | "mapx");

		debouncedGetMarketMaps(search, createdByValues);

		return () => {
			cancelRequest(GET_MARKET_MAPS_TOKEN);
		};
	}, [search, createdBy, debouncedGetMarketMaps]);

	useEffect(() => {
		return () => {
			dispatch(setMarketMapPaginationInfo({page: 1, per_page: 20}));
		};
	}, [dispatch]);

	const handleTitleClick = (map: STMarketMap) => {
		setModalProps(map);
		setModalDisplay(true);
	};

	const handleDeleteClick = useCallback(
		(id: number) => {
			customConfirmAlert({
				yesButtonLabel: "Yes",
				noButtonLabel: "No",
				title: "Target List Deletion Warning!",
				message: `You may have saved companies in the target list which will be lost once you remove this target list. Are you sure about deleting the target list?`,
				handlePressYes: () => {
					dispatch(deleteMarketMap(id));
				},
			});
		},
		[dispatch],
	);

	return (
		<>
			{(marketMaps.length === 0 && getting) ||
			(getting && prevSearch !== search) ||
			(getting && createdByChanged) ? (
				<Loader />
			) : (
				<div className={css.list}>
					{marketMaps.length === 0 && search && (
						<div className={css.empty}>
							{'No results found for "'}
							{<strong>{search}</strong>}
							{'".'}
						</div>
					)}
					{!getting &&
						marketMaps.map((map: STMarketMap) => (
							<div key={map.id} className={css.map}>
								<div className={css.interaction}>
									<div className={css.name} onClick={() => handleTitleClick(map)}>
										{map.name}
									</div>
									{(user.isSuperUser || !map.is_global) && (
										<Options
											loading={deletingMarketMaps.includes(map.id)}
											options={[
												{
													name: "Edit Target List",
													action: () => {
														setEditModalProps(map);
														handleEditModalDisplay(true);
														setModalDisplay(false);
													},
													icon: <PencilIcon />,
												},
												{
													name: "Duplicate Target List",
													action: () => {
														setDuplicateModalProps(map);
														handleDuplicateModalDisplay(true);
														setModalDisplay(false);
													},
													icon: <ContentCopyIcon />,
												},
												{
													name: "Delete Target List",
													action: () => handleDeleteClick(map.id),
													icon: <RecycleBinPermanent />,
													color: "#F20D0D",
												},
											]}
										/>
									)}
								</div>
								<div className={css.description}>{map.description}</div>
								<div className={css.companies}>
									{map.companies.slice(0, 6).map((company: STCompany) => (
										<div
											key={company.id.toString()}
											className={css.companyImageWrapper}
										>
											<img
												loading="lazy"
												alt={company.name}
												className={css.companyImage}
												src={company?.logo_url || EMPTY_COMPANY_LOGO}
												onError={(e) => {
													(e.target as HTMLImageElement).src =
														EMPTY_COMPANY_LOGO;
												}}
											/>
										</div>
									))}
									{map.companies.length > 6 && (
										<div className={css.companyImageWrapper}>
											<div className={css.companyImage}>
												{map.companies.length}
											</div>
										</div>
									)}
									<div className={css.createdBy}>
										Created by{" "}
										{map.is_global
											? "MapX"
											: map.owner_id === user.user_id
											? "You"
											: "Your Organisation"}
									</div>
								</div>
							</div>
						))}

					{!getting && pagination && pagination?.count >= 10 && (
						<div className={css.paginationWrapper}>
							<Pagination
								currentPage={pagination?.page}
								resultsCount={pagination?.count}
								totalPages={pagination?.pages}
								perPage={pagination?.per_page}
								onPageChange={onPageChange}
								onPerPageChange={onPerPageChange}
							/>
						</div>
					)}

					{getting && marketMaps.length > 0 && <Loader count={3} />}
				</div>
			)}
			<MarketMapModal
				modalProps={modalProps}
				modalDisplay={modalDisplay}
				setModalDisplay={setModalDisplay}
			/>
			{editModalProps && editMarketMapModalOpen && (
				<EditMarketMapModal
					modalProps={editModalProps}
					modalDisplay={editMarketMapModalOpen}
					setModalDisplay={handleEditModalDisplay}
				/>
			)}
			{duplicateModalProps && duplicateMarketMapModalOpen && (
				<DuplicateMarketMapModal
					modalProps={duplicateModalProps}
					modalDisplay={duplicateMarketMapModalOpen}
					setModalDisplay={handleDuplicateModalDisplay}
				/>
			)}
		</>
	);
};

export default List;
