import React, {useRef, useState} from "react";
import {BiDotsVerticalRounded} from "react-icons/bi";
import styles from "./MarketReportCard.module.scss";
import colors from "styles/themes.module.scss";
import {Button, Loader} from "components";
import useOnClickOutside from "hooks/useOnClickOutside";
import customConfirmAlert from "helpers/customConfirmAlert";
import {FaCaretRight} from "react-icons/fa";
import {TMarketMapItem} from "mapx-pages/MarketReports/types";

export default function MarketReportCard(props: TMarketMapItem) {
	const [activeMenu, setActiveMenu] = useState(false);

	// @TODO use actual deleting state
	const loading = false;

	const handleItemClick = () => {
		console.log("selected item object: ", props);
	};

	const handleEditClick = () => {
		console.log("item to edit: ", props);
		setActiveMenu(false);
	};

	const handleDeleteClick = () => {
		customConfirmAlert({
			yesButtonLabel: "Yes",
			noButtonLabel: "No",
			title: "Market Reports Deletion Warning!",
			message: `Are you sure about deleting the market report?`,
			handlePressYes: () => {
				setActiveMenu(false);
				console.log("id to delete: ", props.id);
				// @TODO Delete actions
			},
			afterClose: () => {
				setActiveMenu(false);
			},
		});
	};

	const toggleMenu = () => {
		setActiveMenu(!activeMenu);
	};

	const optionsRef = useRef(null);

	useOnClickOutside(optionsRef, () => setActiveMenu(false));

	return (
		<div className={styles.list}>
			<span className={styles.content}>
				<div className={styles.textContainer}>
					<div className={styles.listInfo}>
						<h2 onClick={handleItemClick}>{props?.title ?? "Unnamed Market Report"}</h2>
						<div className={styles.statsContainer}>
							<section>
								<div className={styles.stats}>
									<div className={styles.statSubtitle}>
										Chief Technology Officer, United States
									</div>
								</div>
								<div className={styles.statInfo}>
									<span>
										Estimated Market:{" "}
										<span className={styles.count}>{props.count1}</span>
									</span>
									<span>
										Market Criteria:{" "}
										<span className={styles.count}>{props.count2}</span>
									</span>
								</div>
							</section>

							<div className={styles.marketMapButtons}>
								<Button
									className={styles.openReportButton}
									onClick={handleItemClick}
								>
									<span>View Report</span>{" "}
									<FaCaretRight color={"#0C5850"} size="15px" />
								</Button>
							</div>
						</div>
					</div>

					<div ref={optionsRef}>
						<span className={activeMenu ? styles.active : styles.hidden}>
							<div className={styles.marketMapMenu}>
								<div className={styles.menuItem} onClick={handleEditClick}>
									Edit Report
								</div>

								<div className={styles.menuItem} onClick={handleDeleteClick}>
									Remove Report
								</div>
							</div>
						</span>

						{loading ? (
							<Loader
								height={16}
								width={16}
								type="ThreeDots"
								color={colors.loaderDotColor}
							/>
						) : (
							<BiDotsVerticalRounded
								size={24}
								className={styles.menuDots}
								onClick={toggleMenu}
							/>
						)}
					</div>
				</div>
			</span>
		</div>
	);
}
