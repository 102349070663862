import Pusher, {Channel} from "pusher-js";
import {STPusherCredentials} from "web-socket/types";

let pusherInstance: Nullable<Pusher>;

const activeChannels: Map<string, Channel> = new Map();

Pusher.logToConsole = process.env?.REACT_APP_PUSHER_LOG === "true";

export const initializePusher = (credentials: STPusherCredentials): void => {
	if (pusherInstance) return; // Avoid reinitializing

	pusherInstance = new Pusher(credentials.appKey, {
		cluster: "eu",
		forceTLS: true,
	});
};

export const subscribeToChannel = <T>(
	channelName: string,
	eventName: string,
	callback: (data: T) => void,
): Channel => {
	if (!pusherInstance) {
		throw new Error("Pusher is not initialized. Call initializePusher first.");
	}

	// Check if the channel is already subscribed
	let channel = activeChannels.get(channelName);

	if (!channel) {
		// Subscribe only if the channel is not already active
		channel = pusherInstance.subscribe(channelName);
		activeChannels.set(channelName, channel);
	}

	// Bind the event to the channel
	channel.bind(eventName, callback);

	return channel;
};

export const unsubscribeFromChannel = (channelName: string, eventName: string): void => {
	if (!pusherInstance) {
		throw new Error("Pusher is not initialized. Call initializePusher first.");
	}

	const channel: Channel = pusherInstance.channel(channelName);

	if (channel) {
		channel.unbind(eventName); // Unbind the specific event
		if (Object.keys(channel.callbacks).length === 0) {
			pusherInstance.unsubscribe(channelName); // Unsubscribe if no more events
		}
	}
};

export const disconnectPusher = (): void => {
	if (pusherInstance) {
		pusherInstance.disconnect();
		pusherInstance = null;
	}
};
