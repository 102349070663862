import {
	editProjectModal,
	saveProjectViaModalDone,
	saveProjectViaModalFail,
	saveProjectViaModalInit,
	updateProjectDone,
	updateProjectFail,
	updateProjectInit,
} from "store/mapx/project-list/projectListActions";
import {userEmailSelector, userSelector} from "store/mapx/user/userSelectors";
import mapxTargetListApi from "api/targetListApi";
import mapxProjectApi from "api/projectApi";
import {Mixpanel} from "helpers/mixpanel";
import {setProjectAsync} from "store/mapx/project-list/projectListAsyncActions";
import {TAppDispatch, TRootState} from "types";
import customConfirmAlert from "helpers/customConfirmAlert";
import {STProject, STProjectForm, TScratchProjectForm} from "api/projectApi/types";
import {
	detachedTargetListSelector,
	targetCandidatesPaginationSelector,
	targetCompaniesPaginationSelector,
	targetListCompaniesSelector,
} from "store/mapx/target-list/targetListSelectors";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {getTargetListCompanies} from "store/mapx/target-list/targetListAsyncActions";
import {getTargetListCandidates} from "store/mapx/target-list/targetListCandidatesAsyncActions";
import {successResponse} from "helpers/map";
import {TProjectFormData} from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/ProjectForm/types";
import {STUser} from "api/organisationApi/types";
import {toast} from "react-toastify";
import {ToastContent} from "components";
import {TProjectForm} from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/types";

export const mergeTargetlist =
	(newProject: STProject) => async (dispatch: TAppDispatch, getState: TRootState) => {
		const state = getState();

		let detachedTargetList = detachedTargetListSelector(state);

		if (!detachedTargetList) {
			const {data} = await mapxTargetListApi.createDetachedTargetList();

			detachedTargetList = data;
		}

		const selectedProjectInState = projectSelector(state);
		const companies = targetListCompaniesSelector(state);
		const companiesPagination = targetCompaniesPaginationSelector(state);
		const candidatesPagination = targetCandidatesPaginationSelector(state);

		if (
			!selectedProjectInState &&
			(companiesPagination?.count > 0 || candidatesPagination?.count > 0)
		) {
			customConfirmAlert({
				yesButtonLabel: "Yes",
				noButtonLabel: "No",
				title: "Transfer Target List?",
				message: `Would you like to transfer the saved companies and people to this new project?`,
				handlePressYes: async () => {
					try {
						if (typeof newProject?.target_list_id === "number") {
							await mapxTargetListApi.mergeTargetLists(
								detachedTargetList.id,
								newProject.target_list_id,
							);

							newProject.companies = companies;
							newProject.companies_number = companiesPagination.count;
							newProject.candidates_number = candidatesPagination.count;

							if (companiesPagination.count) {
								dispatch(getTargetListCompanies());
							}

							if (candidatesPagination.count) {
								dispatch(getTargetListCandidates());
							}
						}

						return newProject;
					} catch (error) {
						console.log(error);
					}
				},
				afterClose: () => {
					return newProject;
				},
			});
		}

		return newProject;
	};

export const saveProjectForAI =
	(formData: TProjectForm) => async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			dispatch(saveProjectViaModalInit());

			const userEmail = userEmailSelector(state);

			const response = await mapxProjectApi.saveProject(formData);

			if (successResponse(response, 201)) {
				const newProject = response.data;

				dispatch(saveProjectViaModalDone(newProject));

				dispatch(setProjectAsync(newProject));

				Mixpanel.track(`Project Created`, {
					name: `${newProject.name}`,
					pageTitle: `Project Create Modal`,
					url: window.location.pathname,
					distinct_id: userEmail,
				});

				return newProject;
			} else {
				return null;
			}
		} catch (error) {
			dispatch(saveProjectViaModalFail(error));

			return null;
		}
	};

export const saveProjectFromScratch =
	(formData: TScratchProjectForm) => async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			dispatch(saveProjectViaModalInit());

			const userEmail = userEmailSelector(state);

			const response = await mapxProjectApi.saveProject(formData);

			if (successResponse(response, 201)) {
				const newProject = response.data;

				if (!newProject.target_list_id) {
					const {data: newTargetList} = await mapxTargetListApi.createTargetList({
						project_id: newProject.id,
					});
					newProject.target_list_id = newTargetList.id;
				}

				const newProjectWithData = await dispatch(mergeTargetlist(newProject));

				dispatch(saveProjectViaModalDone(newProjectWithData));

				dispatch(setProjectAsync(newProjectWithData));

				Mixpanel.track(`Project Created`, {
					name: `${newProject.name}`,
					pageTitle: `Project Create Modal`,
					url: window.location.pathname,
					distinct_id: userEmail,
				});

				return newProject;
			} else {
				return null;
			}
		} catch (error) {
			dispatch(saveProjectViaModalFail(error));

			return null;
		}
	};

export const updateProject =
	(projectID: number, formData: TProjectFormData, displayNotification = true) =>
	async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const user = userSelector(state);

			dispatch(updateProjectInit());

			const updatedForm: STProjectForm = {};

			if (formData.name) {
				updatedForm["name"] = formData.name;
			}

			if (formData.users && formData.users?.length > 0) {
				updatedForm["user_ids"] = [
					...formData.users.map((item: STUser) => Number(item.id)),
					user.user_id,
				];
			}

			if (formData.location) {
				updatedForm["location"] = formData.location.id as string;
			}

			if (formData.company !== undefined && formData.company !== null) {
				updatedForm["company_id"] = formData.company.id as number;
			} else if (formData.company !== undefined && formData.company === null) {
				updatedForm["company_id"] = null;
			}

			if (formData.target_job_title) {
				updatedForm["target_job_title"] = formData.target_job_title;
			}

			const response = await mapxProjectApi.updateProject(projectID, updatedForm);

			if (successResponse(response, 200)) {
				const project = response.data as STProject;

				dispatch(updateProjectDone(project));

				if (displayNotification) {
					toast.success(ToastContent, {
						autoClose: 5000,
						closeOnClick: true,
						data: {
							title: "Project updated!",
							description: "Project changes saved successfully.",
						},
					});
				}

				dispatch(editProjectModal(false));
			}
		} catch (error) {
			dispatch(updateProjectFail(error));

			return {error};
		}
	};
