import React, {useCallback, useEffect, useMemo, useState} from "react";

import styles from "./styles.module.scss";
import {Dropdown, Loader, UpgradeNowTooltip} from "components";
import {ArrowDown, LockIcon, PlusIcon} from "assets/icons";
import {isStarterTypeUserSelector} from "store/mapx/user/userSelectors";
import {useAppDispatch, useAppSelector} from "hooks";
import SpreadSheetIcon from "mapx-pages/Project/Share/Icons/spreadsheetIcon";
import ProjectCollaboration from "mapx-pages/Project/Share/ProjectCollaboration";
import {GeneralButton} from "mapx-components";
import PowerpointIcon from "mapx-pages/Project/Share/Icons/powerpointIcon";
import classNames from "classnames";
import ProjectReportTable from "mapx-pages/Project/Share/ProjectReportTable";
import {
	addNewRowOnProjectReportConfigTable,
	downloadProjectReportAsPowerpoint,
	downloadProjectReportAsSpreadsheet,
	getPresentationAvailableTemplates,
} from "store/mapx/project/projectReportDownloadAsyncActions";
import {SICandidate} from "api/candidateApi/types";
import {targetListCandidatesSelector} from "store/mapx/target-list/targetListSelectors";
import {
	presentationAvailableTemplatesSelector,
	projectSuccessChecklistOptionsSelector,
	selectedPresentationTemplateSelector,
} from "store/mapx/project/projectSelectors";
import {TPresentationAvailableTemplate} from "api/projectApi/types";
import {setSelectedPresentationTemplate} from "store/mapx/project/projectActions";
import {templateOption} from "mapx-pages/Project/Share/ProjectReportTable/types";
import {TProjectSuccessCheckOption} from "mapx-components/Layouts/ProjectChecklist/types";
import {updateProjectSuccessChecklistItem} from "store/mapx/project/projectSuccessChecklistAsyncActions";

const DEFAULT_TEMPLATE_OPTIONS: templateOption[] = [
	{name: "full-page", title: "Full Page Template"},
	{name: "half-page", title: "Half Page Template"},
	{name: "single-line", title: "Single Line Template"},
];

const ShareProject = () => {
	const dispatch = useAppDispatch();

	const checkOptions: TProjectSuccessCheckOption[] = useAppSelector(
		projectSuccessChecklistOptionsSelector,
	);

	const savedPeoples: SICandidate[] = useAppSelector(targetListCandidatesSelector);

	const isStarterTypeUser = useAppSelector(isStarterTypeUserSelector);

	const presentationAvailableTemplates: TPresentationAvailableTemplate[] = useAppSelector(
		presentationAvailableTemplatesSelector,
	);

	const selectedPresentationTemplate: string = useAppSelector(
		selectedPresentationTemplateSelector,
	);

	const [downloadProcessing, setDownloadProcessing] = useState(false);

	const [downloadPowerpointProcessing, setDownloadPowerpointProcessing] = useState(false);

	const LOCK_ICON_COMPONENT = <LockIcon className={styles.downloadIcon} />;

	const SPREADSHEET_ICON_COMPONENT = <SpreadSheetIcon />;

	const [activeIcon, setActiveIcon] = useState(SPREADSHEET_ICON_COMPONENT);

	const LoaderComponent = <Loader height={16} width={16} type="TailSpin" color="#fff" />;

	const handleDownloadExcel = useCallback(async () => {
		if (isStarterTypeUser) return;

		setDownloadProcessing(true);

		await dispatch(downloadProjectReportAsSpreadsheet());

		setDownloadProcessing(false);
	}, [isStarterTypeUser, dispatch]);

	const handleDownloadPowerpoint = useCallback(async () => {
		if (isStarterTypeUser) return;

		setDownloadPowerpointProcessing(true);

		await dispatch(downloadProjectReportAsPowerpoint());

		setDownloadPowerpointProcessing(false);

		const pptReportObj = checkOptions?.find(
			(item: TProjectSuccessCheckOption) => item.title === "Download PPT Report",
		);

		if (pptReportObj && !pptReportObj?.checked) {
			dispatch(
				updateProjectSuccessChecklistItem({
					attribute: "has_shared_project",
					value: true,
				}),
			);
		}
	}, [checkOptions, dispatch, isStarterTypeUser]);

	const displayPowerpointDownload = useMemo(() => {
		return !isStarterTypeUser;
	}, [isStarterTypeUser]);

	const handleTemplateSelection = useCallback(
		(name: string) => {
			dispatch(setSelectedPresentationTemplate(name));
		},
		[dispatch],
	);

	useEffect(() => {
		if (!presentationAvailableTemplates.length) {
			dispatch(getPresentationAvailableTemplates());
		} else {
			if (presentationAvailableTemplates?.length > 0) {
				handleTemplateSelection(presentationAvailableTemplates[0].template_name);
			}
		}
	}, [dispatch, handleTemplateSelection, presentationAvailableTemplates]);

	const selectedTemplateInfo = useMemo(
		() =>
			presentationAvailableTemplates.find(
				(s) => s?.template_name === selectedPresentationTemplate,
			) || null,
		[presentationAvailableTemplates, selectedPresentationTemplate],
	);

	return (
		<div className={styles.container}>
			<div className={styles.section}>
				<div className={styles.sectionTitle}>Report Download</div>

				{savedPeoples?.length > 0 && (
					<div className={styles.sectionContent}>
						<div className={styles.reportColumn}>
							<span className={styles.reportTitle}>
								Group people by status and choose a layout style for their profile
								{presentationAvailableTemplates?.length > 1 && (
									<div className={styles.templateSelection}>
										<Dropdown
											onSelect={(option) =>
												handleTemplateSelection(option.template_name)
											}
											options={presentationAvailableTemplates}
											getOptionLabel={(option) => option.template_name}
											defaultSelectedOption={selectedTemplateInfo}
											placeholder={
												<div className={styles.dropdownPlaceholder}>
													<div>
														Template: {selectedPresentationTemplate}
													</div>
													<div>
														<ArrowDown />
													</div>
												</div>
											}
											dropdownButtonClass={styles.statusDropdown}
										/>
									</div>
								)}
							</span>

							<ProjectReportTable
								availableSlides={
									selectedTemplateInfo
										? selectedTemplateInfo.slide_types
										: DEFAULT_TEMPLATE_OPTIONS
								}
							/>
						</div>

						<div className={styles.actionsColumn}>
							<div className={styles.buttonsWrapper}>
								<GeneralButton
									title="Add Status"
									className={styles.addStatusButton}
									loadingSave={false}
									icon={<PlusIcon />}
									onClick={() => dispatch(addNewRowOnProjectReportConfigTable())}
								/>
							</div>

							<div className={styles.buttonsWrapper}>
								<span
									className={classNames(styles.downloadButton, {
										[styles.disabled]: downloadProcessing,
									})}
									onClick={handleDownloadExcel}
									onMouseEnter={() => {
										if (isStarterTypeUser) {
											setActiveIcon(LOCK_ICON_COMPONENT);
										}
									}}
									onMouseLeave={() => {
										if (isStarterTypeUser) {
											setActiveIcon(SPREADSHEET_ICON_COMPONENT);
										}
									}}
									data-tooltip-id={"downloadProjectReport"}
								>
									{downloadProcessing ? LoaderComponent : activeIcon}{" "}
									{!downloadProcessing && "Spreadsheet Download"}
									{isStarterTypeUser && (
										<UpgradeNowTooltip id={"downloadProjectReport"} />
									)}
								</span>

								{displayPowerpointDownload && (
									<GeneralButton
										title="Powerpoint Download"
										className={classNames(styles.downloadButton, {
											[styles.disabled]: downloadPowerpointProcessing,
										})}
										loadingSave={downloadPowerpointProcessing}
										icon={<PowerpointIcon />}
										onClick={handleDownloadPowerpoint}
									/>
								)}
							</div>
						</div>
					</div>
				)}

				{!savedPeoples?.length && (
					<div className={styles.sectionContent}>
						<span className={styles.reportTitle}>
							Add people to this project to download the report.
						</span>
					</div>
				)}
			</div>

			<div className={styles.section} style={{width: "50%"}}>
				<div className={styles.sectionTitle}>Project Collaboration</div>

				<div className={styles.sectionContent}>
					<ProjectCollaboration />
				</div>
			</div>
		</div>
	);
};

export default ShareProject;
