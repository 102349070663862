import type {Dispatch} from "redux";

import mapXPaymentApi from "api/paymentApi";

import {
	getProductsDone,
	getProductsFail,
	getProductsInit,
	openCheckoutDone,
	openCheckoutFail,
	openCheckoutInit,
	openCustomerPortalDone,
	openCustomerPortalFail,
	openCustomerPortalInit,
} from "./paymentActions";
import {AxiosResponse} from "axios";
import {TAppDispatch} from "types";

export const getProducts = () => async (dispatch: TAppDispatch) => {
	try {
		dispatch(getProductsInit());

		const response: AxiosResponse = await mapXPaymentApi.getProducts();

		if (response) {
			const data = response.data;
			dispatch(getProductsDone(data.products));
		}
	} catch (e) {
		dispatch(getProductsFail(e));
	}
};

export const openCheckout = (priceID: string) => async (dispatch: Dispatch) => {
	try {
		dispatch(openCheckoutInit(priceID));

		const response: AxiosResponse = await mapXPaymentApi.checkoutUrl(priceID);

		if (response) {
			const data = response.data;
			dispatch(openCheckoutDone());

			window.open(data.url, "_self");
		}
	} catch (e) {
		dispatch(openCheckoutFail(e));
	}
};

export const openCustomerPortal = () => async (dispatch: Dispatch) => {
	try {
		dispatch(openCustomerPortalInit());
		const response: AxiosResponse = await mapXPaymentApi.customerPortal();

		if (response) {
			const data = response.data;
			dispatch(openCustomerPortalDone());

			window.open(data.url, "_self");
		}
	} catch (e) {
		dispatch(openCustomerPortalFail(e));
	}
};
