import {HexColor, ILocationReportItem} from "mapx-pages/CompanyDetails/CompanyLocations/types";

export const geographyStyle = (marked: boolean, isHQ: boolean) => {
	return {
		default: {
			fill: isHQ ? "#083E38" : marked ? "#296B64" : "#D6D6DA",
			strokeWidth: 0.75,
			outline: "none",
			transition: "all 250ms",
		},
		hover: marked
			? {
					fill: "#619c9a",
					strokeWidth: 0.75,
					outline: "none",
					transition: "all 250ms",
			  }
			: {
					fill: "#D6D6DA",
			  },
		pressed: {
			outline: "none",
			transition: "all 250ms",
		},
	};
};

export const geographyStyleForPeopleLocation = (color: HexColor, marked: boolean) => {
	return {
		default: {
			fill: marked ? color : "#D6D6DA",
			strokeWidth: 0.75,
			outline: "none",
			transition: "all 250ms",
		},
		hover: {
			fill: marked ? color : "#D6D6DA",
			strokeWidth: 0.75,
			outline: "none",
			transition: "all 250ms",
		},
		pressed: {
			outline: "none",
			transition: "all 250ms",
		},
	};
};

// Utility to interpolate between two colors
export function interpolateColor(color1: HexColor, color2: HexColor, factor: number): HexColor {
	const c1 = parseInt(color1.slice(1), 16);
	const c2 = parseInt(color2.slice(1), 16);

	const r1 = (c1 >> 16) & 0xff;
	const g1 = (c1 >> 8) & 0xff;
	const b1 = c1 & 0xff;

	const r2 = (c2 >> 16) & 0xff;
	const g2 = (c2 >> 8) & 0xff;
	const b2 = c2 & 0xff;

	const r = Math.round(r1 + factor * (r2 - r1));
	const g = Math.round(g1 + factor * (g2 - g1));
	const b = Math.round(b1 + factor * (b2 - b1));

	return `#${((1 << 24) + (r << 16) + (g << 8) + b)
		.toString(16)
		.slice(1)
		.toUpperCase()}` as HexColor;
}

// Map `candidates_count` to a color gradient
export function setColorsForCountries(
	countries: ILocationReportItem[],
	colors: [HexColor, HexColor, HexColor],
): ILocationReportItem[] {
	const counts = countries.map((country) => country.candidates_count);
	// const minCount = Math.min(...counts);
	const maxCount = Math.max(...counts);

	return countries.map((country) => {
		const {candidates_count} = country;

		// Compute percentage of the count relative to maxCount
		const percentage = (candidates_count / maxCount) * 100;

		let interpolatedColor: HexColor;

		if (percentage > 50) {
			const factor = (percentage - 50) / 50; // Scale to [0, 1]
			interpolatedColor = interpolateColor(colors[1], colors[0], factor);
		} else if (percentage > 10) {
			let factor = percentage / 30; // Scale to [0.5, 1]
			factor = factor < 0.5 ? 0.5 : factor;
			interpolatedColor = interpolateColor(colors[2], colors[1], factor > 1 ? 1 : factor);
		} else {
			const factor = percentage / 60; // Scale to [0.001, 0.5]

			// console.log(country, factor)

			interpolatedColor = interpolateColor(colors[2], colors[1], factor > 1 ? 1 : factor);
		}

		return {
			...country,
			color: interpolatedColor,
		};
	});
}
