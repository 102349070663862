import {FC, useEffect, useMemo, useState} from "react";
import classNames from "classnames";
import {useAppSelector} from "hooks";
import {
	getProjectListInProgressSelector,
	projectPaginationSelector,
	projectSelector,
} from "store/mapx/project-list/projectListSelectors";
import {userSelector} from "store/mapx/user/userSelectors";

import styles from "../HomeStyles.module.scss";
import LayoutHeaderTitleItem from "mapx-pages/Home/LayoutHeader/LayoutHeaderTitleItem";
import {ProjectIcon, TimeSavedIcon} from "assets/icons";
import {isNumber} from "lodash";
import {minutesToHour} from "mapx-pages/Home/LayoutHeader/utils";
import AuthApi from "api/authApi";
import {cancelRequest, clearCancelToken, getCancelToken} from "api/cancelTokens";
import {GET_USER_STATS_TOKEN} from "api/requestCancelTokenStrings";
import {successResponse} from "helpers/map";
import {AxiosResponse} from "axios";

const LayoutHeader: FC = () => {
	const userInfo = useAppSelector(userSelector);

	const project = useAppSelector(projectSelector);

	const projectPagination = useAppSelector(projectPaginationSelector);

	const projectsFetchInProgress = useAppSelector(getProjectListInProgressSelector);

	const [loading, setLoading] = useState<boolean>(false);

	const [profileData, setProfileData] = useState({
		// people_identified: 0,
		// people_screened: 0,
		time_saved: 0,
	});

	useEffect(() => {
		const getProfileData = async () => {
			setLoading(true);

			const response: AxiosResponse = await AuthApi.getUserStats({
				cancelToken: getCancelToken(GET_USER_STATS_TOKEN).token,
			});

			if (response && successResponse(response, 200)) {
				const {data} = response;
				setProfileData(data);
			}

			setLoading(false);
		};

		getProfileData().finally(() => clearCancelToken(GET_USER_STATS_TOKEN));

		return () => {
			cancelRequest(GET_USER_STATS_TOKEN);
		};
	}, []);

	const {time_saved} = profileData;

	const savedTimeHumanFormat = useMemo(() => {
		if (isNumber(time_saved)) {
			const hours = minutesToHour(Number(time_saved));

			if (hours > 1) {
				return `${hours} hours`;
			} else {
				return `${hours} hour`;
			}
		} else {
			return "-";
		}
	}, [time_saved]);

	return (
		<>
			<div
				className={classNames(styles.content__header, {
					[styles.content__header__is_header]: project?.name,
				})}
			>
				<div className={styles.content__header__left}>
					<img
						data-testid="user_image"
						className={styles.content__header__left_img}
						src={
							userInfo?.image ||
							"https://img.freepik.com/free-icon/user_318-804790.jpg?w=2000"
						}
					/>

					<div className={styles.content__header__left__texts}>
						<h3 className={styles.content__header__left__texts__name}>
							<span className={styles.content__header__left__texts__name_greetings}>
								Welcome,
							</span>
							{userInfo?.firstName} {userInfo?.lastName}
						</h3>

						<p className={styles.content__header__left__texts__description}>
							{userInfo?.role || "Savannah GroupResearcher"}

							{userInfo?.organisation?.name && `, ${userInfo?.organisation?.name}`}

							{/*{isProUser && (*/}
							{/*	<HelpIcon*/}
							{/*		dataTooltipId="infoTooltip"*/}
							{/*		className={*/}
							{/*			styles.content__header__left__texts__description_info*/}
							{/*		}*/}
							{/*	/>*/}
							{/*)}*/}
						</p>
					</div>
				</div>

				<div className={styles.content__header__right}>
					<LayoutHeaderTitleItem
						loading={projectsFetchInProgress}
						text="Created Projects"
						Icon={<ProjectIcon color={"#2C3030"} />}
						count={projectPagination?.count || "-"}
					/>

					<LayoutHeaderTitleItem
						loading={loading}
						text="Time Saved"
						Icon={<TimeSavedIcon />}
						count={savedTimeHumanFormat}
					/>
				</div>
			</div>

			{/*<InfoTooltip*/}
			{/*    id="infoTooltip"*/}
			{/*    nextRefresh={userCreditRefreshSelect}*/}
			{/*    creditLeftHandler={creditLeftHandler}*/}
			{/*    nextRefreshHandler={nextRefreshHandler}*/}
			{/*    creditLeft={`${Number(userAvailableCredit)}`}*/}
			{/*/>*/}
		</>
	);
};

export default LayoutHeader;
