import axios, {CancelTokenSource} from "axios";

const cancelTokens: Record<string, CancelTokenSource> = {};

export const getCancelToken = (key: string): CancelTokenSource => {
	if (key in cancelTokens) {
		cancelTokens[key].cancel("Operation canceled due to new request.");
	}

	cancelTokens[key] = axios.CancelToken.source();

	return cancelTokens[key];
};

export const cancelRequest = (key: string) => {
	if (key in cancelTokens) {
		cancelTokens[key].cancel("Operation canceled by component unmount.");

		delete cancelTokens[key];
	}
};

export const clearCancelToken = (key: string) => {
	delete cancelTokens[key];
};
