import React, {type FC, useCallback, useEffect, useRef, useState} from "react";

import type {STCompany} from "api/companyApi/types";
import {useAppDispatch, useAppSelector} from "hooks";
import {Accordion} from "mapx-components";
import CompanySearchFilter from "mapx-components/Filters/CompanySearchFilter";
import {setSelectedCompaniesDone} from "store/mapx/company/companyActions";
import {
	setAICompanyIdeaFilterMode,
	setFilterForCompaniesByCompaniesList,
} from "store/mapx/filter/filterActions";
import useGenerateFilterSetByCompanies from "hooks/mapx/useGenerateFilterSetByCompanies";
import {
	aiCompanyIdeaFilterModeSelector,
	companySpecialitiesConnectivityLogicSelector,
	companySpecialitiesFilterKeyByLogic,
	selectedCompaniesSelector,
} from "store/mapx/filter/filterSelectors";
import styles from "./styles.module.scss";
import Switch from "components/Inputs/Switch";
import {ModalSeparator, ResetFilters} from "components";
import CompanySearchByDescription from "containers/Filters/CompanyFilters/CompanyFilter/SearchByDescription";
import SelectedCompaniesTag from "mapx-components/Filters/CompanySearchFilter/SelectedCompaniesTag";

const CompanyFilter: FC = () => {
	const dispatch = useAppDispatch();

	const companySpecialitiesConnectivityLogic = useAppSelector(
		companySpecialitiesConnectivityLogicSelector,
	);

	const specialtyFilterKey = useAppSelector((state) =>
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		companySpecialitiesFilterKeyByLogic(state, companySpecialitiesConnectivityLogic),
	);

	const selectedCompanies = useAppSelector(selectedCompaniesSelector);

	const aiCompanyIdeaFilterMode: "exact" | "suggested" = useAppSelector(
		aiCompanyIdeaFilterModeSelector,
	);

	const filterObject = useGenerateFilterSetByCompanies({
		useSelectedCompanies: true,
		extractAttribute: aiCompanyIdeaFilterMode === "suggested",
		extractCompanyId: aiCompanyIdeaFilterMode === "exact",
	});

	const [applyFilter, setApplyFilter] = useState(false);

	const isMounted = useRef(true);

	const handleItemClick = (company: STCompany) => {
		const ids = new Set(selectedCompanies.map((item: STCompany) => item.id));

		if (!ids.has(company.id)) {
			dispatch(setSelectedCompaniesDone([...selectedCompanies, company]));

			setApplyFilter(true);
		}
	};

	const removeFromSelectedCompanyList = (id: number) => {
		const updatedSelectedCompanies = [...selectedCompanies].filter((item) => item.id !== id);

		dispatch(setSelectedCompaniesDone([...updatedSelectedCompanies]));

		setApplyFilter(true);
	};

	useEffect(() => {
		if ((applyFilter && filterObject) || aiCompanyIdeaFilterMode) {
			const filters = {...filterObject};

			dispatch(setFilterForCompaniesByCompaniesList(filters));

			setApplyFilter(false);
		}

		return () => {
			isMounted.current = false;
		};
	}, [dispatch, applyFilter, filterObject, specialtyFilterKey, aiCompanyIdeaFilterMode]);

	const handleSwitchClick = useCallback(async () => {
		const mode = aiCompanyIdeaFilterMode === "exact" ? "suggested" : "exact";

		dispatch(setAICompanyIdeaFilterMode(mode));
	}, [dispatch, aiCompanyIdeaFilterMode]);

	const handleResetClick = useCallback(() => {
		dispatch(setSelectedCompaniesDone([]));
	}, [dispatch]);

	return (
		<Accordion
			title="AI Company Ideas"
			subtitle={selectedCompanies ? selectedCompanies.length : ""}
			tooltipId={"ai_company_idea_filter_hover"}
		>
			{selectedCompanies.length > 0 && (
				<ResetFilters onClick={handleResetClick} style={{marginTop: 4}} displayIcon={true}>
					Clear Selection
				</ResetFilters>
			)}

			<div className={styles.containSwitch}>
				<span className={styles.active}>Exact</span>
				<Switch
					checked={aiCompanyIdeaFilterMode === "suggested"}
					onChange={handleSwitchClick}
				/>
				<span className={styles.inactive}>Suggested</span>
			</div>

			<div className={styles.label}>Search by name</div>

			<CompanySearchFilter handleOnClick={handleItemClick} displayCompaniesList={false} />

			<ModalSeparator />

			<CompanySearchByDescription />

			{selectedCompanies?.length > 0 && (
				<SelectedCompaniesTag
					handleRemoveClick={removeFromSelectedCompanyList}
					selectedCompanies={selectedCompanies}
				/>
			)}
		</Accordion>
	);
};

export default CompanyFilter;
