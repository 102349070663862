import React, {useCallback, useMemo, useState} from "react";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {ComposableMap, Geographies, Geography, ZoomableGroup} from "react-simple-maps";

import {Tooltip} from "components";
import {getCountryData} from "helpers";

import styles from "mapx-pages/CompanyDetails/CompanyLocations/index.module.scss";
import "react-tooltip/dist/react-tooltip.css";
import {
	geographyStyle,
	geographyStyleForPeopleLocation,
	setColorsForCountries,
} from "mapx-pages/CompanyDetails/CompanyLocations/utils";
import {HQDisplay} from "mapx-pages/CompanyDetails/CompanyLocations/HQDisplay";
import {
	companyLocationReportInProgressSelector,
	companyLocationReportSelector,
	companySelector,
} from "store/mapx/company/companySelectors";
import {STCompany} from "api/companyApi/types";
import {useAppSelector} from "hooks";
import {ArrowUp} from "assets/icons";
import {
	HexColor,
	ILocationReportItem,
	TCountryDataInfo,
	TLocationDataByRegions,
} from "mapx-pages/CompanyDetails/CompanyLocations/types";
import LocationSkeleton from "mapx-pages/CompanyDetails/CompanySkeletons/LocationSkeleton";

const colorGradient: [HexColor, HexColor, HexColor] = ["#052320", "#1e5b54", "#79A5A1"];

export const CompanyLocations = ({isCurrentTabSelected}: {isCurrentTabSelected: boolean}) => {
	const currentCompany: STCompany = useSelector(companySelector);

	const [activeMapOption, setActiveMapOption] = useState<"people" | "ofc">("people");

	const countries = useMemo(
		() =>
			currentCompany && currentCompany?.countries
				? currentCompany?.countries?.map((val) => val?.name)
				: [],
		[currentCompany],
	);

	const hq = useMemo(
		() => currentCompany?.headquarters_country?.name || currentCompany?.headquarters,
		[currentCompany],
	);

	const mappedCountries = useMemo(
		() =>
			currentCompany && currentCompany?.countries
				? currentCompany?.countries?.map((country) => ({
						country: country,
						candidates_percent: 0,
						color: country.name === hq ? "#083E38" : "#296B64",
				  }))
				: [],
		[currentCompany, hq],
	);

	const locationReport: ILocationReportItem[] = useAppSelector(companyLocationReportSelector);

	const locationReportLoading = useAppSelector(companyLocationReportInProgressSelector);

	const headquarterCoords = currentCompany?.headquarters_country
		? getCountryData(currentCompany?.headquarters_country?.code)
		: null;

	const [expandedCountries, setExpandedCountries] = useState(null);

	const locationReportWithColorCode = useMemo(() => {
		return locationReport?.length > 0
			? setColorsForCountries(locationReport, colorGradient)
			: [];
	}, [locationReport]);

	const handleFilter = (event: KeyboardEvent | SVGElement) => {
		return (
			((event instanceof KeyboardEvent && event.metaKey) ||
				(event instanceof KeyboardEvent && event.ctrlKey)) &&
			!(event instanceof MouseEvent || event instanceof WheelEvent)
		);
	};

	const regionsByCountry = useMemo(() => {
		if (!locationReportWithColorCode) {
			return new Map();
		}

		const regions: TLocationDataByRegions = {};

		const data = activeMapOption === "people" ? locationReportWithColorCode : mappedCountries;

		for (const report of data) {
			const country = report.country;
			const region = country.region;

			if (regions[region]) {
				regions[region] = {
					percentage: regions[region].percentage + report.candidates_percent,
					countries: [
						...regions[region].countries,
						{
							name: country.name,
							candidates_percent: report.candidates_percent,
							color: report.color as HexColor,
						},
					],
				};
			} else {
				regions[region] = {
					percentage: report.candidates_percent,
					countries: [
						{
							name: country.name,
							candidates_percent: report.candidates_percent,
							color: report.color as HexColor,
						},
					],
				};
			}
		}

		return new Map(Object.entries(regions).sort((a, b) => b[1].percentage - a[1].percentage));
	}, [activeMapOption, locationReportWithColorCode, mappedCountries]);

	const getColor = useCallback(
		(countryName: string) => {
			if (activeMapOption === "ofc") {
				return geographyStyle(
					countries?.includes(countryName),
					countryName === currentCompany?.headquarters_country?.name,
				);
			} else {
				const color =
					locationReportWithColorCode?.find(
						(report) => report.country.name === countryName,
					)?.color || "#DAD8D7";

				return geographyStyleForPeopleLocation(color, true);
			}
		},
		[
			activeMapOption,
			countries,
			currentCompany?.headquarters_country?.name,
			locationReportWithColorCode,
		],
	);

	const getLocationLabel = useCallback(
		(countryName: string) => {
			if (activeMapOption === "ofc") {
				return countryName;
			} else {
				const report = locationReportWithColorCode?.find(
					(report) => report.country.name === countryName,
				);

				if (report) {
					return `${countryName}: ${
						report?.candidates_percent === 0 ? "<1" : report?.candidates_percent
					}%`;
				} else {
					return countryName;
				}
			}
		},
		[activeMapOption, locationReportWithColorCode],
	);

	return (
		<div
			className={classNames(styles.container, {
				[styles.hidden]: !isCurrentTabSelected,
			})}
		>
			<div className={styles.headerArea}>
				<div className={styles.title}>
					{`${activeMapOption === "people" ? "People" : "Office"} Location`}
				</div>
				<div className={styles.mapOptions}>
					<span
						className={classNames(styles.mapOption, {
							[styles.active]: activeMapOption === "people",
						})}
						onClick={() => setActiveMapOption("people")}
					>
						People Location
					</span>
					<span
						className={classNames(styles.mapOption, {
							[styles.active]: activeMapOption === "ofc",
						})}
						onClick={() => setActiveMapOption("ofc")}
					>
						Office Location
					</span>
				</div>
			</div>
			{!locationReportLoading && (
				<div style={{position: "relative"}}>
					<ComposableMap
						projection={"geoMercator"}
						width={800}
						height={800 / 1.25}
						projectionConfig={{
							scale: 110,
							rotate: [-11, 0, 0],
						}}
						style={{display: "flex", width: "100%"}}
						data-tip=""
					>
						<ZoomableGroup
							center={[0, 0]}
							zoom={1}
							maxZoom={2}
							filterZoomEvent={handleFilter}
							translateExtent={[
								[0, -800 / 1.25],
								[800, 800 / 1.25],
							]}
						>
							<Geographies
								geography="/features.json"
								fill="#D6D6DA"
								stroke="#FFFFFF"
								strokeWidth={0.5}
							>
								{({geographies}) =>
									geographies.map((geo) => {
										return (
											<Tooltip key={geo.rsmKey} followMouse>
												<Tooltip.Trigger asChild>
													<Geography
														key={geo.rsmKey}
														geography={geo}
														style={getColor(geo.properties.name)}
													/>
												</Tooltip.Trigger>
												<Tooltip.Content>
													{getLocationLabel(geo.properties.name)}
												</Tooltip.Content>
											</Tooltip>
										);
									})
								}
							</Geographies>

							{headquarterCoords && hq && (
								<HQDisplay
									lng={headquarterCoords.center_lng}
									lat={headquarterCoords.center_lat}
									name={hq}
								/>
							)}
						</ZoomableGroup>
					</ComposableMap>

					<div
						className={classNames(styles.legendWrapper, {
							[styles.hidden]:
								activeMapOption === "people" && locationReport?.length === 0,
						})}
						key={activeMapOption}
					>
						{activeMapOption === "ofc" && hq && (
							<div className={styles.legendTitle}>
								<span>HQ</span>
								<div className={styles.legendContent}>
									<div className={classNames(styles.dot, styles.hqDot)} />
									{hq}
								</div>
							</div>
						)}
						<div className={classNames(styles.legendTitle, styles.expander)}>
							<span
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									width: "100%",
								}}
							>
								<span>{activeMapOption === "people" ? "Region" : "Offices"}</span>
								<span>{activeMapOption === "people" && "Perc."}</span>
							</span>

							{Array.from(regionsByCountry.entries()).map(([key, value], index) => (
								<div
									className={styles.regionList}
									key={index}
									onClick={() =>
										setExpandedCountries((prev) => (prev === key ? null : key))
									}
								>
									<div className={styles.legendContent}>
										{key.toString()}

										<span className={styles.count}>
											{value.countries?.length}
										</span>

										<div
											style={{
												position: "absolute",
												display: "inline-block",
												right: 0,
											}}
										>
											{activeMapOption === "people" && (
												<b>
													{value.percentage > 0 ? value.percentage : "<1"}
													%
												</b>
											)}
											<ArrowUp
												style={{
													transform: `rotate(${
														expandedCountries === key ? "0" : "180deg"
													})`,
												}}
											/>
										</div>
									</div>

									<div
										className={classNames(styles.regionCountryList, {
											[styles.opened]: expandedCountries === key,
										})}
									>
										<div className={styles.countriesListWrapper}>
											{value.countries.map(
												(data: TCountryDataInfo, index: number) => (
													<div
														className={styles.country}
														key={`${data.name}_${index}`}
													>
														<div
															style={{
																display: "flex",
																flexDirection: "row",
																gap: 4,
																alignItems: "center",
															}}
														>
															{data.name === hq ? (
																<div
																	className={classNames(
																		styles.dot,
																		styles.hqDot,
																	)}
																/>
															) : (
																<div
																	className={styles.dot}
																	style={{
																		backgroundColor:
																			activeMapOption ===
																			"people"
																				? data.color
																				: "#296b64",
																	}}
																/>
															)}
															{data.name}
														</div>

														{activeMapOption === "people" && (
															<span className={styles.percentage}>
																{data.candidates_percent > 0
																	? data.candidates_percent
																	: "<1"}
																%
															</span>
														)}
													</div>
												),
											)}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			)}

			{locationReportLoading && <LocationSkeleton />}
		</div>
	);
};
