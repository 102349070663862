import {useCallback} from "react";
import {useSelector} from "react-redux";
import {useAppDispatch} from "hooks";
import StatusSearchFilter from "mapx-components/Filters/StatusSearchFilter";
import {TStatusItem} from "mapx-components/Filters/StatusSearchFilter/types";
import {setAPMultipleFilters} from "store/mapx/additional-profiles/additionalProfilesActions";
import {apCandidateStatusesSelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";

function StatusFilterForAP() {
	const dispatch = useAppDispatch();

	const selectedStatusIds = useSelector(apCandidateStatusesSelector);

	const handleChange = useCallback(
		(item: TStatusItem) => {
			const index = selectedStatusIds.indexOf(item.id);
			const alreadySelected = index !== -1;

			const nextItems = alreadySelected
				? [...selectedStatusIds.slice(0, index), ...selectedStatusIds.slice(index + 1)]
				: [...selectedStatusIds, item.id];

			dispatch(setAPMultipleFilters({progress_status_ids: nextItems}));
		},
		[dispatch, selectedStatusIds],
	);

	const handleReset = useCallback(() => {
		dispatch(setAPMultipleFilters({progress_status_ids: []}));
	}, [dispatch]);

	return (
		<StatusSearchFilter
			handleOnChange={handleChange}
			handleResetClick={handleReset}
			selectedStatuses={selectedStatusIds}
		/>
	);
}

export default StatusFilterForAP;
