import type {SyntheticEvent} from "react";
import {useCallback, useEffect, useMemo, useState} from "react";

import colors from "styles/themes.module.scss";
import {useModalHook} from "hooks";
import {Message, ModalHeader, ModalSeparator, SelectWithInfiniteScroll} from "components";
import GeneralButton from "mapx-components/Buttons/GeneralButton";
import mapXTargetListApi from "api/targetListApi";
import type {TSelectWithActionOptions} from "components/Inputs/SelectWithAction/types";
import type {STTargetList} from "api/targetListApi/types";

import css from "./importFromTargetListModal.module.scss";
import type {TImportFromProjectModal} from "./types";
import {AxiosResponse} from "axios";

const ImportFromTargetListModal = ({
	setCompanies,
	setModalDisplay,
	modalDisplay = true,
}: TImportFromProjectModal) => {
	const [targetList, setTargetList] = useState<TSelectWithActionOptions>({
		label: null,
		value: null,
	});

	const [loading, setLoading] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [max500, setMax500] = useState(false);
	const [min0, setMin0] = useState(false);
	const [targetLists, setTargetLists] = useState<STTargetList[]>([]);
	const [pagination, setPagination] = useState({
		count: 0,
		page: 0,
		pages: 1,
		per_page: 20,
	});

	const {modalIsOpen, customStyles, Modal} = useModalHook(
		{content: {width: "400px", borderRadius: "8px", overflow: "visible"}},
		modalDisplay,
		colors.mainThemeColor,
	);

	const selectedTargetList = useMemo(
		() => targetLists.find((t) => t.id === targetList.value),
		[targetList.value, targetLists],
	);

	const handleInfiniteScroll = useCallback(async () => {
		setLoading(true);
		const response: AxiosResponse = await mapXTargetListApi.getTargetLists(pagination.page + 1);

		const data = response?.data;

		setLoading(false);

		if (data?.pagination) {
			setPagination(data.pagination);
		}

		if (data?.results) {
			setTargetLists([...targetLists, ...data.results]);
		}
	}, [pagination.page, targetLists]);

	const handleSubmit = useCallback(
		async (e: SyntheticEvent) => {
			e.preventDefault();
			if (selectedTargetList) {
				setSubmitting(true);

				const response: AxiosResponse = await mapXTargetListApi.getTargetListCompanies(
					selectedTargetList.id,
				);

				if (response) {
					const data = response?.data;

					if (data?.results?.length > 500) {
						setMax500(true);
					} else if (data?.results?.length === 0) {
						setMin0(true);
					} else if (data.results) {
						setCompanies(data.results);
						setModalDisplay(false);
					}
				}

				setSubmitting(false);
			}
		},
		[selectedTargetList, setCompanies, setModalDisplay],
	);

	useEffect(() => {
		const getInitialData = async () => {
			setLoading(true);
			const response: AxiosResponse = await mapXTargetListApi.getTargetLists(
				pagination.page + 1,
			);

			if (response) {
				const data = response?.data;
				if (data?.pagination) {
					setPagination(data.pagination);
				}

				if (data?.results) {
					setTargetLists([...targetLists, ...data.results]);
				}
			}
			setLoading(false);
		};

		getInitialData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Modal
			title="Import from Target List"
			style={customStyles}
			isOpen={modalIsOpen}
			contentLabel="Modal"
			onRequestClose={() => setModalDisplay(false)}
		>
			<ModalHeader title="Import from Target List" onClose={() => setModalDisplay(false)} />
			<ModalSeparator />
			<form className={css.container} onSubmit={handleSubmit}>
				<label className={css.label}>Project</label>
				<SelectWithInfiniteScroll
					isSelected={!targetList?.value}
					loading={loading}
					onScroll={handleInfiniteScroll}
					options={targetLists.map((tl) => ({
						label: tl.name || "Detached Target List",
						value: tl.id,
					}))}
					pagination={pagination}
					placeholder="Select Target List"
					selected={targetList}
					setSelected={setTargetList}
				/>
				{max500 && (
					<Message
						className={css.message}
						level="error"
						message="You can add max 500 companies per Target List."
					/>
				)}
				{min0 && (
					<Message
						className={css.message}
						level="error"
						message="No company has been added to the target list."
					/>
				)}
				<GeneralButton
					disabled={targetList.value == null}
					customClass={css.button}
					title="Import Target List to Target List"
					loadingSave={submitting}
				/>
			</form>
		</Modal>
	);
};

export default ImportFromTargetListModal;
