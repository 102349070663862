import React, {useEffect} from "react";
import {getProjectStats} from "store/mapx/project/projectAsyncActions";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";

import styles from "mapx-pages/Project/Overview/ProjectStats/projectStats.module.scss";
import SkeletonLoaderProjectStats from "mapx-pages/Project/Overview/ProjectStats/StatsSkeletonLoader";
import {useAppDispatch, useAppSelector} from "hooks";
import {STProject} from "api/projectApi/types";
import {
	projectStatsLoadingSelector,
	projectStatsSelector,
} from "store/mapx/project/projectSelectors";
import {cancelRequest} from "api/cancelTokens";
import {GET_PROJECT_STATISTICS_TOKEN} from "api/requestCancelTokenStrings";

export default function ProjectStats() {
	const dispatch = useAppDispatch();

	const projectStats = useAppSelector(projectStatsSelector);

	const project: STProject = useAppSelector(projectSelector);

	const statsLoading = useAppSelector(projectStatsLoadingSelector);

	useEffect(() => {
		if (project?.id) {
			dispatch(getProjectStats(project.id));
		}

		return () => {
			cancelRequest(GET_PROJECT_STATISTICS_TOKEN);
		};
	}, [dispatch, project?.id]);

	return (
		<div className={styles.statCounts}>
			{statsLoading ? (
				<span className={styles.loader} data-testid={"project-stats-loader"}>
					<SkeletonLoaderProjectStats count={3} />
				</span>
			) : (
				<>
					<div className={styles.countBox}>
						<div className={styles.itemCount} data-testid="candidates_number">
							{projectStats?.candidates_number}
						</div>
						People
					</div>
					<div className={styles.countBox}>
						<div className={styles.itemCount} data-testid="companies_number">
							{projectStats?.companies_number}
						</div>
						Companies
					</div>
					<div className={styles.countBox}>
						<div className={styles.itemCount} data-testid="diversity">
							{projectStats?.diversity_percent ? (
								<>{`${projectStats?.diversity_percent}%`}</>
							) : (
								"-"
							)}
						</div>
						Diversity
					</div>
				</>
			)}
		</div>
	);
}
