import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import {ActionAltButton} from "mapx-components";
import FitToBriefIcon from "mapx-pages/Project/SavedPeople/FilterSection/FitToBrief/Icons/fitToBriefIcon";
import {useAppDispatch, useAppSelector} from "hooks";
import {Tooltip as ReactTooltip} from "react-tooltip";
import FitToBriefModal from "mapx-components/Modals/FitToBriefModal";
import {
	briefCriteriaProjectAssessmentSelector,
	isAnyProjectCandidateScoringInProgressSelector,
	isCurrentProjectCandidateScoringInProgressSelector,
} from "store/mapx/project/candidateScoringSelectors";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {STProject} from "api/projectApi/types";
import {customConfirmAlert} from "helpers";
import {getProjectAllCriteriaForAssessment} from "store/mapx/project/projectAssessmentAsyncActions";
import {activeLastCandidateAssessmentForProjectSelector} from "store/mapx/background-tasks/backgroundTaskSelectors";
import {STBackgroundTaskResponse} from "api/backgroundTaskApi/types";
import {useLocation} from "react-router-dom";

const FitToBrief = () => {
	// const selectedCandidatesIdsForAssessment = useAppSelector(
	// 	selectedCandidateIdsForAssessmentSelector,
	// );

	const dispatch = useAppDispatch();

	const ref = useRef(null);

	const [modalDisplay, setModalDisplay] = useState(false);

	const [loadingBrief, setLoadingBrief] = useState(false);

	const project: STProject = useAppSelector(projectSelector);

	const isCurrentProjectCandidateScoringInProgress = useAppSelector((state) =>
		isCurrentProjectCandidateScoringInProgressSelector(state, project?.id),
	);

	const isAnyProjectCandidateScoringInProgress = useAppSelector(
		isAnyProjectCandidateScoringInProgressSelector,
	);

	const briefCriteria = useAppSelector(briefCriteriaProjectAssessmentSelector);

	const activeLastCandidateAssessmentForProject: Nullable<STBackgroundTaskResponse> =
		useAppSelector(activeLastCandidateAssessmentForProjectSelector);

	const search = useLocation().search;

	const shouldOpeModal = new URLSearchParams(search).get("openFitToBrief");

	const fitToBriefButtonTitle = useMemo(() => {
		if (
			isCurrentProjectCandidateScoringInProgress &&
			activeLastCandidateAssessmentForProject &&
			Array.isArray(activeLastCandidateAssessmentForProject?.candidate_assessments)
		) {
			const completedAssessment =
				activeLastCandidateAssessmentForProject?.candidate_assessments?.filter(
					(c) => c.is_assessed,
				)?.length;
			const totalAssessments =
				activeLastCandidateAssessmentForProject?.candidate_assessments?.length;

			return `Assessing... ${completedAssessment}/${totalAssessments}`;
		}

		return "Fit to Brief";
	}, [activeLastCandidateAssessmentForProject, isCurrentProjectCandidateScoringInProgress]);

	const Tooltip = (
		<ReactTooltip clickable place="bottom" id="fit-to-brief" className={styles.tooltip}>
			<div style={{fontWeight: 500, marginBottom: 8}}>Fit to Brief</div>

			<div>
				<div style={{marginBottom: 10}}>
					AI will assess the people on your project and score them against your hiring
					criteria.
				</div>

				<div style={{marginBottom: 10}}>
					Use the checkboxes to select which people to <b>score</b>.
				</div>

				<div style={{marginBottom: 10}}>
					{
						"Tip: you can select multiple people using the 'select all' checkbox dropdown at the top of the list."
					}
				</div>
			</div>
		</ReactTooltip>
	);

	const openModalWorkflow = useCallback(async () => {
		if (isAnyProjectCandidateScoringInProgress) {
			customConfirmAlert({
				yesButtonLabel: "Okay",
				onlyYesButton: true,
				title: "Candidate Assessment in progress!",
				message: `Candidate assessment in progress for another project. You can only perform one candidate assessment at a time. Please complete the current assessment before starting a new one.`,
				handlePressYes: () => {
					setModalDisplay(false);
				},
			});
		} else {
			setModalDisplay(true);
			setLoadingBrief(true);
			await dispatch(getProjectAllCriteriaForAssessment());
			setLoadingBrief(false);
		}
	}, [dispatch, isAnyProjectCandidateScoringInProgress]);

	const handleFitToBriefModalClick = useCallback(async () => {
		await openModalWorkflow();
	}, [openModalWorkflow]);

	useEffect(() => {
		async function get() {
			if (shouldOpeModal === "true") {
				setModalDisplay(true);
				setLoadingBrief(true);
				await dispatch(getProjectAllCriteriaForAssessment());
				setLoadingBrief(false);
			}
		}

		get();
	}, [dispatch, shouldOpeModal]);

	return (
		<div
			className={styles.fitToBriefContainer}
			data-tooltip-id="fit-to-brief"
			data-testid="fit-to-brief"
		>
			<ActionAltButton
				key={"fitToBrief"}
				customClass={classNames(styles.fitToBrief, {
					[styles.loading]: isCurrentProjectCandidateScoringInProgress,
				})}
				disabled={isCurrentProjectCandidateScoringInProgress}
				iconComponent={<FitToBriefIcon />}
				handleClick={handleFitToBriefModalClick}
				title={fitToBriefButtonTitle}
				loading={isCurrentProjectCandidateScoringInProgress}
			/>

			{Tooltip}

			<div ref={ref} style={{display: "flex", zIndex: 2}}>
				<FitToBriefModal
					modalDisplay={modalDisplay}
					setModalDisplay={setModalDisplay}
					briefCriteria={briefCriteria}
					loadingBrief={loadingBrief}
				/>
			</div>
		</div>
	);
};

export default FitToBrief;
