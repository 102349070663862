import type {FC} from "react";
import {useCallback, useEffect, useMemo, useState} from "react";

import {useAppDispatch, useAppSelector, useCompanyIdParam} from "hooks";
import {CompanyCard, RoundedButton} from "components";
import {targetListCompaniesSelector} from "store/mapx/target-list/targetListSelectors";
import {getProjectFrequentlyHiredFromData} from "store/mapx/project/projectAsyncActions";
import {projectFrequentlyHiredFromDataSelector} from "store/mapx/project/projectSelectors";
import {
	addCompanyToTargetList,
	removeCompanyFromTargetList,
} from "store/mapx/target-list/targetListAsyncActions";

import styles from "./ProjectFreqHiredFrom.module.scss";
import NotEnoughData from "../NotEnoughData";
import {STCompany} from "api/companyApi/types";

const ProjectFreqHiredFrom: FC = () => {
	const dispatch = useAppDispatch();

	const paramId = useCompanyIdParam();

	const data = useAppSelector(projectFrequentlyHiredFromDataSelector);

	const targetListCompanies = useAppSelector(targetListCompaniesSelector);

	const [loading, setLoading] = useState<boolean>(false);

	const handleShowMoreClick = useCallback(() => {
		setLoading(true);

		dispatch(
			getProjectFrequentlyHiredFromData(
				paramId,
				{
					page: data?.pagination?.page + 1,
					per_page: 9,
				},
				() => setLoading(false),
			),
		);
	}, [data, dispatch, paramId]);

	useEffect(() => {
		if (paramId) {
			setLoading(true);
			dispatch(
				getProjectFrequentlyHiredFromData(
					paramId,
					{
						per_page: 9,
						page: 1,
					},
					() => setLoading(false),
				),
			);
		}
	}, [dispatch, paramId]);

	const inTargetList = useMemo(
		() => new Set(targetListCompanies.map((company: STCompany) => company?.id)),
		[targetListCompanies],
	);

	const addCompany = (companyId: number) => {
		dispatch(addCompanyToTargetList(companyId));
	};

	const removeCompany = (companyId: number) => {
		dispatch(removeCompanyFromTargetList(companyId));
	};

	const renderCards = data?.results?.map(({company}: {company: STCompany}) => {
		const isSelectedItem = inTargetList.has(company?.id);

		return (
			<CompanyCard
				key={company?.id}
				company={company}
				isTooltipSelect={false}
				isSelectedItem={isSelectedItem}
				handleAddClick={() => addCompany(company?.id)}
				handleRemoveClick={() => removeCompany(company?.id)}
			/>
		);
	});

	return (
		<div className={styles.company_block}>
			<div className={styles.company_block__head}>
				<p className={styles.company_block__head_title}>Companies Frequently Hired From</p>
			</div>

			<hr className={styles.company_block__line} />

			<div className={styles.company_block__card}>{renderCards}</div>

			{!loading &&
				data?.pagination?.page !== data?.pagination?.pages &&
				!!data?.pagination?.count && (
					<div className={styles.company_block__show_more}>
						<RoundedButton onClick={handleShowMoreClick}>Show More</RoundedButton>
					</div>
				)}

			{!data?.pagination?.pages && (
				<NotEnoughData
					applySmallSize={false}
					title="There is not enough data to display companies frequently hired from report."
				/>
			)}
		</div>
	);
};

export default ProjectFreqHiredFrom;
